import styled from "styled-components";
import Sidebar from "../components/general/Sidebar";
import Header from "../components/general/Header";
import { useState } from "react";
import PrimaryButton from "../components/styled/PrimaryButton";

const ReferralProgramPage = () => {
  const [activeTab, setActiveTab] = useState("Earn");
  const [sidebarHide, setSidebarHide] = useState(true);
  const [sidebarCollapse, setSidebarCollapse] = useState(false);

  return (
    <PageContainer>
      <Header
        collapse={{ sidebarCollapse, setSidebarCollapse }}
        hide={{ sidebarHide, setSidebarHide }}
      />
      <PageWrapper>
        <Sidebar
          tab={{ activeTab, setActiveTab }}
          hide={{ sidebarHide, setSidebarHide }}
          collapse={{ sidebarCollapse, setSidebarCollapse }}
        />
        <Main>
          <div className="page_content">
            <div className="page_title_container">
              <p> Make money on our product!</p>
              <p>
                {" "}
                If there is an audience, there will be money.{" "}
                <span>A LOT OF MONEY!</span>
              </p>
            </div>

            <ReferralCard>
              <div className="left">
                <div className="card_title">Looking for partners</div>
                <div className="card_text">
                  Develop together with us! We invite banks, acquirers and
                  processing companies to partner with us to expand the range of
                  financial products
                </div>
                <PrimaryButton disabled={false} text="More" />
              </div>

              <img className="itemImg" src="./assets/images/partners.png" />
            </ReferralCard>

            <ReferralCard>
              <div className="left">
                <div className="card_title">Referral program</div>
                <div className="card_text">
                  You set the commission percentage you want to charge from your
                  referrals and we will give you 70% of the commission taken
                  from them
                </div>
                <PrimaryButton disabled={false} text="More" />
              </div>

              <img
                className="itemImg"
                src="https://e.pn/assets/earn_1.b904aac1.png"
              />
            </ReferralCard>

            <ReferralCard>
              <div className="left">
                <div className="card_title">WhiteLabel</div>
                <div className="card_text">
                  WhiteLabel is a full copy of our service under your control.
                  We create a full copy of for you on your domain and create a
                  bank account for you, and you start earning money from users
                  and save on yourself
                </div>
                <PrimaryButton disabled={false} text="More" />
                {/* <button style={{ width: "100%" }}>Hi</button> */}
              </div>

              <img
                className="itemImg"
                src="https://e.pn/assets/earn_2.d0bbf1af.png"
              />
            </ReferralCard>
          </div>
        </Main>
      </PageWrapper>
    </PageContainer>
  );
};

const PageContainer = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
`;

const PageWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: grid;
  grid-template-columns: max-content auto;

  @media screen and (max-width: 768px) {
    & {
      grid-template-columns: auto auto;
    }
  }
`;

const Main = styled.div`
  background-color: white;
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
  padding: 0px 30px;

  .page_content {
    width: 100%;
    padding: 24px 0;
    margin: 0 auto;
    max-width: 1074px;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .page_content .page_title_container p {
    font-size: 32px;
    font-weight: 800;
    line-height: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    font-family: Roboto;
    text-align: center;
    line-height: 133.333%;
    font-size: 48px;
    font-weight: 600;
    line-height: 133.333%;
    text-wrap: balance;
    letter-spacing: -0.25px;
  }

  .page_content .page_title_container p:nth-child(2) span {
    text-transform: uppercase;
    font-weight: 700;
    background: linear-gradient(
      145deg,
      rgb(76, 211, 255) -43.59%,
      rgb(0, 98, 254) 100%
    );
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;

const ReferralCard = styled.div`
  border-radius: 16px;
  border: none;
  background: #f7f9fb;
  box-sizing: content-box;
  padding: 24px;
  padding-bottom: 0px;
  display: flex;
  justify-content: space-between;

  .left {
    max-width: 630px;
    width: 100%;
  }

  .left .card_title {
    font-size: 32px;
    font-weight: 500;
    line-height: 1;
    color: #111111;
    margin-bottom: 16px;
  }

  .left .card_text {
    font-size: 20px;
    line-height: 120%;
    color: #686c77;
    margin-bottom: 16px;
  }

  img {
    max-width: 330px;
  }

  @media screen and (max-width: 768px) {
    & {
      width: 100%;
      /* display: grid; */
      display: flex;
      flex-direction: column-reverse;
      max-width: max-content;
      align-items: center;
      padding: 24px;
      box-sizing: border-box;
      gap: 16px;
    }

    .left {
      max-width: 410px;
      width: 100%;
      box-sizing: border-box;
    }

    .left .card_title {
      text-align: center;
    }

    .left .card_text {
      font-size: 20px;
      width: 100%;
      align-items: center;
      color: #686c77;
      line-height: 24px;
      font-family: Roboto;
      margin-top: 24px;
      text-align: center;
      font-weight: 500;
    }
  }
`;

export default ReferralProgramPage;
