import styled from "styled-components";
import "../../../css/settings/general.css";
import { useContext } from "react";
import { context } from "../../../context/context";

const General = () => {
  const { userData } = useContext(context);

  return (
    <div>
      <Box>
        <h1 className="box_title">Profile settings</h1>

        <div className="user_wrapper">
          <UserProfile>
            <p>{String(userData.email).slice(0, 2)}</p>
          </UserProfile>

          <div className="user_info">
            <p className="name">{userData.email}</p>
            <p className="register_info">3 days ago</p>
          </div>
        </div>
      </Box>

      <Box>
        <h1 className="box_title">Format settings</h1>

        <div className="section">
          <div className="date_items">
            <span className="flex_divide date_item">
              <p>Date and time format</p>
              <select className="light_select">
                <option>DD.MM.YYYY, HH:mm</option>
              </select>
            </span>
            <span className="flex_divide date_item">
              <p>Time zone</p>
              <select className="light_select">
                <option>UTC (+00:00)</option>
              </select>
            </span>
          </div>
        </div>
      </Box>

      {/* <div className="Profile-settings">
        <h1>Profile settings</h1>
        <div className="user-details">
          <div className="user">
            <div className="profile-circle">
              <p>Us</p>
            </div>
          </div>
          <div className="details">
            <p>Timothymaarv@gmail.com</p>
            <small>Registered March 26, 2024</small>
          </div>
        </div>
      </div> */}
      {/* <div className="Profile-settings">
        <h1>Format settings</h1>
        <div className="Date-time">
          <p>Date and time format</p>
          <select>
            <option value="dog">DD.MM.YYYY, HH:mm</option>
            <option value="cat">Cat</option>
          </select>
        </div>
        <div className="Date-time">
          <p>Timezone</p>
          <select>
            <option value="dog">UTC (+00:00)</option>
            <option value="cat">Cat</option>
          </select>
        </div>
      </div> */}
    </div>
  );
};

const Box = styled.div`
  padding: 24px;
  width: 70%;
  border: 1px solid #e1e8f1;
  border-radius: 20px;
  margin-bottom: 10px;
  /* height: 155px; */
  /* box-sizing: border-box; */

  input[type="checkbox"] {
    cursor: pointer;
    accent-color: #1968e5;
    border: 1px solid #1968e5;
    border-radius: 4px;
  }

  h1.box_title {
    font-size: 20px;
    font-weight: 700;
    line-height: 1;
    color: #111111;
  }

  .check_section {
  }

  .check_section p {
    font-size: 14px;
    line-height: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    white-space: nowrap;
  }

  .user_wrapper {
    display: flex;
    gap: 16px;
    align-items: center;
    margin-top: 24px;
  }

  .user_info {
    display: grid;
    gap: 4px;
  }

  .user_info .name {
    color: #111111;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    white-space: nowrap;
  }

  .user_info .register_info {
    color: #686c77;
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    white-space: nowrap;
  }

  .flex_center {
    display: flex;
    align-items: center;
  }

  .flex_divide {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .form {
    display: flex;
    flex-direction: column;
    max-width: 70%;
  }

  .flex-8 {
    gap: 8px;
  }

  .flex-12 {
    gap: 12px;
  }

  .flex-16 {
    gap: 16px;
  }

  .flex-24 {
    gap: 24px;
  }

  .flex-32 {
    gap: 32px;
  }

  .section {
    margin-top: 24px;
  }

  .date_items {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 12px;
  }

  .date_item p {
    font-size: 14px;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    white-space: nowrap;
  }

  .light_select {
    box-sizing: border-box;
    display: flex;
    outline: none;
    gap: 8px;
    background-color: rgba(17, 17, 17, 0.05);
    border-radius: 8px;
    padding: 8px 12px;
    font-size: 14px;
    border: none;
    align-items: center;
    line-height: 1;
    padding-right: 10px;
    font-weight: 500;
    height: 36px;
  }

  @media screen and (max-width: 768px) {
    & {
      width: 100% !important;
      box-sizing: border-box;
    }

    .form {
      width: 100%;
      display: flex;
      flex-direction: column;
      max-width: unset;
      box-sizing: border-box;
    }
  }
`;

const UserProfile = styled.div`
  width: 62px;
  height: 62px;
  border-radius: 50%;
  position: relative;
  container-type: inline-size;
  cursor: pointer;
  margin-left: 7px;
  border: 3px solid #b3261e;
  display: grid;
  place-content: center;
  text-transform: uppercase;
  font-weight: 700;
  color: #1968e5;
  box-sizing: border-box;
`;

const ProfileSettings = styled.div``;

export default General;
