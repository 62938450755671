import styled from "styled-components";
import LightButton from "../components/styled/LightButton";
import { useEffect, useState } from "react";
import Toast from "../hooks/Toast";
import { collection, getDocs, orderBy, query } from "firebase/firestore";
import { db } from "../firebase/firebase";
import CircularLoader from "../components/styled/loaders/CircularLoader";
import { Skeleton } from "@mui/material";
import { getTime } from "../utils/utils";

const UsersTable = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [userList, setUserList] = useState([]);

  useEffect(() => {
    const usersList = [];

    async function getUsers() {
      const q = query(collection(db, "users"), orderBy("registerDate", "desc"));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        usersList.push(doc.data());
      });
      console.log(userList);
      setUsers(usersList);
      setUserList(usersList);
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    }

    getUsers();
  }, []);

  function copyValue(value, type) {
    navigator.clipboard.writeText(value);

    // setType("success");
    // setMessage("Copied " + type);
    // setIsOpenCon(true);
  }

  function handleSearchUsers(e) {
    const { value } = e.target;
    let filteredUsers;

    if (value) {
      filteredUsers = userList.filter((users) =>
        // users.firstname.toLowerCase().includes(value.toLowerCase()) ||
        users.email.toLowerCase().includes(value.toLowerCase())
      );
      setUsers(filteredUsers);
    } else {
      setUsers(userList);
    }
  }

  // toast
  const [openToast, setOpenToast] = useState(false);
  const [toastMessage, setToastMessage] = useState(" ");
  const [toastType, setToastType] = useState(undefined);

  return (
    <TableWrapper className="scrollbar-hide">
      {openToast && (
        <Toast
          open={{ openToast, setOpenToast }}
          message={toastMessage}
          type={toastType}
        />
      )}
      <Search>
        <div className="search">
          <img src="./assets/icons/misc/search.svg" />
          <div className="search_wrapper">
            <div className="search_container">
              <input
                maxLength="40"
                placeholder="Search for user"
                onChange={handleSearchUsers}
              />

              {/* <img src="./assets/icons/misc/close.svg" /> */}
            </div>
          </div>
        </div>
      </Search>

      {isLoading && (
        <UsersTableWrapper>
          <thead>
            <tr>
              <th>
                {" "}
                <Skeleton
                  variant="rounded"
                  height={18}
                  width={150}
                  sx={{
                    backgroundColor: "#0000001f",
                    borderRadius: "32px",
                  }}
                />
              </th>
              <th>
                {" "}
                <Skeleton
                  variant="rounded"
                  height={18}
                  width={100}
                  sx={{
                    backgroundColor: "#0000001f",
                    borderRadius: "32px",
                  }}
                />
              </th>
              <th>
                <Skeleton
                  variant="rounded"
                  height={18}
                  width={120}
                  sx={{
                    backgroundColor: "#0000001f",
                    borderRadius: "32px",
                  }}
                />
              </th>
              <th>
                <Skeleton
                  variant="rounded"
                  height={18}
                  width={120}
                  sx={{
                    backgroundColor: "#0000001f",
                    borderRadius: "32px",
                  }}
                />
              </th>
              <th>
                <Skeleton
                  variant="rounded"
                  height={18}
                  width={80}
                  sx={{
                    backgroundColor: "#0000001f",
                    borderRadius: "32px",
                  }}
                />
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <Skeleton
                  variant="rounded"
                  height={18}
                  width={150}
                  sx={{
                    backgroundColor: "#0000001f",
                    borderRadius: "32px",
                  }}
                />
              </td>
              <td>
                <Skeleton
                  variant="rounded"
                  height={18}
                  width={100}
                  sx={{
                    backgroundColor: "#0000001f",
                    borderRadius: "32px",
                  }}
                />
              </td>
              <td>
                <Skeleton
                  variant="rounded"
                  height={18}
                  width={120}
                  sx={{
                    backgroundColor: "#0000001f",
                    borderRadius: "32px",
                  }}
                />
              </td>
              <td>
                <Skeleton
                  variant="rounded"
                  height={18}
                  width={120}
                  sx={{
                    backgroundColor: "#0000001f",
                    borderRadius: "32px",
                  }}
                />
              </td>
              <td>
                <Skeleton
                  variant="rounded"
                  height={18}
                  width={120}
                  sx={{
                    backgroundColor: "#0000001f",
                    borderRadius: "32px",
                  }}
                />
              </td>
              <td>
                <Skeleton
                  variant="rounded"
                  height={18}
                  width={100}
                  sx={{
                    backgroundColor: "#0000001f",
                    borderRadius: "32px",
                  }}
                />
              </td>
            </tr>
          </tbody>
        </UsersTableWrapper>
      )}

      {!isLoading && (
        <UsersTableWrapper>
          <thead>
            <tr>
              <th>User</th>
              <th>Account Type</th>
              <th>Register Date</th>
              <th>Last Login</th>
              <th>Status</th>
              <th></th>
            </tr>
          </thead>

          {users.length > 0 && (
            <tbody>
              {users.map((user, index) => (
                <tr key={index}>
                  <td>
                    <UserProfile>
                      <div className="icon">
                        <p>{String(user.email).slice(0, 2)}</p>
                      </div>
                      <p>{user.email}</p>
                    </UserProfile>
                  </td>
                  <td>{user.accountType}</td>
                  <td>{getTime(new Date() - user.registerDate.toDate())}</td>
                  <td>{getTime(new Date() - user.lastLogin.toDate())}</td>
                  <td>
                    <p className="enabled">Active</p>
                  </td>
                  <td>
                    <LightButton text="Manage" />
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </UsersTableWrapper>
      )}

      {!isLoading && !users.length > 0 && (
        <p
          style={{
            textAlign: "center",
            margin: "0 auto",
            padding: "16px",
            padddingTop: "0",
            width: "100%",
          }}
        >
          No users yet
        </p>
      )}
    </TableWrapper>
  );
};

const Search = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 0px;
  position: relative;
  width: 100%;
  height: 32px;
  border-bottom: 1px solid #d9d9d9;
  border-radius: 4px 4px 0px 0px;
  border-color: #686c77;

  .search {
    display: flex;
    align-items: center;
    align-self: stretch;
    gap: 12px;
    flex-grow: 1;
    max-width: 100%;
  }

  .search_wrapper {
    flex-grow: 1;
    overflow: hidden;
  }

  .search_container {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    gap: 12px;
    width: 100%;
    position: relative;
  }

  input {
    margin: 0px;
    padding: 0px;
    font-family: inherit;
    font-weight: inherit;
    line-height: inherit;
    color: inherit;
    flex-grow: 1;
    background: transparent;
    overflow: hidden;
    white-space: nowrap;
    min-width: 1%;
    width: 100%;
    text-overflow: ellipsis;
    max-width: 100%;
    font-size: 14px;
    outline: none;
    border: none;
  }

  .select_buttons {
    display: flex;
    gap: 24px;
  }
`;

const TableWrapper = styled.div`
  max-width: 90vw;
  overflow-y: auto;
  white-space: nowrap;
  display: grid;
  gap: 24px;

  p.enabled {
    color: green;
  }

  p.disabled {
    color: red;
  }
`;

const UsersTableWrapper = styled.table`
  padding-bottom: 24px;

  thead tr th {
    background: #ecf0f5;
    padding: 0px 16px;
    height: 52px;
    font-weight: 500;
    color: #070707;
    vertical-align: middle;
    text-align: start;
  }

  thead tr th:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  thead tr th:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  tbody tr td {
    padding: 0px 16px;
    height: 52px;
    font-weight: 400;
    color: #070707;
    vertical-align: middle;
    text-align: start;
  }

  tbody tr {
    cursor: pointer;
  }

  tbody tr:hover {
    background: #ecf0f567;
  }
`;

const UserProfile = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;

  .icon {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    position: relative;
    cursor: pointer;
    border: 1px solid #b3261e;
    display: grid;
    place-content: center;
    text-transform: uppercase;
    font-weight: 700;
    color: #1968e5;
    box-sizing: border-box;
  }
`;

export default UsersTable;
