import styled from "styled-components";
import Sidebar from "../components/general/Sidebar";
import Header from "../components/general/Header";
import { useContext, useState } from "react";
import { context } from "../context/context";

const DashboardPage = () => {
  const [activeTab, setActiveTab] = useState("Dashboard");
  const [sidebarHide, setSidebarHide] = useState(true);
  const [sidebarCollapse, setSidebarCollapse] = useState(false);

  const { userData } = useContext(context);

  return (
    <PageContainer>
      <Header
        collapse={{ sidebarCollapse, setSidebarCollapse }}
        hide={{ sidebarHide, setSidebarHide }}
      />
      <PageWrapper>
        <Sidebar
          tab={{ activeTab, setActiveTab }}
          hide={{ sidebarHide, setSidebarHide }}
          collapse={{ sidebarCollapse, setSidebarCollapse }}
        />
        <Main className="scrollbar-hide">
          <div className="page_content ">
            <div className="page_title">Dashboard</div>

            {/* email_alert */}

            {/* <EmailAlert>
              <span className="left">
                <img src="./assets/icons/misc/warning.svg" />
                <p>Please confirm your email address</p>
              </span>

              <button>Confirm</button>
            </EmailAlert> */}

            <div className="info_cards">
              {/* main account card */}
              <MainAccountCard>
                <div className="main_account_top">
                  <h5 className="title">Main account</h5>
                  <h1 className="amount">
                    $
                    {String(userData?.totalBalance).slice(
                      0,
                      String(userData?.totalBalance).indexOf(".")
                    )}
                    <span>
                      {String(userData?.totalBalance).slice(
                        String(userData?.totalBalance).indexOf(".")
                      )}
                    </span>
                  </h1>
                </div>

                <div className="btns">
                  <button className="top_up_button">
                    <img src="./assets/icons/misc/top-up.svg" />
                    <p>Top up</p>
                  </button>

                  <button className="transfer_button">
                    <img src="./assets/icons/misc/transfer.svg" />
                    <p>Transfer between accounts</p>
                  </button>
                </div>
              </MainAccountCard>
              {/* personal cards card */}
              <PersonalCardsCard>
                <div className="main_account_top">
                  <h5 className="title">Personal cards</h5>
                  <h1 className="amount">
                    $0
                    <span>.00</span>
                  </h1>
                </div>

                <div className="btns">
                  <button className="transfer_button">
                    <img src="./assets/icons/misc/issue-card.svg" />
                    <p>Issue a new card</p>
                  </button>
                </div>
              </PersonalCardsCard>
              {/* total amount card */}
              <TotalAmountCard>
                <div className="main_account_top">
                  <h5 className="title">Total amount</h5>
                  <h1 className="amount">
                    $0
                    <span>.00</span>
                  </h1>
                </div>

                <div className="stats_divide">
                  <div className="stat">
                    <p className="stat_title">Team accounts</p>
                    <h3 className="stat_text">
                      $0
                      <span>.00</span>
                    </h3>
                  </div>

                  <div className="divider"></div>

                  <div className="stat">
                    <p className="stat_title">Team cards</p>
                    <h3 className="stat_text">
                      $0
                      <span>.00</span>
                    </h3>
                  </div>
                </div>
              </TotalAmountCard>
            </div>

            <div className="statistics_cards">
              {/* account level card */}
              <AccountLevelCard>
                <div className="account_level_top">
                  <span className="title">
                    <p>Your account level:</p>
                    <h4>Standard</h4>
                  </span>
                  <button>More detailed</button>
                </div>
                <div className="progress"></div>
                <div className="account_level_bottom">
                  <p className="left">TopUps in 30 days: $0</p>
                  <p className="right">$1 000 left of $1 000</p>
                </div>
              </AccountLevelCard>
              {/* expenses_card */}
              <ExpensesCard>
                <div className="main_account_top">
                  <h5 className="title">Expenses on your cards</h5>
                  <div className="title_divide">
                    <p>Personal</p>
                    <div className="mini_divider"></div>
                    <p>Team</p>
                  </div>
                </div>

                <div className="stats_divide">
                  <div className="stat">
                    <p className="stat_title">Team accounts</p>
                    <h3 className="stat_text">
                      $0
                      <span>.00</span>
                    </h3>
                  </div>

                  <div className="divider"></div>

                  <div className="stat">
                    <p className="stat_title">Team cards</p>
                    <h3 className="stat_text">
                      $0
                      <span>.00</span>
                    </h3>
                  </div>
                </div>
              </ExpensesCard>
            </div>
          </div>
        </Main>
      </PageWrapper>
    </PageContainer>
  );
};

const PageContainer = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
`;

const PageWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: grid;
  grid-template-columns: max-content auto;
  box-sizing: border-box;

  @media screen and (max-width: 768px) {
    & {
      grid-template-columns: auto auto;
    }
  }
`;

const Main = styled.div`
  background-color: white;
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
  padding: 0px 30px;

  .page_content {
    width: 100%;
    padding: 24px 0;
    margin: 0 auto;
    max-width: 1074px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    max-width: 1074px;
  }

  .page_content .page_title {
    font-size: 32px;
    font-weight: 800;
    line-height: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    white-space: nowrap;
    font-family: Roboto;
  }

  .page_content .info_cards {
    display: flex;
    gap: 24px;
  }

  .page_content .statistics_cards {
    display: flex;
    gap: 24px;
  }

  @media screen and (max-width: 1200px) {
    .page_content .info_cards {
      display: grid;
      width: 100%;
      grid-template-areas:
        "main main cards cards"
        "main main cards cards"
        "amount amount amount amount"
        "amount amount amount amount";
    }

    .page_content .statistics_cards {
      display: grid;
      grid-template-columns: auto;
    }
  }

  @media screen and (max-width: 768px) {
    .page_content .info_cards {
      display: grid;
      width: 100%;
      grid-template-areas:
        "main main main main"
        "cards cards cards cards"
        "amount amount amount amount";
    }
  }
`;

const EmailAlert = styled.div`
  display: flex;
  gap: 8px;
  padding: 16px;
  background: #fbe3e3;
  border-radius: 16px;
  border: 1px solid #df1b1b;
  max-width: max-content;
  align-items: center;

  .left {
    display: flex;
    gap: 8px;
    align-items: center;
  }

  .left p {
    font-size: 14px;
    line-height: 142%;
    gap: 8px;
    color: #df1b1b;
  }

  button {
    font-size: 14px;
    color: rgba(5, 104, 229, 1);
    background: none;
    border: none;
    font-weight: 500;
    cursor: pointer;
  }
`;

const MainAccountCard = styled.div`
  padding: 24px;
  background-color: #f7f9fb;
  max-width: 339px;
  width: 100%;
  border-radius: 16px;
  box-sizing: border-box;
  font-family: Roboto !important;
  grid-area: main;

  @media screen and (max-width: 1200px) {
    & {
      max-width: unset;
      width: 100%;
    }
  }

  .main_account_top .title {
    margin-bottom: 16px;
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
  }

  .main_account_top .amount {
    font-size: 36px;
    line-height: 40px;
    font-weight: 700;
  }

  .main_account_top .amount span {
    color: #94a3b8;
    font-size: 16px;
  }

  .btns {
    margin-top: 48px;
    display: grid;
    gap: 12px;
    grid-template-columns:
      minmax(auto, max-content)
      minmax(auto, 1fr);
  }

  .top_up_button {
    white-space: nowrap;
    display: flex;
    max-height: 32px;
    font-size: 12px;
    border-radius: 8px;
    padding: 8px 12px;
    border: 1px solid transparent;
    gap: 8px;
    justify-content: center;
    align-items: center;
    background-color: #000;
    font-weight: 500;
    cursor: pointer;
    font-family: Roboto;
    font-size: 12px;
  }

  .transfer_button {
    white-space: nowrap;
    display: flex;
    max-height: 32px;
    font-size: 12px;
    border-radius: 8px;
    padding: 8px 12px;
    border: 1px solid transparent;
    gap: 8px;
    justify-content: center;
    align-items: center;
    background-color: #000;
    font-weight: 500;
    cursor: pointer;
    color: rgba(17, 17, 17, 1);
    background: rgba(17, 17, 17, 0.05);
    font-family: Roboto;
    font-size: 12px;
  }

  .top_up_button:hover {
    /* color: rgba(254, 254, 254, 1); */
    background: rgba(0, 0, 0, 0.75);
  }

  .top_up_button p {
    color: #fefefe;
    font-size: 12px;
    font-family: Roboto;
  }

  .transfer_button p {
    font-size: 12px;
    font-family: Roboto;
    white-space: wrap;
  }
`;

const PersonalCardsCard = styled.div`
  padding: 24px;
  background-color: #f7f9fb;
  max-width: 295px;
  width: 100%;
  border-radius: 16px;
  box-sizing: border-box;
  font-family: Roboto !important;
  grid-area: cards;

  @media screen and (max-width: 1200px) {
    & {
      max-width: unset;
      width: 100%;
    }
  }

  .main_account_top .title {
    margin-bottom: 16px;
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
  }

  .main_account_top .amount {
    font-size: 36px;
    line-height: 40px;
    font-weight: 700;
  }

  .main_account_top .amount span {
    color: #94a3b8;
    font-size: 16px;
  }

  .btns {
    margin-top: 48px;
    display: grid;
    gap: 12px;
    grid-template-columns:
      minmax(auto, max-content)
      minmax(auto, 1fr);
  }

  .top_up_button {
    white-space: nowrap;
    display: flex;
    max-height: 32px;
    font-size: 12px;
    border-radius: 8px;
    padding: 8px 12px;
    border: 1px solid transparent;
    gap: 8px;
    justify-content: center;
    align-items: center;
    background-color: #000;
    font-weight: 500;
    cursor: pointer;
    font-family: Roboto;
    font-size: 12px;
  }

  .transfer_button {
    white-space: nowrap;
    display: flex;
    max-height: 32px;
    font-size: 12px;
    border-radius: 8px;
    padding: 8px 12px;
    border: 1px solid transparent;
    gap: 8px;
    justify-content: center;
    align-items: center;
    background-color: #000;
    font-weight: 500;
    cursor: pointer;
    color: rgba(17, 17, 17, 1);
    background: rgba(17, 17, 17, 0.05);
    font-family: Roboto;
    font-size: 12px;
  }

  .top_up_button:hover {
    /* color: rgba(254, 254, 254, 1); */
    background: rgba(0, 0, 0, 0.75);
  }

  .top_up_button p {
    color: #fefefe;
    font-size: 12px;
    font-family: Roboto;
  }
`;

const TotalAmountCard = styled.div`
  padding: 24px;
  background-color: #f7f9fb;
  max-width: 339px;
  width: 100%;
  border-radius: 16px;
  box-sizing: border-box;
  font-family: Roboto !important;
  grid-area: amount;

  @media screen and (max-width: 1200px) {
    & {
      max-width: unset;
      width: 100%;
    }
  }

  .main_account_top .title {
    margin-bottom: 16px;
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
  }

  .main_account_top .amount {
    font-size: 36px;
    line-height: 40px;
    font-weight: 700;
  }

  .main_account_top .amount span {
    color: #94a3b8;
    font-size: 16px;
  }

  .stats_divide {
    margin-top: 48px;
    display: flex;
    justify-content: space-between;
    gap: 12px;
  }

  .stats_divide .stat {
  }

  .stats_divide .stat .stat_title {
    font-size: 10px;
    line-height: 16px;
    font-weight: 500;
    color: #828282;
  }

  .stats_divide .stat .stat_text {
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
    margin-top: 4px;
  }

  .stats_divide .stat .stat_text span {
    color: #94a3b8;
    font-size: 10px;
  }

  .stats_divide .divider {
    width: 1px;
    height: 44px;
    background: #c3d7e5;
  }
`;

const ExpensesCard = styled.div`
  padding: 24px;
  background-color: #f7f9fb;
  max-width: 475px;
  width: 100%;
  border-radius: 16px;
  box-sizing: border-box;
  font-family: Roboto !important;

  .main_account_top {
    display: flex;
    justify-content: space-between;
  }

  .main_account_top .title {
    margin-bottom: 16px;
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
  }

  .main_account_top .title_divide {
    display: flex;
    gap: 16px;
    max-height: max-content;
    box-sizing: border-box;
    height: fit-content;
  }

  .main_account_top .title_divide p {
    font-size: 10px;
    font-weight: 500;
    color: rgba(51, 55, 66, 0.5);
  }

  .title_divide .mini_divider {
    width: 1px;
    height: 12px;
    background: #c3d7e5;
  }

  .stats_divide {
    /* margin-top: 18px; */
    display: flex;
    justify-content: space-between;
    gap: 12px;
  }

  .stats_divide .stat .stat_title {
    font-size: 10px;
    line-height: 16px;
    font-weight: 500;
    color: #828282;
  }

  .stats_divide .stat .stat_text {
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
    margin-top: 4px;
  }

  .stats_divide .stat .stat_text span {
    color: #94a3b8;
    font-size: 10px;
  }

  .stats_divide .divider {
    width: 1px;
    height: 44px;
    background: #c3d7e5;
  }

  @media screen and (max-width: 1200px) {
    & {
      max-width: unset;
      width: 100%;
    }
  }

  @media screen and (max-width: 768px) {
    .main_account_top {
      display: flex;
      flex-direction: column;
    }

    .stats_divide {
      flex-direction: column;
      margin-top: 16px;
    }

    .main_account_top .title {
      margin-bottom: 8px;
    }

    .stats_divide .divider {
      width: 100% !important;
      height: 1px !important;
      background: #c3d7e5;
    }
  }
`;

const AccountLevelCard = styled.div`
  padding: 24px;
  background-color: #f7f9fb;
  border-radius: 16px;
  font-family: Roboto !important;
  flex: 1;
  max-width: 430px;
  box-sizing: border-box;

  .progress {
    height: 4px;
    width: 100%;
    border-radius: 13px;
    background: #e1e8f1;
    margin-bottom: 12px;
    box-sizing: border-box;
    margin-top: 12px;
  }

  .account_level_top {
    display: flex;
    justify-content: space-between;
  }

  .account_level_top button {
    background-color: transparent;
    outline: none;
    border: none;
    color: #1968e5;
    font-size: 12px;
    font-family: roboto;
    font-weight: 500;
  }

  .account_level_top .title {
    display: flex;
    gap: 8px;
  }

  .account_level_top .title p {
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
    color: #828282;
  }

  .account_level_top .title h4 {
    font-size: 24px;
    line-height: 24px;
    font-weight: 500;
  }

  .account_level_bottom {
    display: flex;
    justify-content: space-between;
    margin-top: auto;
  }

  .account_level_bottom p.left {
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    color: #333742;
  }

  .account_level_bottom p.right {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: #828282;
  }

  @media screen and (max-width: 1200px) {
    & {
      max-width: unset;
      width: 100%;
    }

    .account_level_top .title {
      flex-direction: column;
    }

    .account_level_bottom p.left {
      font-size: 10px;
    }

    .account_level_bottom p.right {
      font-size: 10px;
    }
  }
`;

export default DashboardPage;
