import { createContext, useEffect, useState, useReducer } from "react";
import { auth, db } from "../firebase/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import axios from "axios";
import { signOut } from "firebase/auth";
import { doc, onSnapshot } from "firebase/firestore";

const INITIAL_STATE = {
  currentUser: null,
};

const userReducer = function (state, action) {
  switch (action.type) {
    case "login":
      return { currentUser: action.payload };
    case "logout":
      signOut(auth);
      return { currentUser: null };
    default:
      return state.currentUser;
  }
};

export const context = createContext(INITIAL_STATE);

const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(userReducer, INITIAL_STATE);

  // user
  const [user, loading] = useAuthState(auth);

  //   user_data
  const [userData, setUserData] = useState({});

  //   crypto
  const [btcPrice, setBtcPrice] = useState("");
  const [ethPrice, setEthPrice] = useState("");
  const [solPrice, setSolPrice] = useState("");
  const [bchPrice, setBchPrice] = useState("");
  const [ltcPrice, setLtcPrice] = useState("");
  const [dogePrice, setDogePrice] = useState("");
  const [usdtPrice, setUsdtPrice] = useState("");
  const [maticPrice, setMaticPrice] = useState("");
  const [avaxPrice, setAvaxPrice] = useState("");

  // Crypto extra
  const [oneInchPrice, setOneInchPrice] = useState("");
  const [aavePrice, setAavePrice] = useState("");
  const [algoPrice, setAlgoPrice] = useState("");
  const [ancPrice, setAncPrice] = useState("");
  const [apePrice, setApePrice] = useState("");
  const [auroraPrice, setAuroraPrice] = useState("");
  const [axsPrice, setAxsPrice] = useState("");
  const [btgPrice, setBtgPrice] = useState("");
  const [boringPrice, setBoringPrice] = useState("");
  const [adaPrice, setAdaPrice] = useState("");
  const [xcnPrice, setXcnPrice] = useState("");
  const [linkPrice, setLinkPrice] = useState("");
  const [cmdxPrice, setCmdxPrice] = useState("");
  const [croPrice, setCroPrice] = useState("");
  const [daiPrice, setDaiPrice] = useState("");
  const [daoPrice, setDaoPrice] = useState("");
  const [dashPrice, setDashPrice] = useState("");
  const [manaPrice, setManaPrice] = useState("");
  const [dflPrice, setDflPrice] = useState("");
  const [nrgPrice, setNrgPrice] = useState("");
  const [etcPrice, setEtcPrice] = useState("");
  const [evmosPrice, setEvmosPrice] = useState("");
  const [gtPrice, setGtPrice] = useState("");
  const [goPrice, setGoPrice] = useState("");
  const [lnPrice, setLnPrice] = useState("");
  const [xmrPrice, setXmrPrice] = useState("");
  const [nexoPrice, setNexoPrice] = useState("");
  const [okbPrice, setOkbPrice] = useState("");
  const [opPrice, setOpPrice] = useState("");
  const [ognPrice, setOgnPrice] = useState("");
  const [ornPrice, setOrnPrice] = useState("");
  const [dotPrice, setDotPrice] = useState("");
  const [xprPrice, setXprPrice] = useState("");
  const [xrpPrice, setXrpPrice] = useState("");
  const [rainiPrice, setRainiPrice] = useState("");
  const [rariPrice, setRariPrice] = useState("");
  const [sfpPrice, setSfpPrice] = useState("");
  const [shibPrice, setShibPrice] = useState("");
  const [xlmPrice, setXlmPrice] = useState("");
  const [stepPrice, setStepPrice] = useState("");
  const [gmtPrice, setGmtPrice] = useState("");
  const [sushiPrice, setSushiPrice] = useState("");
  const [tlosPrice, setTlosPrice] = useState("");
  const [xtzPrice, setXtzPrice] = useState("");
  const [grtPrice, setGrtPrice] = useState("");
  const [trxPrice, setTrxPrice] = useState("");
  const [uniPrice, setUniPrice] = useState("");
  const [vetPrice, setVetPrice] = useState("");
  const [wingPrice, setWingPrice] = useState("");
  const [wxtPrice, setWxtPrice] = useState("");
  const [timePrice, setTimePrice] = useState("");
  const [zecPrice, setZecPrice] = useState("");
  const [zigPrice, setZigPrice] = useState("");

  const [depositSettings, setDepositSettings] = useState({});

  async function getData(id) {
    onSnapshot(doc(db, "users", id), (doc) => {
      const data = doc.data();

      if (data) {
        const {
          firstname,
          lastname,
          id,
          email,
          photoURL,
          admin,
          accountType,
          totalBalance,
        } = data;
        const userInfo = {
          firstname,
          lastname,
          id,
          email,
          photoURL,
          admin,
          accountType,
          totalBalance,
        };
        setUserData(userInfo);
      } else {
        return;
      }
    });

    // if (isDeletingUser) {
    // isDeletingUser && unsubscribe();
    // }
  }

  async function getDeposit() {
    onSnapshot(doc(db, "admin", "deposit"), (doc) => {
      const data = doc.data();
      setDepositSettings(data);
    });
  }

  async function additionalCryptoPrices() {
    const url = `https://redstone.p.rapidapi.com/prices?provider=redstone&symbols=1INCH,AAVE,ALGO,ANC,APE,AURORA,AXS,BTG,ADA,XCN,LINK,CRO,DAI,DAO,DASH,MANA,DFL,ETC,EVMOS,GT,LN,XMR,NEXO,OKB,OP,OGN,ORN,DOT,XPR,RARI,SFP,SHIB,XLM,GMT,SUSHI,TLOS,XTZ,GRT,TRX,UNI,VET,WING,WXT,TIME,ZEC,ZIG,XRP,AVAX,BCH,BTC,DOGE,ETH,LTC,MATIC,SOL,USDT,QNT`;

    const config = {
      method: "get",
      headers: {
        "X-RapidAPI-Key": "396a8cb761mshc0459779f675ee6p18d42djsn4cd87cfd13f7",
        "X-RapidAPI-Host": "redstone.p.rapidapi.com",
      },
    };

    try {
      const response = await axios.get(url, config);

      if (response) {
        const { data } = response;

        setOneInchPrice(data["1INCH"].value);
        setAavePrice(data.AAVE.value);
        setAdaPrice(data.ADA.value);
        setAlgoPrice(data.ALGO.value);
        setAncPrice(data["ANC"].value);
        setApePrice(data.APE.value);
        setAuroraPrice(data.AURORA.value);
        setAvaxPrice(data.AVAX.value);
        setAxsPrice(data["AXS"].value);
        setBchPrice(data.BCH.value);
        setBtcPrice(data.BTC.value);
        setBtgPrice(data["BTG"].value);
        setCroPrice(data.CRO.value);
        setDaiPrice(data.DAI.value);
        setDaoPrice(data["DAO"].value);
        setDashPrice(data.DASH.value);
        setDogePrice(data.DOGE.value);
        setDotPrice(data.DOT.value);
        setEtcPrice(data["ETC"].value);

        setEthPrice(data.ETH.value);
        setEvmosPrice(data.EVMOS.value);
        setGmtPrice(data.GMT.value);
        setGrtPrice(data.GRT.value);
        setGtPrice(data.GT.value);
        setLinkPrice(data.LINK.value);
        setLnPrice(data.LN.value);
        setLtcPrice(data.LTC.value);
        setManaPrice(data.MANA.value);
        setMaticPrice(data.MATIC.value);

        setNexoPrice(data.NEXO.value);
        setOgnPrice(data["OGN"].value);
        setOkbPrice(data.OKB.value);
        setOrnPrice(data["ORN"].value);
        setOpPrice(data.OP.value);
        setRariPrice(data.RARI.value);
        setSushiPrice(data.SUSHI.value);
        setTimePrice(data.TIME.value);
        setTlosPrice(data.TLOS.value);
        setTrxPrice(data.TRX.value);
        setUniPrice(data.UNI.value);
        setVetPrice(data.VET.value);
        setWingPrice(data["WING"].value);
        setWxtPrice(data.WXT.value);
        setXcnPrice(data.XCN.value);
        setXlmPrice(data.XLM.value);
        setXmrPrice(data.XMR.value);
        setXprPrice(data.XPR.value);
        setXrpPrice(data.XRP.value);
        setXtzPrice(data.XTZ.value);
        setZecPrice(data.ZEC.value);
        setZigPrice(data.ZIG.value);
        setSfpPrice(data.SFP.value);
        setShibPrice(data["SHIB"].value);

        setSolPrice(data.SOL.value);

        setUsdtPrice(data.USDT.value);
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  async function fetchCryptoBackup() {
    const url = `https://coingecko.p.rapidapi.com/simple/price?ids=bitcoin%2C1inch%2Caave%2Calgorand%2Canchor-protocol%2Caxie-infinity%2Cbitcoin-gold%2Ccardano%2Cchainlink%2Ccronos%2Cdai%2Cdao-maker%2Cdash%2Cdecentraland%2Cdefi-land%2Cethereum-classic%2Cevmos%2Cstellar%2Cethereum%2Cmatic%2Csolana%2Cavalanche%2Cdogecoin%2Cethereum%2Clitecoin%2Ctether%2Cbitcoin-cash%2Cproton%2Cusd-coin%2C&vs_currencies=usd`;

    const config = {
      method: "get",
      headers: {
        "X-RapidAPI-Host": "coingecko.p.rapidapi.com",
        "X-RapidAPI-Key": "396a8cb761mshc0459779f675ee6p18d42djsn4cd87cfd13f7",
      },
    };

    try {
      const response = await axios.get(url, config);
      // `live.Crypto.${type}.value`
      // const { data } = response;
      if (response) {
        const { data } = response;
        setOneInchPrice(data["1inch"].usd);
        setAavePrice(data.aave.usd);
        setAlgoPrice(data.algorand.usd);
        setAncPrice(data["anchor-protocol"].usd);
        setAxsPrice(data["axie-infinity"].usd);
        setBtcPrice(data.bitcoin.usd);
        setBtgPrice(data["bitcoin-gold"].usd);
        setAdaPrice(data.cardano.usd);
        setDaiPrice(data.dai.usd);
        setDaoPrice(data["dao-maker"].usd);
        setDashPrice(data.dash.usd);
        setManaPrice(data.decentraland.usd);
        setDflPrice(data["defi-land"].usd);
        setDogePrice(data.dogecoin.usd);
        setEthPrice(data.ethereum.usd);
        setEtcPrice(data["ethereum-classic"].usd);
        setEvmosPrice(data.evmos.usd);
        setLtcPrice(data.litecoin.usd);
        setXprPrice(data.proton.usd);
        setSolPrice(data.solana.usd);
        setXlmPrice(data.stellar.usd);
        setUsdtPrice(data.tether.usd);
        setBchPrice(data["bitcoin-cash"].usd);
      }
    } catch (error) {
      console.log("error fetching", error);
    }
  }

  const currentPrices = {
    // Crypto
    BTC: btcPrice,
    ETH: ethPrice,
    SOL: solPrice,
    BCH: bchPrice,
    LTC: ltcPrice,
    DOGE: dogePrice,
    USDT: Number(usdtPrice),
    USDC: Number(usdtPrice),
    MATIC: maticPrice,
    AVAX: avaxPrice,
    "1INCH": oneInchPrice,
    AAVE: aavePrice,
    ALGO: algoPrice,
    ANC: ancPrice,
    APE: apePrice,
    AURORA: auroraPrice,
    AXS: axsPrice,
    BTG: btgPrice,
    BORING: boringPrice,
    ADA: adaPrice,
    XCN: xcnPrice,
    LINK: linkPrice,
    CMDX: cmdxPrice,
    CRO: Number(croPrice),
    DAI: Number(daiPrice),
    DAO: daoPrice,
    DASH: dashPrice,
    MANA: manaPrice,
    DFL: dflPrice,
    NRG: nrgPrice,
    ETC: etcPrice,
    EVMOS: evmosPrice,
    GT: gtPrice,
    GO: goPrice,
    LN: lnPrice,
    XMR: xmrPrice,
    NEXO: nexoPrice,
    OKB: okbPrice,
    OP: opPrice,
    OGN: ognPrice,
    ORN: ornPrice,
    DOT: dotPrice,
    XPR: xprPrice,
    XRP: xrpPrice,
    RAINI: rainiPrice,
    RARI: rariPrice,
    SFP: sfpPrice,
    SHIB: shibPrice,
    XLM: xlmPrice,
    STEP: stepPrice,
    GMT: gmtPrice,
    SUSHI: sushiPrice,
    TLOS: tlosPrice,
    XTZ: xtzPrice,
    GRT: grtPrice,
    TRX: trxPrice,
    UNI: uniPrice,
    VET: vetPrice,
    WING: wingPrice,
    WXT: wxtPrice,
    TIME: timePrice,
    ZEC: zecPrice,
    ZIG: zigPrice,

    // Fiat
    USD: 1,
  };

  useEffect(() => {
    if (!loading && user) {
      getDeposit();
      getData(user.uid);
    }

    // fetchCryptoPrices();
    // fetchStockPrices();
    additionalCryptoPrices();
    fetchCryptoBackup();
  }, [user, loading]);

  return (
    <context.Provider
      value={{
        currentUser: state.currentUser,
        dispatch,
        currentPrices,
        userData,
        depositSettings,
      }}
    >
      {children}
    </context.Provider>
  );
};

export default Provider;
