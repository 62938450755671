import styled from "styled-components";
import Sidebar from "../components/general/Sidebar";
import Header from "../components/general/Header";
import { useContext, useEffect, useState } from "react";
import PrimaryButton from "../components/styled/PrimaryButton";
import NoCards from "../components/empty-states/NoCards";
import IssueCard from "../components/IssueCard";
import LightButtonWithIcon from "../components/styled/LightButtonWithIcon";
import { Skeleton } from "@mui/material";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../firebase/firebase";
import { context } from "../context/context";
import ShowCardModal from "../modals/ShowCardModal";

const CardsPage = () => {
  const { userData } = useContext(context);
  const { id } = userData;
  const [activeTab, setActiveTab] = useState("Cards");
  const [sidebarHide, setSidebarHide] = useState(true);
  const [sidebarCollapse, setSidebarCollapse] = useState(false);

  const [issueCard, setIssueCard] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  const fauxCards = [1, 2, 3, 4, 5];

  const [cards, setCards] = useState([]);
  const [cardsList, setCardsList] = useState([]);

  const [showCard, setShowCard] = useState(false);
  const [details, setDetails] = useState({});

  function handleShowCard(card) {
    setDetails(card);
    setShowCard(true);
  }

  useEffect(() => {
    const cardList = [];

    async function getCards() {
      const q = query(collection(db, "cards"), where("user", "==", id));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        cardList.push(doc.data());
      });
      setCards(cardList);
      setCardsList(cardList);
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    }

    getCards();
  }, []);

  function handleSearchCards(e) {
    const { value } = e.target;
    let filteredCards;

    if (value) {
      filteredCards = cardsList.filter(
        (cards) =>
          cards.name?.toLowerCase().includes(value.toLowerCase()) ||
          cards.type?.toLowerCase().includes(value.toLowerCase()) ||
          cards.label?.toLowerCase().includes(value.toLowerCase()) ||
          cards.number?.includes(value)
      );
      setCards(filteredCards);
    } else {
      setCards(cardsList);
    }
  }

  return (
    <PageContainer>
      {showCard && (
        <ShowCardModal open={{ showCard, setShowCard }} details={details} />
      )}

      <Header
        collapse={{ sidebarCollapse, setSidebarCollapse }}
        hide={{ sidebarHide, setSidebarHide }}
      />
      <PageWrapper>
        <Sidebar
          tab={{ activeTab, setActiveTab }}
          hide={{ sidebarHide, setSidebarHide }}
          collapse={{ sidebarCollapse, setSidebarCollapse }}
        />
        <Main>
          {!issueCard && (
            <div className="page_content">
              <div className="page_top">
                <div className="page_title">Cards</div>
                <PrimaryButton
                  text="Issue a card"
                  action={() => setIssueCard(!issueCard)}
                />
              </div>
              <Cards>
                <div className="cards_top">
                  {/* search */}
                  <Search>
                    <div className="search">
                      <img src="./assets/icons/misc/search.svg" />
                      <div className="search_wrapper">
                        <div className="search_container">
                          <input
                            id="input_28e518091b299"
                            maxLength="40"
                            placeholder="Card search"
                            onChange={handleSearchCards}
                          />

                          <img src="./assets/icons/misc/close.svg" />
                        </div>
                      </div>
                    </div>
                  </Search>

                  <div className="right">
                    {/* select buttons */}
                    {/* <div className="buttons"> */}
                    <SelectBox>
                      <p>Card type</p>
                      <img src="./assets/icons/misc/dark-dropdown.svg" />
                    </SelectBox>

                    <SelectBox>
                      <p>Status: Active</p>
                      <img src="./assets/icons/misc/dark-dropdown.svg" />
                    </SelectBox>
                    {/* </div> */}

                    {/* reset filters btn */}
                    <button className="blue_button">Reset filters</button>
                  </div>
                </div>

                {/* <LightButtonWithIcon
                  src="./assets/icons/misc/settings.svg"
                  text="Manage tags"
                /> */}
              </Cards>

              {isLoading && (
                <LoadingCardsRoll>
                  {isLoading &&
                    fauxCards.map((card, index) => (
                      <LoadingBox key={index}>
                        <div className="top">
                          <Skeleton
                            variant="rounded"
                            height={16}
                            width={"100%"}
                            sx={{
                              backgroundColor: "#0000001f",
                              borderRadius: "32px",
                            }}
                          />
                          <Skeleton
                            variant="rounded"
                            height={16}
                            width={"100%"}
                            sx={{
                              backgroundColor: "#0000001f",
                              borderRadius: "32px",
                            }}
                          />
                        </div>

                        <div className="mid">
                          <Skeleton
                            variant="rounded"
                            height={16}
                            width={"100%"}
                            sx={{
                              backgroundColor: "#0000001f",
                              borderRadius: "32px",
                            }}
                          />
                        </div>
                        <div className="bottom">
                          <Skeleton
                            variant="rounded"
                            height={16}
                            width={"100%"}
                            sx={{
                              backgroundColor: "#0000001f",
                              borderRadius: "32px",
                            }}
                          />
                          <Skeleton
                            variant="rounded"
                            height={16}
                            width={"100%"}
                            sx={{
                              backgroundColor: "#0000001f",
                              borderRadius: "32px",
                            }}
                          />
                        </div>
                      </LoadingBox>
                    ))}
                </LoadingCardsRoll>
              )}

              {!isLoading && !cards.length > 0 && (
                <NoCards action={() => setIssueCard(!issueCard)} />
              )}

              {!isLoading && cards.length > 0 && (
                <CardRoller>
                  <div className="title">
                    Cards <span className="badge"> {cards?.length}</span>
                  </div>

                  <div className="cards">
                    {cards.map((card, index) => (
                      <Card key={index}>
                        <div className="card_wrapper">
                          <div className="card_content">
                            <div className="title">{card.type}</div>
                            <div className="bin">{String(card.number)}</div>

                            <button
                              className="dark_button"
                              onClick={() => handleShowCard(card)}
                            >
                              View details
                            </button>
                          </div>
                        </div>
                      </Card>
                    ))}
                  </div>
                </CardRoller>
              )}
            </div>
          )}

          {issueCard && (
            <div className="page_content">
              <IssueCard issue={{ issueCard, setIssueCard }} />
            </div>
          )}
        </Main>
      </PageWrapper>
    </PageContainer>
  );
};

const LoadingCardsRoll = styled.div`
  display: flex;
  gap: 30px;

  @media screen and (max-width: 1200px) {
    & {
      display: grid;
      grid-gap: 30px;
      grid-template-columns: auto auto auto;
      grid-template-rows: auto auto;
    }
  }

  @media screen and (max-width: 768px) {
    & {
      display: grid;
      grid-template-columns: auto auto;
      /* flex-direction: column; */
      box-sizing: border-box;
    }
  }
`;

const CardRoller = styled.div`
  border: 1px solid #e1e8f1;
  padding: 12px;
  border-radius: 16px;
  margin-top: 32px;
  width: 100%;

  .title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
  }

  .badge {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    padding: 0px 6px;
    background: #1968e5;
    height: 20px;
    text-align: center;
    font-size: 10px;
    font-weight: 500;
    line-height: 1;
    color: #fefefe;
  }

  .description {
    margin-top: 16px;
    font-size: 12px;
    line-height: 125%;
    font-weight: 500;
    color: #828282;
    max-width: 70%;
    display: none;
  }

  .cards {
    gap: 24px;
    display: grid;
    margin-top: 32px;
    grid-template-columns: 0.5fr 0.5fr;
    box-sizing: border-box;
  }

  @media screen and (max-width: 768px) {
    .cards {
      display: flex;
      flex-direction: column;
    }
  }
`;

const LoadingBox = styled.div`
  display: grid;
  background: #f7f9fb;
  height: 118px;
  width: 100%;
  color: #fff;
  border-radius: 8px;
  overflow: hidden;
  padding: 10px 10px 12px;
  position: relative;

  .mid {
    margin-top: 32px;
  }

  .top,
  .bottom {
    display: flex;
    width: 100%;
    gap: 12px;
  }
`;

const PageContainer = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
`;

const Card = styled.div`
  padding: 24px;
  border-radius: 16px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  flex-grow: 1;
  border: none;
  background: #f7f9fb;
  box-sizing: border-box;
  width: 100%;

  .card_wrapper {
    display: flex;
    justify-content: flex-start;
    flex-grow: 1;
    gap: 24px;
    align-items: center;
    flex-direction: row;
    box-sizing: border-box;
  }

  .card_content {
    justify-content: flex-start;
    align-items: stretch;
    flex-direction: column;
    flex-grow: 1;
    align-self: stretch;
    gap: 24px;
    padding: calc(24px / 1.7) 0;
    width: 100%;
    display: flex;
    box-sizing: border-box;
  }

  .card_content .title {
    color: #070707;
    font-size: 20px;
    line-height: 1;
    font-weight: 600;
  }

  .card_content .bin {
    display: flex;
    align-items: baseline;
    gap: 0.5ch;
    white-space: nowrap;
    font-size: 16px;
    line-height: 1;
    font-weight: 500;
    color: #828282;
    margin-top: 8px;
  }

  .card_container {
    flex-shrink: 0;
    order: 3;
    width: 224px;
    transform: rotate(-10deg) translateZ(0px);
    margin: calc(24px / 2) calc(24px / 2) calc(24px / 2) auto;
  }

  .universal_card {
    position: relative;
    flex-shrink: 0;
    margin: 0px auto;
    width: 100%;
    max-width: 224px;
    height: 140px;
  }

  .universal_card_wrapper {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: 10px 10px 12px;
    background-color: #111111;
    width: 100%;
    color: #ffffff;
    position: relative;
    border-radius: 12px;
    height: 100%;
  }

  .locker_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    inset: 0px;
    background: rgba(0, 0, 0, 0.7);
    border-radius: inherit;
    padding: 15px;
    font-size: 14px;
    line-height: 142%;
    position: absolute;
    z-index: 20;
    color: #ffffff;
    text-align: center;
  }

  .locker {
    border-width: 0px;
    border-style: solid;
    font: inherit;
    font-synthesis: inherit;
    place-content: inherit;
    place-items: inherit;
    place-self: inherit;
    animation: inherit;
    background: transparent;
    border-block: inherit;
    border: inherit;
    border-radius: inherit;
    columns: inherit;
    gap: inherit;
    column-rule: inherit;
    contain-intrinsic-size: inherit;
    container: inherit;
    flex: inherit;
    flex-flow: inherit;
    inset-block: inherit;
    inset-inline: inherit;
    list-style: inherit;
    margin-block: inherit;
    margin: inherit;
    margin-inline: inherit;
    marker: inherit;
    mask: inherit;
    offset: inherit;
    outline: inherit;
    overflow: inherit;
    overscroll-behavior: inherit;
    padding-block: inherit;
    padding: inherit;
    padding-inline: inherit;
    scroll-margin-block: inherit;
    scroll-margin: inherit;
    scroll-margin-inline: inherit;
    scroll-padding-block: inherit;
    scroll-padding: inherit;
    scroll-padding-inline: inherit;
    text-decoration: inherit;
    text-emphasis: inherit;
    white-space: inherit;
    transition: inherit;
    view-timeline: inherit;
    border-spacing: inherit;
    -webkit-mask-box-image: inherit;
    -webkit-text-stroke: inherit;
    border-color: rgb(230, 230, 230);
  }

  .bg {
  }

  .universal_container {
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
    border-width: 0px;
    border-style: solid;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    flex-grow: 1;
    position: relative;
    z-index: 10;
  }

  .universal_container .universal_container_top {
    white-space: nowrap;
    display: flex;
    align-items: center;
    font-family: orcas;
    gap: 6.5px;
    font-size: 17px;
    line-height: 28px;
    font-weight: 500;
    margin-top: 7px;
    letter-spacing: normal;
  }

  .universal_container_bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 7px;
  }

  .universal_container_bottom span {
    font-weight: 600;
    font-size: 11px;
    line-height: 9px;
    letter-spacing: 4.8px;
    text-transform: uppercase;
    color: #ffffff;
    opacity: 0.89;
    background: -webkit-linear-gradient(
        360deg,
        rgb(210, 209, 213) 16.99%,
        rgb(0, 0, 0) 22.93%,
        rgb(240, 237, 236) 31.63%,
        rgb(116, 115, 114) 54.96%,
        rgb(255, 255, 255) 67.93%,
        rgb(195, 195, 195) 89.93%,
        rgb(0, 0, 0) 121.64%
      )
      text;
    -webkit-text-fill-color: transparent;
  }

  @media screen and (max-width: 768px) {
    & {
      width: 100% !important;
    }

    .card_wrapper {
      flex-direction: column;
      width: 100%;
    }

    .card_container {
      margin: 0 auto;
      /* transform: translateX(3px); */
    }
  }
`;

const PageWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: grid;
  grid-template-columns: max-content auto;

  @media screen and (max-width: 768px) {
    & {
      grid-template-columns: auto auto;
    }
  }
`;

const Main = styled.div`
  background-color: white;
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
  padding: 0px 30px;

  .page_content {
    width: 100%;
    padding: 24px 0;
    margin: 0 auto;
    max-width: 1074px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    max-width: 1074px;
  }

  .page_content .page_top {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .page_content .page_title {
    font-size: 32px;
    font-weight: 800;
    line-height: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    white-space: nowrap;
    font-family: Roboto;
  }

  .page_content .info_cards {
    display: flex;
    gap: 24px;
  }

  .page_content .statistics_cards {
    display: flex;
    gap: 24px;
  }
`;

const Cards = styled.div`
  .cards_top {
    display: flex;
    justify-content: space-between;
    white-space: nowrap;
    flex-wrap: nowrap;
    gap: 16px;
    align-items: center;
    margin-bottom: 16px;
  }

  .right {
    display: flex;
    gap: 16px;
  }

  .buttons {
    display: flex;
    gap: 12px;
  }

  @media screen and (max-width: 768px) {
    .cards_top {
      width: 100%;
      flex-direction: column;
      align-items: flex-start;
    }

    .right {
      width: 100%;
      justify-content: space-between;
    }
  }
`;

const Search = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 0px;
  position: relative;
  width: 100%;
  height: 32px;
  border-bottom: 1px solid #d9d9d9;
  border-radius: 4px 4px 0px 0px;
  border-color: #686c77;

  .search {
    display: flex;
    align-items: center;
    align-self: stretch;
    gap: 12px;
    flex-grow: 1;
    max-width: 100%;
  }

  .search_wrapper {
    flex-grow: 1;
    overflow: hidden;
  }

  .search_container {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    gap: 12px;
    width: 100%;
    position: relative;
  }

  input {
    margin: 0px;
    padding: 0px;
    font-family: inherit;
    font-weight: inherit;
    line-height: inherit;
    color: inherit;
    flex-grow: 1;
    background: transparent;
    overflow: hidden;
    white-space: nowrap;
    min-width: 1%;
    width: 100%;
    text-overflow: ellipsis;
    max-width: 100%;
    font-size: 14px;
    outline: none;
    border: none;
  }

  .select_buttons {
    display: flex;
    gap: 24px;
  }
`;

const SelectBox = styled.div`
  gap: 4px;
  padding: 5px 8px;
  background: rgba(0, 0, 0, 0);
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  height: 32px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  max-width: max-content;
  box-sizing: border-box;
`;

export default CardsPage;

{
  /* <div
className="drop-down-wrapper"
style={{
  scrollbarWidth: "thin",
  scrollbarColor: "transparent transparent",
  borderWidth: "0px",
  borderStyle: "solid",
  borderColor: "rgb(230, 230, 230)",
  boxSizing: "border-box",
  background: "#ffffff",
  padding: "8px 0px",
  borderRadius: "4px",
  position: "absolute",
  width: "245px",
  maxHeight: "350px",
  boxShadow:
    "rgba(0, 0, 0, 0.08) 0px 4px 20px 4px, rgba(0, 0, 0, 0.02) 0px 4px 4px, rgba(0, 0, 0, 0.02) 0px -2px 4px",
  cursor: "pointer",
  userSelect: "none",
  fontSize: "14px",
  lineHeight: "18px",
  fontWeight: 400,
  overflow: "hidden",
  overflowY: "hidden",
}}
>
<div
  style={{
    scrollbarWidth: "thin",
    scrollbarColor: "transparent transparent",
    borderWidth: "0px",
    borderStyle: "solid",
    borderColor: "rgb(230, 230, 230)",
    boxSizing: "border-box",
  }}
>
  <div
    className="drop-down-element-container"
    style={{
      scrollbarWidth: "thin",
      scrollbarColor: "transparent transparent",
      borderWidth: "0px",
      borderStyle: "solid",
      borderColor: "rgb(230, 230, 230)",
      boxSizing: "border-box",
      gap: "20px",
      padding: "8px 12px 8px 16px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      height: "40px",
    }}
  >
    <div
      className="select"
      style={{
        scrollbarWidth: "thin",
        scrollbarColor: "transparent transparent",
        borderWidth: "0px",
        borderStyle: "solid",
        borderColor: "rgb(230, 230, 230)",
        boxSizing: "border-box",
        gap: "16px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
      }}
    >
      <label
        className="Checkbox Checkbox-checked Checkbox-type-default Checkbox-size-md Checkbox-color-primary"
        htmlFor="checkbox_80b8dc0913ba8"
        style={{
          scrollbarWidth: "thin",
          scrollbarColor: "transparent transparent",
          borderWidth: "0px",
          borderStyle: "solid",
          borderColor: "rgb(230, 230, 230)",
          boxSizing: "border-box",
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
          gap: "12px",
          position: "relative",
          cursor: "pointer",
        }}
      >
        <input
          id="checkbox_80b8dc0913ba8"
          className="Checkbox__input"
          type="checkbox"
          style={{
            scrollbarWidth: "thin",
            scrollbarColor: "transparent transparent",
            borderWidth: "0px",
            borderStyle: "solid",
            borderColor: "rgb(230, 230, 230)",
            boxSizing: "border-box",
            margin: "0px",
            padding: "0px",
            fontFamily: "inherit",
            fontSize: "100%",
            fontWeight: "inherit",
            lineHeight: "inherit",
            color: "inherit",
            display: "none",
          }}
        />
        <span
          className="Checkbox__container"
          style={{
            scrollbarWidth: "thin",
            scrollbarColor: "transparent transparent",
            boxSizing: "border-box",
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderStyle: "solid",
            borderColor: "rgba(25,104,229,1)",
            backgroundColor: "rgba(25,104,229,1)",
            flexShrink: 0,
            borderRadius: "2px",
            borderWidth: "2px",
            width: "18px",
            height: "18px",
          }}
        >
          <svg
            className="Checkbox__container__icon"
            width="12"
            fill="none"
            viewBox="0 0 12 10"
            xmlns="http://www.w3.org/2000/svg"
            style={{
              scrollbarWidth: "thin",
              scrollbarColor: "transparent transparent",
              borderWidth: "0px",
              borderStyle: "solid",
              borderColor: "rgb(230, 230, 230)",
              boxSizing: "border-box",
              display: "block",
              verticalAlign: "middle",
              color: "rgba(255,255,255,1)",
              height: "auto",
              width: "12px",
            }}
          >
            <path
              d="M4 9.4L0 5.4L1.4 4L4 6.6L10.6 0L12 1.4L4 9.4Z"
              fill="currentColor"
              style={{
                scrollbarWidth: "thin",
                scrollbarColor:
                  "transparent transparent",
                borderWidth: "0px",
                borderStyle: "solid",
                borderColor: "rgb(230, 230, 230)",
                boxSizing: "border-box",
              }}
            />
          </svg>
        </span>
      </label>
      <p
        style={{
          scrollbarWidth: "thin",
          scrollbarColor: "transparent transparent",
          borderWidth: "0px",
          borderStyle: "solid",
          borderColor: "rgb(230, 230, 230)",
          boxSizing: "border-box",
          margin: "0px",
        }}
      >
        Personal cards
      </p>
    </div>
  </div>
</div>
<div
  style={{
    scrollbarWidth: "thin",
    scrollbarColor: "transparent transparent",
    borderWidth: "0px",
    borderStyle: "solid",
    borderColor: "rgb(230, 230, 230)",
    boxSizing: "border-box",
  }}
>
  <div
    className="drop-down-element-container"
    style={{
      scrollbarWidth: "thin",
      scrollbarColor: "transparent transparent",
      borderWidth: "0px",
      borderStyle: "solid",
      borderColor: "rgb(230, 230, 230)",
      boxSizing: "border-box",
      gap: "20px",
      padding: "8px 12px 8px 16px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      height: "40px",
    }}
  >
    <div
      className="select"
      style={{
        scrollbarWidth: "thin",
        scrollbarColor: "transparent transparent",
        borderWidth: "0px",
        borderStyle: "solid",
        borderColor: "rgb(230, 230, 230)",
        boxSizing: "border-box",
        gap: "16px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
      }}
    >
      <label
        className="Checkbox Checkbox-type-default Checkbox-size-md Checkbox-color-primary"
        htmlFor="checkbox_6815ee3bad54a"
        style={{
          scrollbarWidth: "thin",
          scrollbarColor: "transparent transparent",
          borderWidth: "0px",
          borderStyle: "solid",
          borderColor: "rgb(230, 230, 230)",
          boxSizing: "border-box",
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
          gap: "12px",
          position: "relative",
          cursor: "pointer",
        }}
      >
        <input
          id="checkbox_6815ee3bad54a"
          className="Checkbox__input"
          type="checkbox"
          style={{
            scrollbarWidth: "thin",
            scrollbarColor: "transparent transparent",
            borderWidth: "0px",
            borderStyle: "solid",
            borderColor: "rgb(230, 230, 230)",
            boxSizing: "border-box",
            margin: "0px",
            padding: "0px",
            fontFamily: "inherit",
            fontSize: "100%",
            fontWeight: "inherit",
            lineHeight: "inherit",
            color: "inherit",
            display: "none",
          }}
        />
        <span
          className="Checkbox__container"
          style={{
            scrollbarWidth: "thin",
            scrollbarColor: "transparent transparent",
            boxSizing: "border-box",
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderStyle: "solid",
            backgroundColor: "rgba(25,104,229,0)",
            flexShrink: 0,
            borderRadius: "2px",
            borderWidth: "2px",
            width: "18px",
            height: "18px",
            borderColor: "#111111",
          }}
        />
      </label>
      <p
        style={{
          scrollbarWidth: "thin",
          scrollbarColor: "transparent transparent",
          borderWidth: "0px",
          borderStyle: "solid",
          borderColor: "rgb(230, 230, 230)",
          boxSizing: "border-box",
          margin: "0px",
        }}
      >
        Teams cards (Owner)
      </p>
    </div>
  </div>
</div>
<div
  style={{
    scrollbarWidth: "thin",
    scrollbarColor: "transparent transparent",
    borderWidth: "0px",
    borderStyle: "solid",
    borderColor: "rgb(230, 230, 230)",
    boxSizing: "border-box",
  }}
>
  <div
    className="drop-down-element-container"
    style={{
      scrollbarWidth: "thin",
      scrollbarColor: "transparent transparent",
      borderWidth: "0px",
      borderStyle: "solid",
      borderColor: "rgb(230, 230, 230)",
      boxSizing: "border-box",
      gap: "20px",
      padding: "8px 12px 8px 16px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      height: "40px",
    }}
  >
    <div
      className="select"
      style={{
        scrollbarWidth: "thin",
        scrollbarColor: "transparent transparent",
        borderWidth: "0px",
        borderStyle: "solid",
        borderColor: "rgb(230, 230, 230)",
        boxSizing: "border-box",
        gap: "16px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
      }}
    >
      <label
        className="Checkbox Checkbox-checked Checkbox-type-default Checkbox-size-md Checkbox-color-primary"
        htmlFor="checkbox_7d1f932b65aaa"
        style={{
          scrollbarWidth: "thin",
          scrollbarColor: "transparent transparent",
          borderWidth: "0px",
          borderStyle: "solid",
          borderColor: "rgb(230, 230, 230)",
          boxSizing: "border-box",
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
          gap: "12px",
          position: "relative",
          cursor: "pointer",
        }}
      >
        <input
          id="checkbox_7d1f932b65aaa"
          className="Checkbox__input"
          type="checkbox"
          style={{
            scrollbarWidth: "thin",
            scrollbarColor: "transparent transparent",
            borderWidth: "0px",
            borderStyle: "solid",
            borderColor: "rgb(230, 230, 230)",
            boxSizing: "border-box",
            margin: "0px",
            padding: "0px",
            fontFamily: "inherit",
            fontSize: "100%",
            fontWeight: "inherit",
            lineHeight: "inherit",
            color: "inherit",
            display: "none",
          }}
        />
        <span
          className="Checkbox__container"
          style={{
            scrollbarWidth: "thin",
            scrollbarColor: "transparent transparent",
            boxSizing: "border-box",
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderStyle: "solid",
            borderColor: "rgba(25,104,229,1)",
            backgroundColor: "rgba(25,104,229,1)",
            flexShrink: 0,
            borderRadius: "2px",
            borderWidth: "2px",
            width: "18px",
            height: "18px",
          }}
        >
          <svg
            className="Checkbox__container__icon"
            width="12"
            fill="none"
            viewBox="0 0 12 10"
            xmlns="http://www.w3.org/2000/svg"
            style={{
              scrollbarWidth: "thin",
              scrollbarColor: "transparent transparent",
              borderWidth: "0px",
              borderStyle: "solid",
              borderColor: "rgb(230, 230, 230)",
              boxSizing: "border-box",
              display: "block",
              verticalAlign: "middle",
              color: "rgba(255,255,255,1)",
              height: "auto",
              width: "12px",
            }}
          >
            <path
              d="M4 9.4L0 5.4L1.4 4L4 6.6L10.6 0L12 1.4L4 9.4Z"
              fill="currentColor"
              style={{
                scrollbarWidth: "thin",
                scrollbarColor:
                  "transparent transparent",
                borderWidth: "0px",
                borderStyle: "solid",
                borderColor: "rgb(230, 230, 230)",
                boxSizing: "border-box",
              }}
            />
          </svg>
        </span>
      </label>
      <p
        style={{
          scrollbarWidth: "thin",
          scrollbarColor: "transparent transparent",
          borderWidth: "0px",
          borderStyle: "solid",
          borderColor: "rgb(230, 230, 230)",
          boxSizing: "border-box",
          margin: "0px",
        }}
      >
        Teams cards (Buyer)
      </p>
    </div>
  </div>
</div>
<div
  style={{
    scrollbarWidth: "thin",
    scrollbarColor: "transparent transparent",
    borderWidth: "0px",
    borderStyle: "solid",
    borderColor: "rgb(230, 230, 230)",
    boxSizing: "border-box",
  }}
>
  <div
    className="drop-down-element-container"
    style={{
      scrollbarWidth: "thin",
      scrollbarColor: "transparent transparent",
      borderWidth: "0px",
      borderStyle: "solid",
      borderColor: "rgb(230, 230, 230)",
      boxSizing: "border-box",
      gap: "20px",
      padding: "8px 12px 8px 16px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      height: "40px",
    }}
  >
    <div
      className="select"
      style={{
        scrollbarWidth: "thin",
        scrollbarColor: "transparent transparent",
        borderWidth: "0px",
        borderStyle: "solid",
        borderColor: "rgb(230, 230, 230)",
        boxSizing: "border-box",
        gap: "16px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
      }}
    >
      <label
        className="Checkbox Checkbox-type-default Checkbox-size-md Checkbox-color-primary"
        htmlFor="checkbox_35771840fadf9"
        style={{
          scrollbarWidth: "thin",
          scrollbarColor: "transparent transparent",
          borderWidth: "0px",
          borderStyle: "solid",
          borderColor: "rgb(230, 230, 230)",
          boxSizing: "border-box",
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
          gap: "12px",
          position: "relative",
          cursor: "pointer",
        }}
      >
        <input
          id="checkbox_35771840fadf9"
          className="Checkbox__input"
          type="checkbox"
          style={{
            scrollbarWidth: "thin",
            scrollbarColor: "transparent transparent",
            borderWidth: "0px",
            borderStyle: "solid",
            borderColor: "rgb(230, 230, 230)",
            boxSizing: "border-box",
            margin: "0px",
            padding: "0px",
            fontFamily: "inherit",
            fontSize: "100%",
            fontWeight: "inherit",
            lineHeight: "inherit",
            color: "inherit",
            display: "none",
          }}
        />
        <span
          className="Checkbox__container"
          style={{
            scrollbarWidth: "thin",
            scrollbarColor: "transparent transparent",
            boxSizing: "border-box",
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderStyle: "solid",
            backgroundColor: "rgba(25,104,229,0)",
            flexShrink: 0,
            borderRadius: "2px",
            borderWidth: "2px",
            width: "18px",
            height: "18px",
            borderColor: "#111111",
          }}
        />
      </label>
      <p
        style={{
          scrollbarWidth: "thin",
          scrollbarColor: "transparent transparent",
          borderWidth: "0px",
          borderStyle: "solid",
          borderColor: "rgb(230, 230, 230)",
          boxSizing: "border-box",
          margin: "0px",
        }}
      >
        All Team cards
      </p>
    </div>
    <svg
      className="transition-transform"
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        scrollbarWidth: "thin",
        scrollbarColor: "transparent transparent",
        borderWidth: "0px",
        borderStyle: "solid",
        borderColor: "rgb(230, 230, 230)",
        boxSizing: "border-box",
        display: "block",
        verticalAlign: "middle",
        transitionProperty: "transform",
        transitionTimingFunction:
          "cubic-bezier(0.4, 0, 0.2, 1)",
        transitionDuration: "0.15s",
        width: "20px",
        height: "20px",
      }}
    >
      <path
        d="M6 9L12 15L18 9"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        style={{
          scrollbarWidth: "thin",
          scrollbarColor: "transparent transparent",
          borderWidth: "0px",
          borderStyle: "solid",
          borderColor: "rgb(230, 230, 230)",
          boxSizing: "border-box",
        }}
      />
    </svg>
  </div>
</div>
</div> */
}
