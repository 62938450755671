import { useEffect, useRef, useState } from "react";
import Modal from "@mui/material/Modal";
import { styled } from "styled-components";
import Toast from "../hooks/Toast";

const SupportModal = ({ open }) => {
  const { contactSupport, setContactSupport } = open;

  // toast
  const [openToast, setOpenToast] = useState(false);
  const [toastMessage, setToastMessage] = useState(" ");
  const [toastType, setToastType] = useState(undefined);

  return (
    <>
      {openToast && (
        <Toast
          open={{ openToast, setOpenToast }}
          message={toastMessage}
          type={toastType}
        />
      )}

      <Modal
        open={contactSupport}
        onClose={() => setContactSupport(false)}
        style={{
          display: "flex",
          placeContent: "center",
          zIndex: "10001",
          padding: "12px",
        }}
      >
        <ModalStandard className="scrollbar-hide">
          <div className="modal_top">
            <div className="modal_top_left">
              {/* <img
                data-v-04385784=""
                class="logoImage"
                src="https://epn.net/storage/2024/02/02/6654840424c66d6d740b54e36cbe5410845e4647.png"
                loading="lazy"
                alt=""
              /> */}

              <p>Suggest improvements</p>
            </div>
            {/* <p>Add signal balance</p> */}

            <svg
              width="15"
              height="14"
              viewBox="0 0 15 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={() => setContactSupport(!contactSupport)}
              style={{ cursor: "pointer" }}
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M11.8647 0.366365C12.3532 -0.122122 13.1451 -0.122122 13.6336 0.366365C14.1221 0.854853 14.1221 1.64685 13.6336 2.13533L8.88929 6.87968L13.8743 11.8647C14.3628 12.3532 14.3628 13.1451 13.8743 13.6336C13.3858 14.1221 12.5938 14.1221 12.1053 13.6336L7.12032 8.64864L2.13533 13.6336C1.64685 14.1221 0.854853 14.1221 0.366366 13.6336C-0.122122 13.1451 -0.122122 12.3532 0.366366 11.8647L5.35136 6.87968L0.607014 2.13533C0.118527 1.64685 0.118527 0.854853 0.607014 0.366365C1.0955 -0.122122 1.8875 -0.122122 2.37598 0.366365L7.12032 5.11071L11.8647 0.366365Z"
                fill="#858DAD"
              />
            </svg>
          </div>

          <div className="modal_content">
            <div className="top">
              <p className="text">
                Describe what you would like to improve, change or simplify.
              </p>
              <p className="text">
                Or contact our Product manager via Telegram
              </p>

              <TextArea
                className="textarea"
                maxLength="200"
                placeholder="Enter the full text of your appeal"
              ></TextArea>

              <div className="checkbox_wrapper">
                <input type="checkbox" />
                <p>I agree to get contacted by support</p>
              </div>
            </div>

            <div className="bottom">
              <div className="btns">
                <button className="dark_button disabled">Send</button>
                <button className="blue_button_outline">Contact me</button>
              </div>
            </div>
          </div>
        </ModalStandard>
      </Modal>
    </>
  );
};

const TextArea = styled.textarea`
  font-family: inherit;
  color: inherit;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #d9d9d9;
  width: 100%;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  font-family: Roboto;
  height: 180px;
  box-sizing: border-box;
  margin-top: 12px;
`;

const ModalStandard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  /* justify-content: space-between; */
  align-items: flex-start;
  border-radius: 16px;
  width: 660px;
  height: 480px;
  background-color: #fff;
  opacity: 1;
  padding: 24px;
  box-sizing: border-box;
  overflow: hidden;
  outline: none;
  place-self: center;
  position: relative;

  .bottom {
    margin-top: auto;
    position: absolute;
    bottom: 30px;
  }

  .btns {
    display: flex;

    gap: 12px;
  }

  .modal_top {
    color: white;
    font-size: 16px;
    font-weight: 600;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .modal_top_left {
    display: flex;
    align-items: center;
  }

  .modal_top_left img {
    height: 30px;
    width: auto;
    margin-right: 10px;
  }

  .modal_top_left p {
    color: #333742;
    font-size: 16px;
    font-family: Roboto;
  }

  .modal_content {
    width: 100%;
    box-sizing: border-box;
  }

  .modal_content .top {
    width: 100%;
    box-sizing: border-box;
  }

  .modal_content .top .text {
    color: #333742;
    font-family: Roboto;
    line-height: 22px;
    font-size: 18px;
  }

  .checkbox_wrapper {
    display: flex;
    gap: 8px;
    align-items: center;
    margin-top: 16px;
  }

  .checkbox_wrapper p {
    font-size: 16px;
    line-height: 133%;
    font-weight: 500;
  }

  .slide {
  }

  .slider {
  }

  .slide img {
  }
`;

export default SupportModal;
