import { useContext, useState } from "react";
import styled from "styled-components";
import { context } from "../../context/context";
import Toast from "../../hooks/Toast";

const DepositAddressBox = ({ address, amount }) => {
  const [qrShown, setQrShown] = useState(false);
  const { currentPrices } = useContext(context);
  // const amount = 1000;

  // toast
  const [openToast, setOpenToast] = useState(false);
  const [toastMessage, setToastMessage] = useState(" ");
  const [toastType, setToastType] = useState(undefined);

  function convertValue() {
    return fiatToCrypto(currentPrices[address.symbol], Number(amount));
  }

  function fiatToCrypto(worth, price) {
    return price / worth;
  }

  function copyValue(value, type) {
    navigator.clipboard.writeText(value);

    setToastType("success");
    setToastMessage("Copied " + type);
    setOpenToast(true);
  }

  return (
    <Wrapper>
      {openToast && (
        <Toast
          open={{ openToast, setOpenToast }}
          message={toastMessage}
          type={toastType}
        />
      )}

      <div className="deposit_box">
        <div className="deposit_box_top">
          <div className="deposit_asset">
            <img src={`./asseticons/${address?.symbol}.svg`} />
            <p>{address?.name}</p>
          </div>

          {address?.QR && (
            <div
              className="deposit_qr_button"
              onClick={() => setQrShown(!qrShown)}
            >
              <img src="./assets/icons/misc/qr.svg" />
              <p>Show QR code for payment</p>
              <img
                src="./assets/icons/misc/tiny-dropdown.svg"
                className={
                  qrShown ? "tiny_dropdown translate" : "tiny_dropdown"
                }
              />
            </div>
          )}
        </div>

        <div className="deposit_inputs">
          <div className="box address_input">
            <p>{address?.address}</p>
            <img
              src="./assets/icons/misc/copy.svg"
              onClick={() => copyValue(address?.address, "address")}
            />
          </div>

          <div className="box amount_input">
            <p>{convertValue(address.symbol, amount)}</p>
            <img
              src="./assets/icons/misc/copy.svg"
              onClick={() =>
                copyValue(convertValue(address.symbol, amount), "amount")
              }
            />
          </div>
        </div>

        {/* qr */}
        {qrShown && (
          <div className="qr_container">
            <div className="qr">
              <img src={address?.QR} />
            </div>
            <p className="qr_caption">
              QR code for payment in the amount
              <span>
                {convertValue(address.symbol, amount)} {address.symbol}
              </span>
            </p>
          </div>
        )}
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 20px;
  max-width: 720px;

  .deposit_box {
    box-sizing: border-box;
    background: #f7f9fb;
    padding: 24px;
    margin-bottom: 24px;
    border-radius: 16px;
    width: 100%;
  }

  .deposit_box_top {
    display: flex;
    justify-content: space-between;
  }

  .address_input {
    display: flex;
  }

  .deposit_asset {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
  }

  .deposit_qr_button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;
    color: #1968e5;
    cursor: pointer;
    font-size: 12px;
    line-height: 14px;
    font-weight: 400;
  }

  .tiny_dropdown {
    background-color: rgba(17, 17, 17, 0.05);
    padding: 10px;
    box-sizing: border-box;
    border-radius: 100%;
    cursor: pointer;
    transition: transform 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  .tiny_dropdown.translate {
    transform: rotate(180deg);
  }

  .tiny_dropdown:hover {
    background-color: rgba(17, 17, 17, 0.1);
  }

  .deposit_inputs {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    margin-top: 16px;
  }

  .box {
    height: 44px;
    padding: 10px 16px;
    border-radius: 12px;
    background: #fff !important;
    border: 1px solid #f3f3f3;
    /* max-width: max-content; */
    box-sizing: border-box;
    align-items: center;
    display: flex;
    cursor: pointer;
  }

  .box p {
    background: transparent;
    min-width: 1%;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    white-space: nowrap;
    display: flex;
    justify-content: space-between;
  }

  .box img {
    height: 24px;
    width: 24px;
    margin-left: 24px;
    cursor: pointer;
    /* padding: 4px; */
    box-sizing: border-box;
    transition: transform 300ms cubic-bezier(0.075, 0.82, 0.165, 1);
    /* border-radius: 100%; */
  }

  .box img:hover {
    transform: scale(0.9);
  }

  .box.address_input {
    flex-grow: 1;
  }

  .qr_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
  }

  .qr img {
    max-width: 200px;
    height: auto;
  }

  .qr_caption {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 4px;
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    margin-top: 12px;
    color: #686c77;
  }

  .qr_caption span {
    font-weight: 500;
    color: #111111;
  }

  @media screen and (max-width: 1200px) {
    .box p {
      max-width: 120px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

export default DepositAddressBox;
