import styled from "styled-components";
import "../../../css/settings/verification.css";

const Verification = () => {
  return (
    <div style={{ display: "flex", gap: "16px" }}>
      <Box>
        <h1 className="box_title">Residence</h1>

        <div className="section form">
          <TransparentDropdown>
            <h3 className="title">Citzenship</h3>
            <select className="input_wrapper">
              <option defaultChecked disabled>
                <p className="option">Choose</p>
              </option>
              <option>
                <p className="option">Afghanistan</p>
              </option>
              <option>
                <p className="option">Albania</p>
              </option>

              <option>
                <p className="option">Algeria</p>
              </option>
            </select>
          </TransparentDropdown>

          <button className="dark_button disabled">Choose</button>
        </div>
      </Box>

      <SmallBox></SmallBox>

      {/* <div className="Residence"> */}

      {/* <div className="residencebox1">
          <h1>Residence</h1>
          <h2>Citizenship</h2>
          <div className="choose-box">
            <select name="choose" id="choose" className="choose">
              <option value="country">Belize</option>
            </select>
          </div>
          <button className="btn">Continue</button>
        </div> */}
      {/* <div className="residencebox2">
          <div className="line2">
            <div className="cover-img">
              <img
                src="./assets/icons/settings/tick.svg"
                alt="tick"
                className="tick"
              />
            </div>
            <h1>Residence</h1>
          </div>
          <div className="line2 margin19">
            <div className="cover-img">
              <img
                src="./assets/icons/settings/tick.svg"
                alt="tick"
                className="tick grey"
              />
            </div>
            <h1 className="gray">Personal data</h1>
          </div>
          <div className="group margin19">
            <div className="line2">
              <div className="cover-img">
                <img
                  src="./assets/icons/settings/tick.svg"
                  alt="tick"
                  className="tick grey"
                />
              </div>
              <h1 className="gray">Upload documents</h1>
            </div>
            <div className="small-exes">
              <div className="line2 moc">
                <img
                  src="./assets/icons/settings/ex.svg"
                  alt="Ex"
                  className="Ex"
                />{" "}
                <p className="gray">State Passport</p>
              </div>
              <div className="line2 moc">
                <img
                  src="./assets/icons/settings/ex.svg"
                  alt="Ex"
                  className="Ex"
                />{" "}
                <p className="gray">Address Confirmation</p>
              </div>
              <div className="line2 moc">
                <img
                  src="./assets/icons/settings/ex.svg"
                  alt="Ex"
                  className="Ex"
                />{" "}
                <p className="gray">Selfie with document</p>
              </div>
            </div>
          </div>
        </div> */}
      {/* </div> */}
    </div>
  );
};

const Box = styled.div`
  padding: 24px;
  width: 70%;
  border: 1px solid #e1e8f1;
  border-radius: 20px;
  margin-bottom: 10px;
  /* height: 155px; */
  /* box-sizing: border-box; */

  input[type="checkbox"] {
    cursor: pointer;
    accent-color: #1968e5;
    border: 1px solid #1968e5;
    border-radius: 4px;
  }

  h1.box_title {
    font-size: 20px;
    font-weight: 700;
    line-height: 1;
    color: #111111;
  }

  .check_section {
  }

  .check_section p {
    font-size: 14px;
    line-height: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    white-space: nowrap;
  }

  .user_wrapper {
    display: flex;
    gap: 16px;
    align-items: center;
    margin-top: 24px;
  }

  .user_info {
    display: grid;
    gap: 4px;
  }

  .user_info .name {
    color: #111111;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    white-space: nowrap;
  }

  .user_info .register_info {
    color: #686c77;
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    white-space: nowrap;
  }

  .flex_center {
    display: flex;
    align-items: center;
  }

  .flex_divide {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .form {
    display: flex;
    flex-direction: column;
    max-width: 70%;
  }

  .flex-8 {
    gap: 8px;
  }

  .flex-12 {
    gap: 12px;
  }

  .flex-16 {
    gap: 16px;
  }

  .flex-24 {
    gap: 24px;
  }

  .flex-32 {
    gap: 32px;
  }

  .section {
    margin-top: 24px;
  }

  .date_items {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 12px;
  }

  .date_item p {
    font-size: 14px;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    white-space: nowrap;
  }

  .light_select {
    box-sizing: border-box;
    display: flex;
    outline: none;
    gap: 8px;
    background-color: rgba(17, 17, 17, 0.05);
    border-radius: 8px;
    padding: 8px 12px;
    font-size: 14px;
    border: none;
    align-items: center;
    line-height: 1;
    padding-right: 10px;
    font-weight: 500;
    height: 36px;
  }

  @media screen and (max-width: 768px) {
    & {
      width: 100% !important;
      box-sizing: border-box;
    }

    .form {
      width: 100%;
      display: flex;
      flex-direction: column;
      max-width: unset;
      box-sizing: border-box;
    }
  }
`;

const SmallBox = styled.div`
  padding: 24px;
  width: 30%;
  border: 1px solid #e1e8f1;
  border-radius: 20px;
  margin-bottom: 10px;
  /* height: 155px; */
  box-sizing: border-box;
`;

const TransparentDropdown = styled.div`
  box-sizing: border-box;
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: flex-start; */
  flex-direction: column;
  gap: 24px;
  margin-bottom: 20px;
  max-width: 720px;

  h3.title {
    font-size: 16px;
    line-height: 1;
    font-weight: 500;
    margin-bottom: 12px;
  }

  .input_wrapper {
    display: flex;
    box-sizing: border-box;
    align-items: center;
    position: relative;
    width: 100%;
    background: transparent;
    padding: 10px 12px;
    border-radius: 8px;
    border-width: 1px;
    border-style: solid;
    border-color: #f3f3f3;
    height: 56px;
    font-size: 16px;
    font-weight: 500 !important;
  }

  .input_block {
    box-sizing: border-box;
    flex-grow: 1;
    overflow: hidden;
    max-width: 100%;
    position: relative;
  }

  label {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
    transition: transform 0.1s ease 0s;
    margin-top: 4px;
    line-height: 1;
    color: #686c77;
    font-size: 14px;
    margin-bottom: 2px;
  }

  .max_button {
    font-size: 12px;
    font-weight: 600;
    background: none;
    outline: none;
    border: none;
    cursor: pointer;
  }

  input {
    box-sizing: border-box;
    margin: 0px;
    padding: 0px;
    font-family: inherit;
    font-size: 100%;
    font-weight: inherit;
    line-height: inherit;
    color: inherit;
    flex-grow: 1;
    background: transparent;
    overflow: hidden;
    white-space: nowrap;
    min-width: 1%;
    width: 100%;
    text-overflow: ellipsis;
    max-width: 100%;
    outline: none;
    border: none;
    transition: 0.3s ease-in-out;
  }

  /* input:focus ~ label {
    color: red;
    transition-duration: 0.2s;
    transform: translate(0, -1.5em) scale(0.9, 0.9);
  } */

  .caption {
    display: flex;
    gap: 4px;
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
  }

  .caption span {
    color: rgba(21, 128, 61, 1);
  }

  p.option {
    color: #333742;
    font-size: 16px;
    font-weight: 600 !important;
    font-family: Roboto;
  }
`;

export default Verification;
