import { useEffect, useRef, useState } from "react";
import Modal from "@mui/material/Modal";
import { styled } from "styled-components";
import Toast from "../hooks/Toast";
import CircularLoader from "../components/styled/loaders/CircularLoader";
import { doc, setDoc, updateDoc } from "firebase/firestore";
import { makeid } from "../utils/utils";
import { db } from "../firebase/firebase";

const AddProductModal = ({ open }) => {
  const { addProduct, setAddProduct } = open;

  // toast
  const [openToast, setOpenToast] = useState(false);
  const [toastMessage, setToastMessage] = useState(" ");
  const [toastType, setToastType] = useState(undefined);

  //   type
  const [type, setType] = useState(undefined);
  function handleType(e) {
    const { value } = e.target;

    if (value) {
      setType(value);
    } else {
      setType("");
    }
  }

  //   label
  const [label, setLabel] = useState(undefined);
  function handleLabel(e) {
    const { value } = e.target;

    if (value) {
      setLabel(value);
    } else {
      setLabel("");
    }
  }

  //   card number
  const [cardNumber, setCardNumber] = useState(undefined);
  function handleCardNumber(e) {
    const { value } = e.target;

    if (value) {
      setCardNumber(value);
    } else {
      setCardNumber("");
    }
  }

  //   expiry
  const [expiry, setExpiry] = useState(undefined);
  function handleExpiry(e) {
    const { value } = e.target;

    if (value) {
      setExpiry(value);
    } else {
      setExpiry("");
    }
  }

  //   cvc
  const [cvc, setCVC] = useState(undefined);
  function handleCVC(e) {
    const { value } = e.target;

    if (value) {
      setCVC(value);
    } else {
      setCVC("");
    }
  }

  //   price
  const [price, setPrice] = useState(undefined);
  function handlePrice(e) {
    const { value } = e.target;

    if (Number(value)) {
      setPrice(Number(value));
    } else {
      setPrice("");
    }
  }

  //   minimum
  const [minimum, setMimimum] = useState(undefined);
  function handleMinimum(e) {
    const { value } = e.target;

    if (Number(value)) {
      setMimimum(Number(value));
    } else {
      setMimimum("");
    }
  }

  //   commission
  const [commission, setCommission] = useState(undefined);
  function handleCommission(e) {
    const { value } = e.target;

    if (Number(value)) {
      setCommission(Number(value));
    } else {
      setCommission("");
    }
  }

  const [isAdding, setIsAdding] = useState(false);

  async function addProductDB() {
    // console.log(type, label, cardNumber, cvc, expiry);
    // return;
    setIsAdding(true);

    const randomOne = Math.floor(Math.random(100, 999) * 1000 + 1);

    const ref = makeid(4) + randomOne;

    await updateDoc(doc(db, "admin", "products"), {
      [ref]: {
        type,
        label,
        cardNumber,
        cvc,
        expiry,
        ref,
        price,
        minimum,
        commission,
      },
    })
      .then(() => {
        setIsAdding(false);
        setToastType("success");
        setToastMessage("Product added successfully");
        setOpenToast(true);
        setTimeout(() => {
          setAddProduct(false);
        }, 400);
      })
      .catch((error) => {
        setIsAdding(false);
        setToastType("error");
        setToastMessage("Failed to add. Please try again later");
        setOpenToast(true);
        console.log("error", error);
      });
  }

  return (
    <>
      {openToast && (
        <Toast
          open={{ openToast, setOpenToast }}
          message={toastMessage}
          type={toastType}
        />
      )}

      <Modal
        open={addProduct}
        onClose={() => setAddProduct(false)}
        style={{
          display: "flex",
          placeContent: "center",
          zIndex: "10001",
          padding: "12px",
        }}
      >
        <ModalStandard className="scrollbar-hide">
          <div className="modal_top">
            <div className="modal_top_left">
              {/* <img
                data-v-04385784=""
                class="logoImage"
                src="https://epn.net/storage/2024/02/02/6654840424c66d6d740b54e36cbe5410845e4647.png"
                loading="lazy"
                alt=""
              /> */}

              <p>Add a product</p>
            </div>
            {/* <p>Add signal balance</p> */}

            <svg
              width="15"
              height="14"
              viewBox="0 0 15 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={() => setAddProduct(!addProduct)}
              style={{ cursor: "pointer" }}
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M11.8647 0.366365C12.3532 -0.122122 13.1451 -0.122122 13.6336 0.366365C14.1221 0.854853 14.1221 1.64685 13.6336 2.13533L8.88929 6.87968L13.8743 11.8647C14.3628 12.3532 14.3628 13.1451 13.8743 13.6336C13.3858 14.1221 12.5938 14.1221 12.1053 13.6336L7.12032 8.64864L2.13533 13.6336C1.64685 14.1221 0.854853 14.1221 0.366366 13.6336C-0.122122 13.1451 -0.122122 12.3532 0.366366 11.8647L5.35136 6.87968L0.607014 2.13533C0.118527 1.64685 0.118527 0.854853 0.607014 0.366365C1.0955 -0.122122 1.8875 -0.122122 2.37598 0.366365L7.12032 5.11071L11.8647 0.366365Z"
                fill="#858DAD"
              />
            </svg>
          </div>

          <div className="modal_content">
            <div className="top">
              <TextBox>
                <div className="input_wrapper">
                  <div className="input_block">
                    <label htmlFor="value">Type</label>
                    <input
                      placeholder="Visa, MasterCard"
                      onChange={handleType}
                    />
                  </div>
                </div>
              </TextBox>

              <TextBox>
                <div className="input_wrapper">
                  <div className="input_block">
                    <label htmlFor="value">Label</label>
                    <input
                      placeholder="Universal, Advertising"
                      onChange={handleLabel}
                    />
                  </div>
                </div>
              </TextBox>

              <TextBox>
                <div className="input_wrapper">
                  <div className="input_block">
                    <label htmlFor="value">Card number</label>
                    <input
                      placeholder="5536 7649 0231 6543"
                      onChange={handleCardNumber}
                    />
                  </div>
                </div>
              </TextBox>

              <TextBox>
                <div className="input_wrapper">
                  <div className="input_block">
                    <label htmlFor="value">CVC</label>
                    <input placeholder="553" onChange={handleCVC} />
                  </div>
                </div>
              </TextBox>

              <TextBox>
                <div className="input_wrapper">
                  <div className="input_block">
                    <label htmlFor="value">Expiry</label>
                    <input placeholder="02/24" onChange={handleExpiry} />
                  </div>
                </div>
              </TextBox>

              <TextBox>
                <div className="input_wrapper">
                  <div className="input_block">
                    <label htmlFor="value">Price $</label>
                    <input
                      placeholder="10"
                      onChange={handlePrice}
                      type="number"
                    />
                  </div>
                </div>
              </TextBox>

              <TextBox>
                <div className="input_wrapper">
                  <div className="input_block">
                    <label htmlFor="value">Mimimum top-up $</label>
                    <input
                      placeholder="100"
                      onChange={handleMinimum}
                      type="number"
                    />
                  </div>
                </div>
              </TextBox>

              <TextBox>
                <div className="input_wrapper">
                  <div className="input_block">
                    <label htmlFor="value">Commission %</label>
                    <input
                      placeholder="5"
                      onChange={handleCommission}
                      type="number"
                    />
                  </div>
                </div>
              </TextBox>
            </div>

            <div className="bottom">
              <div className="btns">
                <button
                  onClick={addProductDB}
                  disabled={
                    isAdding ||
                    !type ||
                    !label ||
                    !cvc ||
                    !cardNumber ||
                    !expiry
                  }
                  className={
                    isAdding ||
                    !type ||
                    !label ||
                    !cvc ||
                    !cardNumber ||
                    !expiry
                      ? "dark_button full disabled"
                      : "dark_button full "
                  }
                >
                  {isAdding ? (
                    <CircularLoader bg="#cccccc" size="16" color="#ffffff" />
                  ) : (
                    "Add"
                  )}
                </button>
              </div>
            </div>
          </div>
        </ModalStandard>
      </Modal>
    </>
  );
};

const TextArea = styled.textarea`
  font-family: inherit;
  color: inherit;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #d9d9d9;
  width: 100%;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  font-family: Roboto;
  height: 180px;
  box-sizing: border-box;
  margin-top: 12px;
`;

const TextBox = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 24px;
  max-width: 720px;
  margin-top: 16px;

  h3.title {
    margin: 0px;
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
  }

  .input_wrapper {
    display: flex;
    box-sizing: border-box;
    align-items: center;
    position: relative;
    width: 100%;
    background: transparent;
    padding: 10px 12px;
    border-radius: 8px;
    border-width: 1px;
    border-style: solid;
    border-color: #f3f3f3;
    height: 56px;
    width: 100% !important;
  }

  .input_block {
    box-sizing: border-box;
    flex-grow: 1;
    overflow: hidden;
    max-width: 100%;
    position: relative;
  }

  label {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
    transition: transform 0.1s ease 0s;
    margin-top: 4px;
    line-height: 1;
    color: #686c77;
    font-size: 14px;
    margin-bottom: 2px;
  }

  .max_button {
    font-size: 12px;
    font-weight: 600;
    background: none;
    outline: none;
    border: none;
    cursor: pointer;
  }

  input {
    box-sizing: border-box;
    margin: 0px;
    padding: 0px;
    font-family: inherit;
    font-size: 100%;
    font-weight: inherit;
    line-height: inherit;
    color: inherit;
    flex-grow: 1;
    background: transparent;
    overflow: hidden;
    white-space: nowrap;
    min-width: 1%;
    width: 100%;
    text-overflow: ellipsis;
    max-width: 100%;
    outline: none;
    border: none;
    transition: 0.3s ease-in-out;
  }

  /* input:focus ~ label {
    color: red;
    transition-duration: 0.2s;
    transform: translate(0, -1.5em) scale(0.9, 0.9);
  } */

  .caption {
    display: flex;
    gap: 4px;
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
  }

  .caption span {
    color: rgba(21, 128, 61, 1);
  }

  /* @media screen and (max-width: 768px) {
    & {
      width: 100% !important;
      max-width: unset;
    }
  } */
`;

const ModalStandard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  /* justify-content: space-between; */
  align-items: flex-start;
  border-radius: 16px;
  width: 660px;
  min-height: 480px;
  height: 80%;
  background-color: #fff;
  opacity: 1;
  padding: 24px;
  box-sizing: border-box;
  overflow: hidden;
  outline: none;
  place-self: center;
  position: relative;

  .bottom {
    margin-top: auto;
    position: absolute;
    bottom: 30px;
  }

  .btns {
    display: flex;

    gap: 12px;
  }

  .modal_top {
    color: white;
    font-size: 16px;
    font-weight: 600;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .modal_top_left {
    display: flex;
    align-items: center;
  }

  .modal_top_left img {
    height: 30px;
    width: auto;
    margin-right: 10px;
  }

  .modal_top_left p {
    color: #333742;
    font-size: 16px;
    font-family: Roboto;
  }

  .modal_content {
    width: 100%;
    box-sizing: border-box;
  }

  .modal_content .top {
    width: 100%;
    box-sizing: border-box;
  }

  .modal_content .top .text {
    color: #333742;
    font-family: Roboto;
    line-height: 22px;
    font-size: 18px;
  }

  .checkbox_wrapper {
    display: flex;
    gap: 8px;
    align-items: center;
    margin-top: 16px;
  }

  .checkbox_wrapper p {
    font-size: 16px;
    line-height: 133%;
    font-weight: 500;
  }

  .slide {
  }

  .slider {
  }

  .slide img {
  }
`;

export default AddProductModal;
