import styled from "styled-components";
import Sidebar from "../components/general/Sidebar";
import Header from "../components/general/Header";
import { useState } from "react";
import CreateTicket from "../components/CreateTicket";

const SupportPage = () => {
  const [activeTab, setActiveTab] = useState("Support");

  const links = ["FAQ", "API Help", "Documents", "Refunds search"];
  const [sidebarHide, setSidebarHide] = useState(true);
  const [sidebarCollapse, setSidebarCollapse] = useState(false);

  const [createTicket, setCreateTicket] = useState(false);

  return (
    <PageContainer>
      <Header
        collapse={{ sidebarCollapse, setSidebarCollapse }}
        hide={{ sidebarHide, setSidebarHide }}
      />
      <PageWrapper>
        <Sidebar
          tab={{ activeTab, setActiveTab }}
          hide={{ sidebarHide, setSidebarHide }}
          collapse={{ sidebarCollapse, setSidebarCollapse }}
        />
        <Main>
          {!createTicket && (
            <div className="page_content">
              <p className="page_title">Support</p>

              <div className="section cards">
                <TechnicalSupportCard>
                  <div className="box_wrapper">
                    <p className="subtitle">
                      If you have any difficulties while working with the
                      service, contact us
                    </p>
                    <div className="buttons">
                      <button
                        className="ticket"
                        onClick={() => setCreateTicket(!createTicket)}
                      >
                        <p>Create a ticket</p>
                        <div className="label">Quick response</div>
                      </button>
                      <button className="button tg_hyperlink">
                        <img src="./assets/icons/misc/telegram.svg" />
                        <p>Telegram</p>
                      </button>
                    </div>
                  </div>
                </TechnicalSupportCard>

                <AdditionalInformationCard>
                  <div className="box_wrapper">
                    <p className="subtitle">
                      If you have any difficulties while working with the
                      service, contact us
                    </p>

                    <div className="links">
                      {links.map((link) => (
                        <p className="link">{link}</p>
                      ))}
                    </div>
                  </div>
                </AdditionalInformationCard>
              </div>

              <div className="section">
                <h2 className="section_title">Tickets</h2>

                <Tickets>
                  <h4 className="title">As long as it's empty</h4>
                  <p className="subtitle">
                    If you have any questions, we are always ready to answer
                    them.
                  </p>
                  <button
                    className="btn"
                    onClick={() => setCreateTicket(!createTicket)}
                  >
                    Create a ticket
                  </button>
                </Tickets>
              </div>
            </div>
          )}

          {createTicket && (
            <div className="page_content">
              <CreateTicket ticket={{ createTicket, setCreateTicket }} />
            </div>
          )}
        </Main>
      </PageWrapper>
    </PageContainer>
  );
};

const PageContainer = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
`;

const PageWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: grid;
  grid-template-columns: max-content auto;

  @media screen and (max-width: 768px) {
    & {
      grid-template-columns: auto auto;
    }
  }
`;

const Main = styled.div`
  background-color: white;
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
  padding: 0px 30px;

  .page_content {
    width: 100%;
    padding: 24px 0;
    margin: 0 auto;
    max-width: 1074px;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .page_content .page_title {
    font-size: 32px;
    font-weight: 800;
    line-height: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    white-space: nowrap;
    font-family: Roboto;
  }

  .page_content .info_cards {
    display: flex;
    gap: 24px;
  }

  .page_content .statistics_cards {
    display: flex;
    gap: 24px;
  }

  .section {
    margin-bottom: 40px;
  }

  h2.section_title {
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
    border-width: 0px;
    border-style: solid;
    border-color: rgb(230, 230, 230);
    box-sizing: border-box;
    margin: 0px;
    font-size: 24px;
    line-height: 28px;
    font-weight: 500;
    margin-bottom: 20px;
  }

  .section.cards {
    display: flex;
    gap: 24px;
  }
  @media screen and (max-width: 768px) {
    .section.cards {
      flex-direction: column;
    }
  }
`;

const TechnicalSupportCard = styled.div`
  padding: 24px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  border: none;
  background: #f7f9fb;

  .box_wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  .box_wrapper .subtitle {
    margin: 0px;
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    color: #686c77;
  }

  .buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    width: 100%;
    margin-top: 24px;
  }

  button {
    font-family: inherit;
    text-transform: none;
    cursor: pointer;
    appearance: button;
    display: flex;
    justify-content: center;
    outline: none;
    gap: 8px;
    align-items: center;
    line-height: 1;
    text-align: center;
    font-weight: 500;
    position: relative;
    flex-shrink: 0;
    border-radius: 8px;
    padding: 8px 12px;
    border: 1px solid transparent;
    font-size: 14px;
    height: 36px;
    border-color: rgba(17, 17, 17, 0);
    background: rgba(17, 17, 17, 1);
    color: rgba(254, 254, 254, 1);
    flex-grow: 1;
    min-width: 150px;
    cursor: pointer;
  }

  button.ticket .label {
    background: linear-gradient(135deg, #4cd3ff, #0062fe);
    padding: 2px 8px;
    position: absolute;
    top: -1px;
    right: -1px;
    border-top-right-radius: inherit;
    border-bottom-left-radius: 24px;
    font-size: 8px;
    line-height: 12px;
    font-weight: 500;
  }

  button.tg_hyperlink {
    /* --bgAlpha: 0;
    --borderAlpha: 1; */
    border-color: rgba(217, 217, 217, 1);
    color: rgba(25, 104, 229, 1);
    background: rgba(25, 104, 229, 0);
  }

  button.tg_hyperlink img {
    width: 24px;
    height: 24px;
    background-color: rgb(59 130 246);
    border-radius: 100%;
  }
`;

const AdditionalInformationCard = styled.div`
  padding: 24px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  border: none;
  background: #f7f9fb;

  .links {
    display: grid;
    margin-top: 16px;
    gap: 16px;
  }

  .links .link {
    font-weight: 400;
    font-size: 14px;
    color: rgba(25, 104, 229, 1);
    cursor: pointer;
  }

  .box_wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  .box_wrapper .subtitle {
    margin: 0px;
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    color: #686c77;
  }

  .buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    margin-top: 24px;
    width: 100%;
  }

  button {
    font-family: inherit;
    text-transform: none;
    cursor: pointer;
    appearance: button;
    display: flex;
    justify-content: center;
    outline: none;
    gap: 8px;
    align-items: center;
    line-height: 1;
    text-align: center;
    font-weight: 500;
    position: relative;
    flex-shrink: 0;
    border-radius: 8px;
    padding: 8px 12px;
    border: 1px solid transparent;
    font-size: 14px;
    height: 36px;
    border-color: rgba(17, 17, 17, 0);
    background: rgba(17, 17, 17, 1);
    color: rgba(254, 254, 254, 1);
    flex-grow: 1;
    min-width: 150px;
    cursor: pointer;
  }

  button.ticket .label {
    background: linear-gradient(135deg, #4cd3ff, #0062fe);
    padding: 2px 8px;
    position: absolute;
    top: -1px;
    right: -1px;
    border-top-right-radius: inherit;
    border-bottom-left-radius: 24px;
    font-size: 8px;
    line-height: 12px;
    font-weight: 500;
  }

  button.tg_hyperlink {
    /* --bgAlpha: 0;
    --borderAlpha: 1; */
    border-color: rgba(217, 217, 217, 1);
    color: rgba(25, 104, 229, 1);
    background: rgba(25, 104, 229, 0);
  }

  button.tg_hyperlink img {
    width: 24px;
    height: 24px;
    background-color: rgb(59 130 246);
    border-radius: 100%;
  }
`;

const Tickets = styled.div`
  border-radius: 16px;
  border: 1px solid #ecf0f5;
  gap: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 24px;
  height: 608px;

  h4.title {
    margin: 0px;
    font-size: 32px;
    line-height: 36px;
    font-weight: 500;
  }

  p.subtitle {
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    color: #828282;
  }

  button {
    color: #1968e5;
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    background: none;
    outline: none;
    border: none;
    cursor: pointer;
  }
`;

export default SupportPage;
