import styled from "styled-components";

const PrimaryButton = ({ disabled, text, action }) => {
  return (
    <Wrapper
      disabled={disabled}
      className={disabled && "disabled"}
      onClick={action}
    >
      <p>{text}</p>
    </Wrapper>
  );
};

const Wrapper = styled.button`
  color: rgba(254, 254, 254, 1);
  background: rgba(17, 17, 17, 1);
  border-radius: 8px;
  padding: 8px 12px;
  font-size: 14px;
  border: 1px solid transparent;
  font-family: Roboto;
  line-height: 1;
  text-align: center;
  font-weight: 500;
  position: relative;
  height: 36px;
  cursor: pointer;

  &.disabled {
    background: rgba(17, 17, 17, 0.075);
    user-select: none;
    cursor: not-allowed;
  }
`;

export default PrimaryButton;
