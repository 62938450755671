import { useEffect, useRef, useState } from "react";
import Modal from "@mui/material/Modal";
import { styled } from "styled-components";
import Toast from "../hooks/Toast";
import CircularLoader from "../components/styled/loaders/CircularLoader";
import { deleteField, doc, setDoc, updateDoc } from "firebase/firestore";
import { makeid } from "../utils/utils";
import { db, storage } from "../firebase/firebase";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";

const EditAddressModal = ({ open, selected }) => {
  const { editAddress, setEditAddress } = open;

  // toast
  const [openToast, setOpenToast] = useState(false);
  const [toastMessage, setToastMessage] = useState(" ");
  const [toastType, setToastType] = useState(undefined);

  const [name, setName] = useState(undefined);
  const [symbol, setSymbol] = useState(undefined);
  const [address, setAddress] = useState(undefined);
  const [qr, setQR] = useState(undefined);
  const [imageName, setImageName] = useState("");
  const [imageLink, setImageLink] = useState("");
  const [tempUrl, setTempUrl] = useState("");
  //   const { dispatch } = useContext(context);

  const imageRef = useRef();

  function handleImageChange() {
    imageRef.current.click();
  }

  useEffect(() => {
    if (selected) {
      const { name, symbol, address, QR } = selected;
      setName(name);
      setSymbol(symbol);
      setAddress(address);
      setQR(QR);
      //   setIsLoading(false);
    }
  }, [selected]);

  const [isSaving, setIsSaving] = useState(false);

  async function handleSave() {
    setIsSaving(true);

    if (imageLink) {
      submitQR();
    } else {
      handleEditAddress();
    }
  }

  async function submitQR() {
    // setIsUploadingVerification(true);
    if (imageLink) {
      const storageRef = ref(storage, imageName + new Date());
      const uploadTask = uploadBytesResumable(storageRef, imageLink);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // const progress =
          //   (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

          // console.log("Upload is " + progress + "% done");
          switch (snapshot.state) {
            case "paused":
              // console.log("Upload is paused");
              break;
            case "running":
              // console.log("Upload is running");
              break;
            default:
              break;
          }
        },
        (error) => {
          console.log(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            // console.log("File available at", downloadURL);
            // submitVerification(downloadURL);
            handleEditAddressQR(downloadURL);
          });
        }
      );
    }
  }

  async function handleEditAddressQR(url) {
    const namekey = `addresses.${symbol}`;

    const q = doc(db, "admin", "deposit");
    try {
      await updateDoc(q, {
        [namekey]: {
          QR: url,
          address,
          name,
          symbol,
        },
      });
      setIsSaving(false);
      setToastType("success");
      setToastMessage("Saved successfully");
      setOpenToast(true);
    } catch (error) {
      //   setIsSaving(false);
      console.log(error);
      setToastType("error");
      setToastMessage("Could not save. Please try again later");
      setOpenToast(true);
    }
  }

  async function handleEditAddress() {
    const namekey = `addresses.${symbol}`;

    const q = doc(db, "admin", "deposit");
    try {
      await updateDoc(q, {
        [namekey]: {
          QR: qr,
          address,
          name,
          symbol,
        },
      });
      setIsSaving(false);
      setToastType("success");
      setToastMessage("Saved successfully");
      setOpenToast(true);
    } catch (error) {
      setIsSaving(false);
      setToastType("error");
      setToastMessage("Could not save. Please try again later");
      setOpenToast(true);
    }
  }

  function handleAddress(e) {
    const { value } = e.target;

    if (value) {
      setAddress(value);
    } else {
      setAddress("");
    }
  }

  function handleImageURL(e) {
    const file = e.target.files[0];

    if (file) {
      setImageLink(file);
      setImageName(file.name);
      const url = URL.createObjectURL(file);
      setTempUrl(url);
    }
  }

  // delete
  const [isDeleting, setIsDeleting] = useState(false);

  async function handleDelete() {
    setIsDeleting(true);
    const namekey = `addresses.${symbol}`;

    const q = doc(db, "admin", "deposit");
    try {
      await updateDoc(q, {
        [namekey]: deleteField(),
      });

      setIsDeleting(false);
      setToastType("success");
      setToastMessage("Deleted successfully");
      setOpenToast(true);
      setTimeout(() => {
        setEditAddress(false);
      }, 600);
    } catch (error) {
      setIsDeleting(false);
      setToastType("error");
      setToastMessage("Could not delete. Please try again later");
      setOpenToast(true);
    }
  }

  return (
    <>
      {openToast && (
        <Toast
          open={{ openToast, setOpenToast }}
          message={toastMessage}
          type={toastType}
        />
      )}

      <Modal
        open={editAddress}
        onClose={() => setEditAddress(false)}
        style={{
          display: "flex",
          placeContent: "center",
          zIndex: "10001",
          padding: "12px",
        }}
      >
        <ModalStandard className="scrollbar-hide">
          <div className="modal_top">
            <div className="modal_top_left">
              <p>Edit {symbol} Address</p>
            </div>

            <svg
              width="15"
              height="14"
              viewBox="0 0 15 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={() => setEditAddress(!editAddress)}
              style={{ cursor: "pointer" }}
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M11.8647 0.366365C12.3532 -0.122122 13.1451 -0.122122 13.6336 0.366365C14.1221 0.854853 14.1221 1.64685 13.6336 2.13533L8.88929 6.87968L13.8743 11.8647C14.3628 12.3532 14.3628 13.1451 13.8743 13.6336C13.3858 14.1221 12.5938 14.1221 12.1053 13.6336L7.12032 8.64864L2.13533 13.6336C1.64685 14.1221 0.854853 14.1221 0.366366 13.6336C-0.122122 13.1451 -0.122122 12.3532 0.366366 11.8647L5.35136 6.87968L0.607014 2.13533C0.118527 1.64685 0.118527 0.854853 0.607014 0.366365C1.0955 -0.122122 1.8875 -0.122122 2.37598 0.366365L7.12032 5.11071L11.8647 0.366365Z"
                fill="#858DAD"
              />
            </svg>
          </div>

          <div className="modal_content">
            <div className="top">
              <TextBox>
                <div className="input_wrapper">
                  <div className="input_block">
                    <label htmlFor="value">Address:</label>
                    <input
                      placeholder={address}
                      defaultValue={address}
                      onChange={handleAddress}
                    />
                  </div>
                </div>
              </TextBox>

              {/* qr */}
              <div
                className="qr-code"
                style={{
                  margin: "0 auto",
                  height: "200px",
                  border: "1px solid #f3f3f3",
                  borderRadius: "12px",
                  display: "grid",
                  cursor: "pointer",
                  placeContent: "center",
                  marginTop: "40px",
                  //   margin: "40px 0px",
                  position: "relative",
                }}
                onClick={handleImageChange}
              >
                {qr && !tempUrl && (
                  <span
                    style={{
                      maxHeight: "250px",
                      overflow: "hidden",
                      padding: "8px",
                      height: "100%",
                    }}
                  >
                    <img
                      style={{ margin: "0px" }}
                      src={qr}
                      alt=""
                      className="qr-code"
                    />
                  </span>
                )}

                {qr && tempUrl && (
                  <span
                    style={{
                      maxHeight: "250px",
                      overflow: "hidden",
                      padding: "8px",
                      height: "100%",
                    }}
                  >
                    <img
                      style={{ margin: "0px" }}
                      src={tempUrl}
                      alt=""
                      className="qr-code"
                    />
                  </span>
                )}

                {!qr && tempUrl && (
                  <span
                    style={{
                      maxHeight: "250px",
                      overflow: "hidden",
                      padding: "8px",
                      height: "100%",
                    }}
                  >
                    <img
                      style={{ margin: "0px" }}
                      src={tempUrl}
                      alt=""
                      className="qr-code"
                    />
                  </span>
                )}

                {!qr && !tempUrl && (
                  <p
                    style={{
                      padding: "8px",
                      color: "white",
                      lineHeight: "20px",
                      fontWeight: "500",
                      fontSize: "14px",
                      color: "#111111",
                    }}
                  >
                    Tap here to add or change QR code. Leave empty to save
                    address without QR code.
                  </p>
                )}

                <input
                  type="file"
                  style={{
                    opacity: "0",
                    position: "absolute",
                    pointerEvents: "none",
                    top: "0",
                    left: "0",
                    height: "100%",
                    maxWidth: "100%",
                  }}
                  ref={imageRef}
                  onChange={handleImageURL}
                  accept="image/png, image/gif, image/jpeg, image/svg"
                />
              </div>
            </div>

            <div className="bottom">
              <div className="btns">
                <button
                  onClick={handleDelete}
                  disabled={isSaving || isDeleting}
                  className={
                    isSaving || isDeleting
                      ? "red_button full disabled"
                      : "red_button full "
                  }
                >
                  {isDeleting ? (
                    <CircularLoader bg="#cccccc" size="16" color="#ffffff" />
                  ) : (
                    "Delete"
                  )}
                </button>

                <button
                  onClick={handleSave}
                  disabled={isSaving || isDeleting}
                  className={
                    isSaving || isDeleting
                      ? "dark_button full disabled"
                      : "dark_button full "
                  }
                >
                  {isSaving ? (
                    <CircularLoader bg="#cccccc" size="16" color="#ffffff" />
                  ) : (
                    "Save"
                  )}
                </button>
              </div>
            </div>
          </div>
        </ModalStandard>
      </Modal>
    </>
  );
};

const TextArea = styled.textarea`
  font-family: inherit;
  color: inherit;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #d9d9d9;
  width: 100%;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  font-family: Roboto;
  height: 180px;
  box-sizing: border-box;
  margin-top: 12px;
`;

const BgDropdown = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 20px;
  max-width: 720px;

  select {
    width: 100%;
    border: none;
    outline: none;
    background-color: transparent;
    appearance: none;
    padding: 0;
    margin: 0;
    font-weight: 500;
  }

  h3.title {
    margin: 0px;
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
  }

  .input_wrapper {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    background: transparent;
    padding: 10px 12px;
    border-radius: 8px;
    border-width: 1px;
    border-style: solid;
    border-color: #f3f3f3;
    background-color: #f3f3f3;
    cursor: pointer;
    box-sizing: border-box;
  }

  .input_block {
    box-sizing: border-box;
    flex-grow: 1;
    overflow: hidden;
    max-width: 100%;
  }

  p.label {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
    transition: transform 0.1s ease 0s;
    margin-top: 4px;
    line-height: 1;
    color: #686c77;
    font-size: 14px;
  }

  p.amount {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
    transition: transform 0.1s ease 0s;
    margin-top: 4px;
    line-height: 1;
    font-size: 15px;
  }

  p.amount span {
    color: #686c77;
  }

  .caption {
    display: flex;
    gap: 4px;
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
  }

  .caption span {
    color: rgba(21, 128, 61, 1);
  }
`;

const TextBox = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 24px;
  max-width: 720px;
  margin-top: 16px;

  h3.title {
    margin: 0px;
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
  }

  .input_wrapper {
    display: flex;
    box-sizing: border-box;
    align-items: center;
    position: relative;
    width: 100%;
    background: transparent;
    padding: 10px 12px;
    border-radius: 8px;
    border-width: 1px;
    border-style: solid;
    border-color: #f3f3f3;
    height: 56px;
    width: 100% !important;
  }

  .input_block {
    box-sizing: border-box;
    flex-grow: 1;
    overflow: hidden;
    max-width: 100%;
    position: relative;
  }

  label {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
    transition: transform 0.1s ease 0s;
    margin-top: 4px;
    line-height: 1;
    color: #686c77;
    font-size: 14px;
    margin-bottom: 2px;
  }

  .max_button {
    font-size: 12px;
    font-weight: 600;
    background: none;
    outline: none;
    border: none;
    cursor: pointer;
  }

  input {
    box-sizing: border-box;
    margin: 0px;
    padding: 0px;
    font-family: inherit;
    font-size: 100%;
    font-weight: inherit;
    line-height: inherit;
    color: inherit;
    flex-grow: 1;
    background: transparent;
    overflow: hidden;
    white-space: nowrap;
    min-width: 1%;
    width: 100%;
    text-overflow: ellipsis;
    max-width: 100%;
    outline: none;
    border: none;
    transition: 0.3s ease-in-out;
  }

  /* input:focus ~ label {
    color: red;
    transition-duration: 0.2s;
    transform: translate(0, -1.5em) scale(0.9, 0.9);
  } */

  .caption {
    display: flex;
    gap: 4px;
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
  }

  .caption span {
    color: rgba(21, 128, 61, 1);
  }

  /* @media screen and (max-width: 768px) {
    & {
      width: 100% !important;
      max-width: unset;
    }
  } */
`;

const ModalStandard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  /* justify-content: space-between; */
  align-items: flex-start;
  border-radius: 16px;
  width: 660px;
  min-height: 480px;
  height: 70%;
  background-color: #fff;
  opacity: 1;
  padding: 24px;
  box-sizing: border-box;
  overflow: hidden;
  outline: none;
  place-self: center;
  position: relative;

  .qr-code {
    max-width: 200px;
    width: 100%;
    height: auto;
    place-self: center;
    margin-top: 24px;
    margin: 0 auto;
  }

  .bottom {
    margin-top: auto;
    position: absolute;
    bottom: 30px;
  }

  .btns {
    display: flex;

    gap: 12px;
  }

  .modal_top {
    color: white;
    font-size: 16px;
    font-weight: 600;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .modal_top_left {
    display: flex;
    align-items: center;
  }

  .modal_top_left img {
    height: 30px;
    width: auto;
    margin-right: 10px;
  }

  .modal_top_left p {
    color: #333742;
    font-size: 16px;
    font-family: Roboto;
  }

  .modal_content {
    width: 100%;
    box-sizing: border-box;
  }

  .modal_content .top {
    width: 100%;
    box-sizing: border-box;
  }

  .modal_content .top .text {
    color: #333742;
    font-family: Roboto;
    line-height: 22px;
    font-size: 18px;
  }

  .checkbox_wrapper {
    display: flex;
    gap: 8px;
    align-items: center;
    margin-top: 16px;
  }

  .checkbox_wrapper p {
    font-size: 16px;
    line-height: 133%;
    font-weight: 500;
  }

  .slide {
  }

  .slider {
  }

  .slide img {
  }
`;

const TransparentDropdown = styled.div`
  box-sizing: border-box;
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: flex-start; */
  flex-direction: column;
  gap: 24px;
  margin-bottom: 20px;
  max-width: 720px;

  h3.title {
    font-size: 16px;
    line-height: 1;
    font-weight: 500;
    margin-bottom: 12px;
  }

  .input_wrapper {
    display: flex;
    box-sizing: border-box;
    align-items: center;
    position: relative;
    width: 100%;
    background: transparent;
    padding: 10px 12px;
    border-radius: 8px;
    border-width: 1px;
    border-style: solid;
    border-color: #f3f3f3;
    height: 56px;
    font-size: 16px;
    font-weight: 500 !important;
  }

  .input_block {
    box-sizing: border-box;
    flex-grow: 1;
    overflow: hidden;
    max-width: 100%;
    position: relative;
  }

  label {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
    transition: transform 0.1s ease 0s;
    margin-top: 4px;
    line-height: 1;
    color: #686c77;
    font-size: 14px;
    margin-bottom: 2px;
  }

  .max_button {
    font-size: 12px;
    font-weight: 600;
    background: none;
    outline: none;
    border: none;
    cursor: pointer;
  }

  input {
    box-sizing: border-box;
    margin: 0px;
    padding: 0px;
    font-family: inherit;
    font-size: 100%;
    font-weight: inherit;
    line-height: inherit;
    color: inherit;
    flex-grow: 1;
    background: transparent;
    overflow: hidden;
    white-space: nowrap;
    min-width: 1%;
    width: 100%;
    text-overflow: ellipsis;
    max-width: 100%;
    outline: none;
    border: none;
    transition: 0.3s ease-in-out;
  }

  /* input:focus ~ label {
    color: red;
    transition-duration: 0.2s;
    transform: translate(0, -1.5em) scale(0.9, 0.9);
  } */

  .caption {
    display: flex;
    gap: 4px;
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
  }

  .caption span {
    color: rgba(21, 128, 61, 1);
  }

  p.option {
    color: #333742;
    font-size: 16px;
    font-weight: 600 !important;
    font-family: Roboto;
  }
`;

export default EditAddressModal;
