import styled from "styled-components";
import "../../../css/settings/safety.css";
import { useContext } from "react";
import { context } from "../../../context/context";

const SafetyAndSecurity = () => {
  const { userData } = useContext(context);

  return (
    <div>
      {/* <div className="SafetyBox"> */}

      <Box>
        {/* <h1 className="box_title">Profile settings</h1> */}
        <div className="section form flex-16">
          <TextBox>
            <div className="input_wrapper">
              <div className="input_block">
                <label htmlFor="value">Email</label>
                <input placeholder="" />
              </div>
            </div>
          </TextBox>

          <EmailAlert>
            <span className="left">
              <img src="./assets/icons/misc/warning.svg" />
              <p>Please confirm your email address</p>
            </span>

            <button>Confirm</button>
          </EmailAlert>

          <TextBox>
            <div className="input_wrapper">
              <div className="input_block">
                <label htmlFor="value">Password</label>
                <input placeholder="" />
              </div>
              <button className="blue_button">Edit</button>
            </div>
          </TextBox>
        </div>
      </Box>

      {/* <Box>
        <h1 className="box_title">Two-factor authorization</h1>
        <div className="section flex-16">
          <div className="check_section flex_divide">
            <p>Two-factor authorization to log in</p>
            <input type="checkbox" />
          </div>
        </div>
      </Box> */}

      {/* <div className="auth-settings width">
        <h1>Authorization settings</h1>
        <div className="email-box">
          <div>
            <p>Email</p>
            <h5>Timothymaarv@gmail.com</h5>
          </div>
        </div>
        <div className="error">
          <img
            src="./assets/icons/settings/error.svg"
            alt=""
            className="error"
          />
          <p>
            Please confirm your email address <a href="">Confirm</a>
          </p>
        </div>
        <div className="email-box">
          <div className="email">
            <p>Password</p>
            <h5>************</h5>
          </div>
          <div className="edit">
            <a>Edit</a>
          </div>
        </div>
      </div> */}
      {/* <div className="api-token width">
        <h1 className="space">Your API token</h1>

        <div className="space">
          <p>
            You need an API token to interact with <a href="">our API</a>
          </p>
          <p>You can fully automate your work with us</p>
        </div>
        <div className="email-box space">
          <div className="email">
            <p>Token</p>
            <h5>446076|ioYeLT1svmDUiLWbYBbEa4nfeKSaT8hn0VsCTZRv</h5>
          </div>
        </div>
      </div> */}
      {/* <div className="Two-factor width">
        <h1>Two-factor authorization</h1>
        <div className="flex">
          <button className="blue-btn">Telegram</button>
          <button className="blue-btn">Google Authenticator</button>
        </div>
        <div className="levels">
          <div className="box">
            <p>Two-factor authorization to log in</p>{" "}
            <input type="checkbox" name="check1" id="check" />
          </div>
          <div className="box">
            <p>Two-factor authorization to view card details</p>{" "}
            <input type="checkbox" name="check2" id="check" />
          </div>
          <div className="box">
            <p>Two-factor authorization to withdraw money</p>{" "}
            <input type="checkbox" name="check3" id="check" />
          </div>
          <div className="box">
            <p>Two-factor authorization to send money to another user</p>{" "}
            <input type="checkbox" name="check4" id="check" />
          </div>
        </div>
      </div> */}
      {/* <div className="last-activity width">
        <h1 className="no-margin">Last activity</h1>
        <table>
          <thead>
            <tr>
              <th>Last login from IP</th>
              <th>Location</th>
              <th>Browser</th>
              <th>
                <a href="" className="float">
                  Remove all
                </a>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="row">
              <td>102.89.47.163</td>
              <td>Lagos, Nigeria</td>
              <td className="ash">Chrome</td>
              <td>
                <p className="float">Current session</p>
              </td>
            </tr>
            <tr>
              <td>102.89.33.199</td>
              <td>Lagos, Nigeria</td>
              <td className="ash">Chrome</td>
              <td>
                <img
                  src="./assets/icons/settings/cross.svg"
                  alt="Cross"
                  className="cross"
                />
              </td>
            </tr>
            <tr>
              <td className="none">102.89.47.163</td>
              <td className="none">Lagos, Nigeria</td>
              <td className="ash none">Chrome</td>
              <td className="none">
                <img
                  src="./assets/icons/settings/cross.svg"
                  alt="cross"
                  className="cross"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div> */}
      {/* <div className="button">
        <button className="red-btn ">Delete</button>
      </div> */}
      {/* </div> */}
    </div>
  );
};

const Box = styled.div`
  padding: 24px;
  width: 70%;
  border: 1px solid #e1e8f1;
  border-radius: 20px;
  margin-bottom: 10px;
  /* height: 155px; */
  /* box-sizing: border-box; */

  input[type="checkbox"] {
    cursor: pointer;
    accent-color: #1968e5;
    border: 1px solid #1968e5;
    border-radius: 4px;
  }

  h1.box_title {
    font-size: 20px;
    font-weight: 700;
    line-height: 1;
    color: #111111;
  }

  .check_section {
  }

  .check_section p {
    font-size: 14px;
    line-height: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    white-space: nowrap;
  }

  .user_wrapper {
    display: flex;
    gap: 16px;
    align-items: center;
    margin-top: 24px;
  }

  .user_info {
    display: grid;
    gap: 4px;
  }

  .user_info .name {
    color: #111111;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    white-space: nowrap;
  }

  .user_info .register_info {
    color: #686c77;
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    white-space: nowrap;
  }

  .flex_center {
    display: flex;
    align-items: center;
  }

  .flex_divide {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .form {
    display: flex;
    flex-direction: column;
    max-width: 70%;
  }

  .flex-8 {
    gap: 8px;
  }

  .flex-12 {
    gap: 12px;
  }

  .flex-16 {
    gap: 16px;
  }

  .flex-24 {
    gap: 24px;
  }

  .flex-32 {
    gap: 32px;
  }

  .section {
    margin-top: 24px;
  }

  .date_items {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 12px;
  }

  .date_item p {
    font-size: 14px;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    white-space: nowrap;
  }

  .light_select {
    box-sizing: border-box;
    display: flex;
    outline: none;
    gap: 8px;
    background-color: rgba(17, 17, 17, 0.05);
    border-radius: 8px;
    padding: 8px 12px;
    font-size: 14px;
    border: none;
    align-items: center;
    line-height: 1;
    padding-right: 10px;
    font-weight: 500;
    height: 36px;
  }

  @media screen and (max-width: 768px) {
    & {
      width: 100% !important;
      box-sizing: border-box;
    }

    .form {
      width: 100%;
      display: flex;
      flex-direction: column;
      max-width: unset;
      box-sizing: border-box;
    }
  }
`;

const TextBox = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 24px;
  max-width: 720px;

  h3.title {
    margin: 0px;
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
  }

  .input_wrapper {
    display: flex;
    box-sizing: border-box;
    align-items: center;
    position: relative;
    width: 100%;
    background: transparent;
    padding: 10px 12px;
    border-radius: 8px;
    border-width: 1px;
    border-style: solid;
    border-color: #f3f3f3;
    height: 56px;
    width: 100% !important;
  }

  .input_block {
    box-sizing: border-box;
    flex-grow: 1;
    overflow: hidden;
    max-width: 100%;
    position: relative;
  }

  label {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
    transition: transform 0.1s ease 0s;
    margin-top: 4px;
    line-height: 1;
    color: #686c77;
    font-size: 14px;
    margin-bottom: 2px;
  }

  .max_button {
    font-size: 12px;
    font-weight: 600;
    background: none;
    outline: none;
    border: none;
    cursor: pointer;
  }

  input {
    box-sizing: border-box;
    margin: 0px;
    padding: 0px;
    font-family: inherit;
    font-size: 100%;
    font-weight: inherit;
    line-height: inherit;
    color: inherit;
    flex-grow: 1;
    background: transparent;
    overflow: hidden;
    white-space: nowrap;
    min-width: 1%;
    width: 100%;
    text-overflow: ellipsis;
    max-width: 100%;
    outline: none;
    border: none;
    transition: 0.3s ease-in-out;
  }

  /* input:focus ~ label {
    color: red;
    transition-duration: 0.2s;
    transform: translate(0, -1.5em) scale(0.9, 0.9);
  } */

  .caption {
    display: flex;
    gap: 4px;
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
  }

  .caption span {
    color: rgba(21, 128, 61, 1);
  }

  /* @media screen and (max-width: 768px) {
    & {
      width: 100% !important;
      max-width: unset;
    }
  } */
`;

const EmailAlert = styled.div`
  display: flex;
  gap: 8px;
  padding: 16px;
  background: #fbe3e3;
  border-radius: 16px;
  border: 1px solid #df1b1b;
  max-width: max-content;
  align-items: center;
  width: 100%;
  white-space: nowrap;

  .left {
    display: flex;
    gap: 8px;
    align-items: center;
    white-space: nowrap;
  }

  .left p {
    font-size: 14px;
    line-height: 142%;
    gap: 8px;
    color: #df1b1b;
  }

  button {
    font-size: 14px;
    color: rgba(5, 104, 229, 1);
    background: none;
    border: none;
    font-weight: 500;
    cursor: pointer;
  }

  @media screen and (max-width: 768px) {
    & {
      flex-direction: column;
      width: 100% !important;
      max-width: 100%;
      box-sizing: border-box;
    }
  }
`;

export default SafetyAndSecurity;
