import { useMediaQuery } from "@mui/material";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import SupportModal from "../../modals/SupportModal";
import { context } from "../../context/context";

const Sidebar = ({ tab, hide, collapse }) => {
  const { activeTab, setActiveTab } = tab;
  const { sidebarHide, setSidebarHide } = hide;
  const { sidebarCollapse, setSidebarCollapse } = collapse;

  const mobile = useMediaQuery("(max-width: 768px)");

  const { userData } = useContext(context);

  const sidebarItems = [
    {
      title: "Dashboard",
      link: "/dashboard",
      default: "./assets/icons/sidebar/default/dashboard.svg",
      highlighted: "./assets/icons/sidebar/highlighted/dashboard.svg",
    },
    {
      title: "Accounts",
      link: "/accounts",
      default: "./assets/icons/sidebar/default/accounts.svg",
      highlighted: "./assets/icons/sidebar/highlighted/accounts.svg",
    },
    {
      title: "Cards",
      link: "/cards",
      default: "./assets/icons/sidebar/default/cards.svg",
      highlighted: "./assets/icons/sidebar/highlighted/cards.svg",
    },
    {
      title: "Transaction History",
      link: "/history",
      default: "./assets/icons/sidebar/default/transaction-history.svg",
      highlighted: "./assets/icons/sidebar/highlighted/transaction-history.svg",
    },
    // {
    //   title: "Teams",
    //   link: "/teams",
    //   default: "./assets/icons/sidebar/default/teams.svg",
    //   highlighted: "./assets/icons/sidebar/highlighted/teams.svg",
    // },
    {
      title: "Support",
      link: "/support",
      default: "./assets/icons/sidebar/default/support.svg",
      highlighted: "./assets/icons/sidebar/highlighted/support.svg",
    },
    {
      title: "Earn",
      link: "/earn",
      default: "./assets/icons/sidebar/default/referral-program.svg",
      highlighted: "./assets/icons/sidebar/highlighted/referral-program.svg",
    },
  ];

  const navigate = useNavigate();

  function handleMobileNav(link) {
    setSidebarHide(true);
    navigate(link);
  }

  const [contactSupport, setContactSupport] = useState(false);

  return (
    <>
      {contactSupport && (
        <SupportModal open={{ contactSupport, setContactSupport }} />
      )}
      {/* main */}

      {!mobile && (
        <DesktopSidebar className={sidebarCollapse && "collapse"}>
          <div className="sidebar_section">
            <div className="sidebar_items">
              {sidebarItems.map((item, index) => (
                <div
                  key={index}
                  className={
                    item.title === activeTab
                      ? "sidebar_item active"
                      : "sidebar_item"
                  }
                  onClick={() => navigate(item.link)}
                >
                  <img
                    src={
                      item.title === activeTab ? item.highlighted : item.default
                    }
                    alt=""
                    className="sidebar_item_icon"
                  />
                  <p className="sidebar_item_text">{item.title}</p>
                </div>
              ))}
            </div>
          </div>

          <div className="sidebar_section">
            <div className="sidebar_items">
              <div
                className={"sidebar_item"}
                onClick={() => setContactSupport(!contactSupport)}
              >
                <img
                  src="./assets/icons/sidebar/default/make-us-better.svg"
                  alt="Make us better"
                  className="sidebar_item_icon"
                  style={{
                    background: "#1967e5f",
                    padding: "4px",
                    borderRadius: "100%",
                  }}
                />
                <p className="sidebar_item_text">Make us better</p>
              </div>
            </div>
          </div>

          {userData.admin && (
            <div className="sidebar_section">
              <div className="sidebar_items">
                <div
                  className={
                    activeTab === "Admin"
                      ? "sidebar_item active"
                      : "sidebar_item"
                  }
                  onClick={() => navigate("/admin")}
                >
                  <img
                    src="./assets/icons/sidebar/default/admin.svg"
                    alt="Admin"
                    className="sidebar_item_icon"
                    style={{
                      background: "#1967e5f",
                      padding: "4px",
                      borderRadius: "100%",
                    }}
                  />
                  <p className="sidebar_item_text">Admin</p>
                </div>
              </div>
            </div>
          )}

          {/* teams */}

          {/* <div className="sidebar_section">
        <p className="sidebar_section_title">Teams</p>
        <div className="sidebar_items">
          <div className="sidebar_item">
            <img
              src="./assets/icons/sidebar/default/dashboard.svg"
              alt=""
              className="sidebar_item_icon"
            />
            <p className="sidebar_item_text">Dashboard</p>
          </div>
        </div>
      </div> */}

          {/* make us better / feedback */}
        </DesktopSidebar>
      )}

      {mobile && (
        <MobileSidebar className={sidebarHide && "hide"}>
          <div className="sidebar_section">
            <div className="sidebar_items">
              {sidebarItems.map((item, index) => (
                <div
                  key={index}
                  className={
                    item.title === activeTab
                      ? "sidebar_item active"
                      : "sidebar_item"
                  }
                  onClick={() => handleMobileNav(item.link)}
                >
                  <img
                    src={
                      item.title === activeTab ? item.highlighted : item.default
                    }
                    alt=""
                    className="sidebar_item_icon"
                  />
                  <p className="sidebar_item_text">{item.title}</p>
                </div>
              ))}
            </div>
          </div>

          <div className="sidebar_section">
            <div className="sidebar_items">
              <div
                className={"sidebar_item"}
                onClick={() => setContactSupport(!contactSupport)}
              >
                <img
                  src="./assets/icons/sidebar/default/make-us-better.svg"
                  alt="Make us better"
                  className="sidebar_item_icon"
                  style={{
                    background: "#1967e5f",
                    padding: "4px",
                    borderRadius: "100%",
                  }}
                />
                <p className="sidebar_item_text">Make us better</p>
              </div>
            </div>
          </div>

          {userData.admin && (
            <div className="sidebar_section">
              <div className="sidebar_items">
                <div
                  className={
                    activeTab === "Admin"
                      ? "sidebar_item active"
                      : "sidebar_item"
                  }
                  onClick={() => navigate("/admin")}
                >
                  <img
                    src="./assets/icons/sidebar/default/admin.svg"
                    alt="Admin"
                    className="sidebar_item_icon"
                    style={{
                      background: "#1967e5f",
                      padding: "4px",
                      borderRadius: "100%",
                    }}
                  />
                  <p className="sidebar_item_text">Admin</p>
                </div>
              </div>
            </div>
          )}

          {/* teams */}

          {/* <div className="sidebar_section">
        <p className="sidebar_section_title">Teams</p>
        <div className="sidebar_items">
          <div className="sidebar_item">
            <img
              src="./assets/icons/sidebar/default/dashboard.svg"
              alt=""
              className="sidebar_item_icon"
            />
            <p className="sidebar_item_text">Dashboard</p>
          </div>
        </div>
      </div> */}

          {/* make us better / feedback */}
        </MobileSidebar>
      )}
    </>
  );
};

const DesktopSidebar = styled.div`
  max-width: 216px;
  width: 100%;
  position: relative;
  z-index: 10001;
  max-height: 100vh;
  overflow-y: scroll;
  background-color: white;
  padding-left: 12px;
  padding-top: 12px;
  box-sizing: border-box;

  &.collapse {
    max-width: 68px;
    /* z-index: 30;
    left: 0;
    transform: none;
    opacity: 1;
    position: sticky;
    top: var(--headerHeight);
    min-height: 100%; */
  }

  .sidebar_section:not(:first-child) {
    margin-top: 12px;
    padding-top: 12px;
    border-top: 1px solid #e5e5e5;
  }

  .sidebar_section {
    /* background-color: blue; */
  }

  .sidebar_section.border {
    /* background-color: blue; */
  }

  .sidebar_items {
    display: flex;
    flex-direction: column;
    gap: 0;
    height: 100%;
  }

  .sidebar_item {
    max-height: 24px;
    height: 100%;
    display: grid;
    grid-template-columns: 24px auto;
    align-content: center;
    align-items: center;
    padding: 8px 12px;
    gap: 24px;
    border-radius: 12px;
    white-space: nowrap;
    line-height: 1;
    cursor: pointer;
  }

  .sidebar_item img {
    max-width: 24px;
    height: auto;
  }

  .sidebar_item_text {
    font-family: Roboto;
    font-size: 14px;
  }

  .sidebar_item.active,
  .sidebar_item:hover {
    background-color: #f3f3f3;
  }

  &.collapse .sidebar_item {
    max-height: 74px;
    box-sizing: border-box;
    display: flex;
    gap: 6px;
    flex-direction: column;
    align-items: center;
    align-content: center;
    padding: 14px 7px !important;
    max-height: unset;
  }

  &.collapse .sidebar_item img {
    height: 16px;
    width: auto;
    /* padding: 2px; */
  }

  &.collapse .sidebar_item p {
    font-size: 10px !important;
    line-height: 14px;
    font-weight: 400;
    white-space: wrap;
    text-align: center;
  }
`;

const MobileSidebar = styled.div`
  width: 100%;
  width: 100%;
  position: fixed;
  left: 0;
  z-index: 10001;
  max-height: 100vh;
  height: 100%;
  overflow-y: scroll;
  background-color: white;
  padding: 12px;
  box-sizing: border-box;
  transition: transform 0.2s ease, opacity 0.2s ease;
  transform: none;

  &.hide {
    transform: translate(-100%);
  }

  .sidebar_section:not(:first-child) {
    margin-top: 12px;
    padding-top: 12px;
    border-top: 1px solid #e5e5e5;
  }

  .sidebar_section {
    /* background-color: blue; */
  }

  .sidebar_section.border {
    /* background-color: blue; */
  }

  .sidebar_items {
    display: grid;
    gap: 0;
  }

  .sidebar_item {
    max-height: 24px;
    height: 100%;
    display: grid;
    grid-template-columns: 24px auto;
    align-content: center;
    align-items: center;
    padding: 8px 12px;
    gap: 24px;
    border-radius: 12px;
    white-space: nowrap;
    line-height: 1;
    cursor: pointer;
  }

  .sidebar_item img {
    max-width: 24px;
    height: auto;
  }

  .sidebar_item_text {
    font-family: Roboto;
    font-size: 14px;
  }

  .sidebar_item.active,
  .sidebar_item:hover {
    background-color: #f3f3f3;
  }
`;

export default Sidebar;
