import React from "react";
import styled from "styled-components";

const CreateTicket = ({ ticket }) => {
  const { createTicket, setCreateTicket } = ticket;

  return (
    <CreateTicketWrapper>
      <div
        className="back_button_wrapper"
        onClick={() => setCreateTicket(!createTicket)}
      >
        <button>
          <img src="./assets/icons/misc/back-arrow.svg" />
        </button>
        <p>Support</p>
      </div>
      <p className="page_title">New Ticket</p>

      <div className="section mt-60">
        {/* ticket subject */}
        <TicketSubject>
          <h4 className="title">Ticket subject</h4>
          <input
            className="input"
            maxLength="20"
            placeholder="Enter the subject of the message"
          />
        </TicketSubject>

        {/* ticket description */}
        <TicketDescription className="mt-32">
          <h4 className="title">Ticket description</h4>
          <textarea
            className="textarea"
            maxLength="200"
            placeholder="Enter the full text of your appeal"
          />
        </TicketDescription>

        {/* attachment */}
        <FileButton>
          <img src="./assets/icons/misc/file.svg" alt="" />
          Attach a file
          <span className="description"> (Maximum files: 10) </span>
        </FileButton>
      </div>
    </CreateTicketWrapper>
  );
};

const CreateTicketWrapper = styled.div`
  .mt-32 {
    margin-top: 32px;
  }

  .mt-48 {
    margin-top: 48px;
  }

  .mt-60 {
    margin-top: 60px;
  }

  .back_button_wrapper {
    display: flex;
    line-height: 1;
    gap: 12px;
    margin-bottom: 24px;
    align-items: center;
  }

  .back_button_wrapper button {
    width: 24px;
    height: 24px;
    border-radius: 100%;
    background: rgba(17, 17, 17, 0.05);
    border: none;
    outline: none;
    cursor: pointer;
  }

  .back_button_wrapper p {
    font-weight: 700;
    font-size: 16px;
    line-height: 1;
  }
`;

const TicketSubject = styled.div`
  gap: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;

  h4 {
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
  }

  input {
    font-family: inherit;
    color: inherit;
    padding: 16px;
    border-radius: 8px;
    border: 1px solid #d9d9d9;
    width: 100%;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
  }
`;

const TicketDescription = styled.div`
  gap: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;

  h4 {
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
  }

  textarea {
    font-family: inherit;
    color: inherit;
    padding: 16px;
    border-radius: 8px;
    border: 1px solid #d9d9d9;
    width: 100%;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    font-family: Roboto;
    height: 180px;
  }
`;

const FileButton = styled.button`
  font-family: inherit;
  text-transform: none;
  cursor: pointer;
  appearance: button;
  display: flex;
  justify-content: center;
  outline: none;
  gap: 8px;
  align-items: center;
  line-height: 1;
  text-align: center;
  position: relative;
  flex-shrink: 0;
  margin-top: 10px;
  width: 100%;
  font-weight: 400;
  border-radius: 8px;
  padding: 8px 12px;
  border: 1px solid transparent;
  font-size: 14px;
  height: 36px;
  border-color: rgba(217, 217, 217, 1);
  background: rgba(51, 55, 66, 0);
  color: rgba(51, 55, 66, 1);
`;

export default CreateTicket;
