import { useContext, useState } from "react";
import styled from "styled-components";
import { context } from "../../context/context";
import {
  ClickAwayListener,
  Popover,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const Header = ({ hide, collapse }) => {
  const { userData } = useContext(context);
  const { sidebarHide, setSidebarHide } = hide;
  const { sidebarCollapse, setSidebarCollapse } = collapse;

  const navigate = useNavigate();

  const mobile = useMediaQuery("(max-width: 768px)");

  const [showUserDropDown, setShowUserDropdown] = useState(false);

  const firstItems = [
    {
      title: "Safety and security",
      link: "/settings",
      icon: "./assets/icons/header/safety.svg",
    },
    // {
    //   title: "KYC Required",
    //   link: "/settings",
    //   icon: "./assets/icons/header/kyc.svg",
    // },
  ];

  const secondItems = [
    // {
    //   title: "Notifications settings",
    //   link: "/settings",
    //   icon: "./assets/icons/header/notifications.svg",
    // },
    {
      title: "Settings",
      link: "/settings",
      icon: "./assets/icons/header/settings.svg",
    },
  ];

  const thirdItems = [
    {
      title: "Exit account",
      link: "/settings",
      icon: "./assets/icons/header/exit.svg",
    },
  ];

  return (
    <>
      {!mobile && (
        <DesktopHeader>
          <div className="header_left">
            {/* hamburger */}
            <img
              src="./assets/icons/header/hamburger.svg"
              alt=""
              className="hamburger"
              onClick={() => setSidebarCollapse(!sidebarCollapse)}
            />

            {/* logo */}
            {/* <img
          className="logo"
          src="https://epn.net/storage/2024/02/02/6654840424c66d6d740b54e36cbe5410845e4647.png"
          loading="lazy"
          alt=""
        ></img> */}
          </div>
          <QuickOverview>
            <div className="left">
              <p className="title">Balance</p>

              <span className="amount">
                $
                {String(userData?.totalBalance).slice(
                  0,
                  String(userData?.totalBalance).indexOf(".")
                )}
                <span>
                  {String(userData?.totalBalance).slice(
                    String(userData?.totalBalance).indexOf(".")
                  )}
                </span>
              </span>

              {/* <span className="amount">
                $0
                <span>.00</span>
              </span> */}
            </div>

            {/* <div className="right">
              <p className="title">Balance</p>
              <span className="account_type">Standard</span>
            </div> */}
          </QuickOverview>

          <UserDropdownContainer>
            <UserProfile onClick={() => setShowUserDropdown(!showUserDropDown)}>
              <p>{String(userData.email).slice(0, 2)}</p>
            </UserProfile>
            {showUserDropDown && (
              <ClickAwayListener
                onClickAway={() => setShowUserDropdown(!showUserDropDown)}
              >
                <UserDropdown>
                  <div className="drop_section user">
                    <div className="user_wrapper">
                      <UserProfile
                        onClick={() => setShowUserDropdown(!showUserDropDown)}
                      >
                        <p>{String(userData.email).slice(0, 2)}</p>
                      </UserProfile>
                      <div>
                        <p className="user_email">{userData.email}</p>
                        <p className="user_level">
                          Account level - {userData.accountType}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="drop_divider" />

                  <div className="drop_section">
                    {/* first */}
                    {firstItems.map((item, index) => (
                      <span
                        key={index}
                        className="drop_item"
                        onClick={() => navigate(item.link)}
                      >
                        <img src={item.icon} alt={item.title} />
                        <p>{item.title}</p>
                      </span>
                    ))}
                  </div>

                  <div className="drop_divider" />

                  <div className="drop_section">
                    {/* first */}
                    {secondItems.map((item, index) => (
                      <span
                        key={index}
                        className="drop_item"
                        onClick={() => navigate(item.link)}
                      >
                        <img src={item.icon} alt={item.title} />
                        <p>{item.title}</p>
                      </span>
                    ))}
                  </div>

                  <div className="drop_divider" />

                  <div className="drop_section">
                    {/* first */}
                    {thirdItems.map((item, index) => (
                      <span
                        key={index}
                        className="drop_item"
                        onClick={() => navigate("/login")}
                      >
                        <img src={item.icon} alt={item.title} />
                        <p>{item.title}</p>
                      </span>
                    ))}
                  </div>
                </UserDropdown>
              </ClickAwayListener>
            )}
          </UserDropdownContainer>
        </DesktopHeader>
      )}

      {mobile && (
        <MobileHeader>
          <div className="header_left">
            {/* <img
              className="logo"
              src="https://epn.net/storage/2024/02/02/6654840424c66d6d740b54e36cbe5410845e4647.png"
              loading="lazy"
              alt=""
            ></img> */}
          </div>

          <div className="header_right">
            <UserDropdownContainer>
              <UserProfile
                onClick={() => setShowUserDropdown(!showUserDropDown)}
              >
                <p>{String(userData.email).slice(0, 2)}</p>
              </UserProfile>
              {showUserDropDown && (
                <ClickAwayListener
                  onClickAway={() => setShowUserDropdown(!showUserDropDown)}
                >
                  <UserDropdown>
                    <div className="drop_section user">
                      <div className="user_wrapper">
                        <UserProfile
                          onClick={() => setShowUserDropdown(!showUserDropDown)}
                        >
                          <p>{String(userData.email).slice(0, 2)}</p>
                        </UserProfile>
                        <div>
                          <p className="user_email">{userData.email}</p>
                          <p className="user_level">
                            Account level - {userData.accountType}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="drop_divider" />

                    <div className="drop_section">
                      {/* first */}
                      {firstItems.map((item, index) => (
                        <span
                          key={index}
                          className="drop_item"
                          onClick={() => navigate(item.link)}
                        >
                          <img src={item.icon} alt={item.title} />
                          <p>{item.title}</p>
                        </span>
                      ))}
                    </div>

                    <div className="drop_divider" />

                    <div className="drop_section">
                      {/* first */}
                      {secondItems.map((item, index) => (
                        <span
                          key={index}
                          className="drop_item"
                          onClick={() => navigate(item.link)}
                        >
                          <img src={item.icon} alt={item.title} />
                          <p>{item.title}</p>
                        </span>
                      ))}
                    </div>

                    <div className="drop_divider" />

                    <div className="drop_section">
                      {/* first */}
                      {thirdItems.map((item, index) => (
                        <span
                          key={index}
                          className="drop_item"
                          onClick={() => navigate("/login")}
                        >
                          <img src={item.icon} alt={item.title} />
                          <p>{item.title}</p>
                        </span>
                      ))}
                    </div>
                  </UserDropdown>
                </ClickAwayListener>
              )}
            </UserDropdownContainer>

            <img
              src="./assets/icons/header/hamburger.svg"
              alt=""
              className="hamburger"
              onClick={() => setSidebarHide(!sidebarHide)}
            />
          </div>
        </MobileHeader>
      )}
    </>
  );
};

const DesktopHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 56px;
  padding-top: 4px;
  padding: 0px 24px;
  box-sizing: border-box;
  align-items: center;

  .header_left {
    display: flex;
    gap: 24px;
    align-items: center;
  }

  .header_left .logo {
    max-width: 100%;
    max-height: 100%;
    height: 20px;
  }

  .header_left .hamburger {
    height: 40px;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 100%;
    cursor: pointer;
  }

  .header_left .hamburger:hover {
    background: rgba(243, 243, 243, 1);
  }
`;

const MobileHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 56px;
  padding-top: 4px;
  padding: 0px 24px;
  box-sizing: border-box;
  align-items: center;

  .header_left {
    display: flex;
    gap: 24px;
    align-items: center;
  }

  .header_left .logo {
    max-width: 100%;
    max-height: 100%;
    height: 20px;
  }

  .header_right {
    display: flex;
    gap: 12px;
    align-items: center;
  }

  .header_right .hamburger {
    height: 40px;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 100%;
    cursor: pointer;
  }

  .header_right .hamburger:hover {
    background: rgba(243, 243, 243, 1);
  }
`;

const QuickOverview = styled.div`
  display: flex;
  align-items: center;
  gap: 48px;

  .left,
  .right {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .left .title {
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
  }

  .left .amount {
    line-height: 40px;
    font-weight: 700;
    line-height: 1;
    font-size: 20px;
  }

  .left .amount span {
    color: #94a3b8;
    font-size: 14px;
    font-weight: 400;
  }

  .right .title {
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
  }

  .right .account_type {
    font-size: 20px;
    line-height: 28px;
    font-weight: 700;
  }
`;

const UserDropdownContainer = styled.div`
  position: relative;
`;

const UserDropdown = styled.div`
  position: absolute;
  min-width: 314px;
  position: absolute;
  z-index: 40;
  border-radius: 4px;
  background: #fff;
  max-height: 384px;
  box-shadow: 0 3px 18px rgba(0, 0, 0, 0.075), 3px 15px 25px rgba(0, 0, 0, 0.05);
  /* padding: 6px 0; */
  min-width: (314px, 100%);
  right: 0;

  @media screen and (max-width: 768px) {
    & {
      right: -30px;
    }
  }

  .drop_section {
    position: relative;
    /* padding: 8px 16px; */
  }

  .drop_divider {
    background: #e5e5e5;
    height: 1px;
    width: 100%;
    margin-bottom: 6px;
    margin-top: 6px;
  }

  .drop_item {
    position: relative;
    padding: 8px 16px;
    display: grid;
    grid-template-columns: 24px auto;
    align-items: center;
    gap: 14px;
    font-weight: 400;
    font-size: 14px;
    height: 40px;
    cursor: pointer;
    box-sizing: border-box;
  }

  .drop_item:hover {
    background: #f3f3f3;
  }

  .user_wrapper {
    display: flex;
    gap: 14px;
    align-items: center;
    display: flex;
    align-items: flex-start;
    gap: 16px;
    width: 100%;
    padding: 16px 16px 8px 8px;
  }

  .user_wrapper .user_email {
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 230px;
    white-space: nowrap;
  }

  .user_wrapper .user_level {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
    color: #1968e5;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    white-space: nowrap;
    font-size: 14px;
    margin-top: 8px;
  }
`;

const UserProfile = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  position: relative;
  container-type: inline-size;
  cursor: pointer;
  margin-left: 7px;
  border: 1px solid #b3261e;
  display: grid;
  place-content: center;
  text-transform: uppercase;
  font-weight: 700;
  color: #1968e5;
  box-sizing: border-box;
`;

export default Header;
