import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { context } from "../../../context/context";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../../firebase/firebase";
import CircularLoader from "../../../components/styled/loaders/CircularLoader";

const Overview = () => {
  const { userData } = useContext(context);

  const { id } = userData;

  const [isLoading, setIsLoading] = useState(true);

  const [history, setHistory] = useState([]);
  const [cards, setCards] = useState([]);
  const [successHistories, setSuccessHistories] = useState([]);
  const [failedHistories, setFailedHistories] = useState([]);

  useEffect(() => {
    let historiesList = [];
    let cardList = [];
    let success = [];
    let fail = [];

    async function getHistory() {
      const q = query(collection(db, "history"), where("user", "==", id));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        historiesList.push(doc.data());
      });

      historiesList.forEach((history) => {
        console.log(history);
        if (history.status === "approved") {
          success.push(history);
          // successHistories.push(history);
        }

        if (history.status === "declined") {
          fail.push(history);
        }
      });

      setFailedHistories(fail);
      setSuccessHistories(success);
      setHistory(historiesList);
      getCards();
    }

    async function getCards() {
      const q = query(collection(db, "cards"), where("user", "==", id));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        cardList.push(doc.data());
      });
      setCards(cardList);
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    }

    if (id) {
      getHistory();
    }
  }, [id]);

  //

  const [totalCardBalance, setTotalCardBalance] = useState(0);

  useEffect(() => {
    function getTotalCardsBalance() {
      let TotalBalance = 0;
      for (let i = 0; i < cards?.length; i++) {
        let balance = Number(cards[i].balance);
        console.log(balance);
        TotalBalance = balance + TotalBalance;
      }
      setTotalCardBalance(TotalBalance);
    }

    if (cards) {
      console.log("call balance");
      getTotalCardsBalance();
    }
  }, [cards]);

  return (
    <>
      {!isLoading && (
        <OverviewWrapper>
          <div className="section">
            <h2 className="section_title">List of accounts</h2>
            <ListOfAccounts>
              <div className="left">
                {/* main account box */}
                <LongBox>
                  <div className="box_left">
                    <div className="icon_wrapper">
                      <img src="./assets/icons/accounts/main.svg" />
                    </div>

                    <div className="box_info">
                      <h3 className="title">Main account</h3>
                      <p className="text">Replenishment history</p>
                    </div>
                  </div>

                  <h3 className="balance">
                    $
                    {String(Number(userData?.totalBalance).toFixed(2)).slice(
                      0,
                      String(Number(userData?.totalBalance).toFixed(2)).indexOf(
                        "."
                      )
                    )}
                    <span>
                      {String(Number(userData?.totalBalance).toFixed(2)).slice(
                        String(
                          Number(userData?.totalBalance).toFixed(2)
                        ).indexOf(".")
                      )}
                    </span>
                  </h3>
                </LongBox>

                {/* personal cards box */}

                <LongBox>
                  <div className="box_left">
                    <div className="icon_wrapper">
                      <img src="./assets/icons/accounts/cards.svg" />
                    </div>

                    <div className="box_info">
                      <h3 className="title">Personal cards</h3>
                      <p className="text">Cards: {cards?.length}</p>
                    </div>
                  </div>

                  <h3 className="balance">
                    $
                    {String(Number(totalCardBalance).toFixed(2)).slice(
                      0,
                      String(Number(totalCardBalance).toFixed(2)).indexOf(".")
                    )}
                    <span>
                      {String(Number(totalCardBalance).toFixed(2)).slice(
                        String(Number(totalCardBalance).toFixed(2)).indexOf(".")
                      )}
                    </span>
                  </h3>
                </LongBox>
              </div>

              <div className="right">
                <DeclineBox>
                  <div className="box_wrapper">
                    <div className="box_main">
                      <h5 className="title">Decline Rate</h5>
                      <h1 className="percent">
                        {Number(
                          (failedHistories?.length / history?.length) * 100
                        ).toFixed(0)}
                        %
                      </h1>
                    </div>

                    <div className="statistics">
                      <p className="title">
                        Total transactions:{" "}
                        <span className="value">{history.length}</span>
                      </p>
                      <p className="title">
                        Successful transactions:{" "}
                        <span className="value">{successHistories.length}</span>
                      </p>
                      <p className="title">
                        Declined transactions:{" "}
                        <span className="value">{failedHistories.length}</span>
                      </p>
                    </div>
                  </div>
                </DeclineBox>
              </div>
            </ListOfAccounts>
          </div>

          {/* <div className="section">
        <h2 className="section_title">Teams</h2>
        <Teams>
          <div className="teams_wrapper">
            <div className="teams_content">
              <div className="team">
                <div className="left">
                  <div className="team_badge">
                    <img
                      src="./assets/icons/accounts/team.svg"
                      style={{ width: "24px" }}
                    />
                  </div>

                  <div className="team_info">
                    <p className="team_name">Your team</p>
                    <p className="team_summary">
                      Members 0:{" "}
                      <span>You haven't invited any members yet </span>
                    </p>
                  </div>
                </div>

                <div className="team_balance">
                  <p className="balance">
                    $0<span>.00</span>
                  </p>
                  <p className="balance_label">Available balance</p>
                </div>
              </div>
            </div>
          </div>
        </Teams>
      </div> */}
        </OverviewWrapper>
      )}

      {isLoading && (
        <OverviewWrapper>
          <CircularLoader bg="#cccccc" size="20" color="#ffffff" />
        </OverviewWrapper>
      )}
    </>
  );
};

const OverviewWrapper = styled.div`
  .section {
    margin-bottom: 40px;
  }

  h2.section_title {
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
    border-width: 0px;
    border-style: solid;
    border-color: rgb(230, 230, 230);
    box-sizing: border-box;
    margin: 0px;
    font-size: 24px;
    line-height: 28px;
    font-weight: 500;
    margin-bottom: 20px;
  }
`;

const ListOfAccounts = styled.div`
  display: flex;
  gap: 1.5rem;

  .left {
    display: grid;
    gap: 12px;
    max-width: 720px;
    width: 100%;
    box-sizing: border-box;
  }

  @media screen and (max-width: 768px) {
    & {
      flex-direction: column;
    }

    .left {
      max-width: unset;
      width: 100%;
    }
  }
`;

const LongBox = styled.div`
  box-sizing: border-box;
  padding: 24px;
  border-radius: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #f7f9fb;
  width: 100%;
  box-sizing: border-box;

  .box_left {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  .icon_wrapper {
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
    border-width: 0px;
    border-style: solid;
    box-sizing: border-box;
    border-radius: 50%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 46px;
    height: 46px;
    background-color: #1968e5;
  }

  h3.title {
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
    margin-bottom: 4px;
  }

  p.text {
    font-size: 12px;
    line-height: 14px;
    font-weight: 400;
    color: #828282;
  }

  h3.balance {
    box-sizing: border-box;
    font-weight: bolder;
    margin: 0px;
    font-size: 32px;
    line-height: 38px;
  }

  h3.balance span {
    color: #94a3b8;
    font-size: 16px;
  }
`;

const DeclineBox = styled.div`
  padding: 24px;
  border-radius: 16px;
  width: 330px;
  border: none;
  background: #f7f9fb;
  box-sizing: border-box;

  @media screen and (max-width: 768px) {
    & {
      width: 100%;
      max-width: unset;
    }
  }

  .box_main {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .box_main h5 {
    font-size: 20px;
    line-height: 1;
    font-weight: 500;
  }

  .box_main h1 {
    font-size: 40px;
    line-height: 54px;
    font-weight: 700;
    color: #1f9649;
  }

  .box_wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 14px;
  }

  .statistics {
    gap: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  .statistics .title {
    margin: 0px;
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    color: #828282;
  }

  .statistics .value {
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    color: #333742;
  }
`;

const Teams = styled.div`
  /* width: 80%; */
  /* display: none; */

  .teams_list {
    gap: 24px;
    display: flex;
    flex-direction: column;
  }

  .teams_wrapper {
    padding: 24px;
    border-radius: 16px;
    padding-top: 10px;
    padding-bottom: 10px;
    border: none;
    background: #f7f9fb;
    display: flex;
    align-items: center;
    box-sizing: border-box;
  }

  /* .teams_content {
    border-spacing: 0px;
    border-radius: inherit;
    width: 100%;
  } */

  .team {
    max-width: fit-content;
    display: flex;
    gap: 72px;
    align-items: center;
    box-sizing: border-box;
  }

  .team .left {
    display: flex;
    gap: 24px;
    align-items: center;
  }

  .team_badge {
    box-sizing: border-box;
    border-radius: 50%;
    background: #1968e5;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    line-height: 1;
    color: #1968e5;
    width: 46px;
    height: 46px;
  }

  .team_info p.team_name {
    color: #111111;
    font-weight: 700;
    font-size: 20px;
    line-height: 1;
  }

  .team_info .team_summary {
    font-size: 12px;
    line-height: 14px;
    margin-top: 7px;
    color: #1968e5;
    font-weight: 500;
  }

  .team_info .team_summary span {
    color: #686c77;
    font-weight: 400;
  }

  .team_balance {
  }

  .team_balance p.balance {
    line-height: 1;
    font-weight: 700;
    font-size: 20px;
  }

  .team_balance p.balance span {
    color: #94a3b8;
    font-size: 14px;
  }

  .balance_label {
    color: #828282;
    line-height: 1;
    font-size: 12px;
    margin-top: 7px;
    font-weight: 500;
  }

  @media screen and (max-width: 768px) {
    .team {
      box-sizing: border-box;
      max-width: 80vw;
      width: 100%;
      white-space: nowrap;
      overflow: scroll;
      gap: 24px;
    }

    .teams_wrapper {
      padding: 12px;
      box-sizing: border-box;
    }
  }
`;

export default Overview;
