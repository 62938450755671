import styled from "styled-components";

const LightButton = ({ text, action }) => {
  return (
    <LightButtonWrapper onClick={action}>
      <p>{text}</p>
    </LightButtonWrapper>
  );
};

const LightButtonWrapper = styled.button`
  box-sizing: border-box;
  display: flex;
  outline: none;
  gap: 8px;
  background-color: rgba(17, 17, 17, 0.05);
  border-radius: 8px;
  padding: 8px 12px;
  font-size: 14px;
  border: none;
  align-items: center;
  line-height: 1;
  text-align: center;
  font-weight: 500;
  height: 36px;
  cursor: pointer;
`;

export default LightButton;
