import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDmvkZagD0gABGbseceJbFUvQCVEiVrrK4",
  authDomain: "cards-9a58e.firebaseapp.com",
  projectId: "cards-9a58e",
  storageBucket: "cards-9a58e.appspot.com",
  messagingSenderId: "112032904354",
  appId: "1:112032904354:web:4a04ac94fdeea042d753fb",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
