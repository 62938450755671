import styled from "styled-components";
import LightButton from "../components/styled/LightButton";
import { useContext, useEffect, useState } from "react";
import Toast from "../hooks/Toast";
import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { db } from "../firebase/firebase";
import CircularLoader from "../components/styled/loaders/CircularLoader";
import { Skeleton } from "@mui/material";
import { getTime } from "../utils/utils";
import { context } from "../context/context";

const TransactionHistory = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [history, setHistory] = useState([]);
  const [historyList, setHistoryList] = useState([]);

  const { userData } = useContext(context);

  const { id } = userData;

  useEffect(() => {
    const historiesList = [];

    async function getHistory() {
      const q = query(collection(db, "history"), where("user", "==", id));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        historiesList.push(doc.data());
        console.log(doc.data());
      });
      //   console.log(userList);
      setHistory(historiesList);
      setHistoryList(historiesList);
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    }

    getHistory();
  }, []);

  function handleSearchHistories(e) {
    const { value } = e.target;
    let filteredHistories;

    if (value) {
      filteredHistories = historyList.filter((histories) =>
        // users.firstname.toLowerCase().includes(value.toLowerCase()) ||
        histories.text.toLowerCase().includes(value.toLowerCase())
      );
      setHistory(filteredHistories);
    } else {
      setHistory(historyList);
    }
  }

  // toast
  const [openToast, setOpenToast] = useState(false);
  const [toastMessage, setToastMessage] = useState(" ");
  const [toastType, setToastType] = useState(undefined);

  return (
    <TableWrapper className="scrollbar-hide">
      {openToast && (
        <Toast
          open={{ openToast, setOpenToast }}
          message={toastMessage}
          type={toastType}
        />
      )}
      <Search>
        <div className="search">
          <img src="./assets/icons/misc/search.svg" />
          <div className="search_wrapper">
            <div className="search_container">
              <input
                maxLength="40"
                placeholder="Search for history"
                onChange={handleSearchHistories}
              />

              {/* <img src="./assets/icons/misc/close.svg" /> */}
            </div>
          </div>
        </div>
      </Search>

      {isLoading && (
        <TransactionHistoryWrapper>
          <thead>
            <tr>
              <th>
                {" "}
                <Skeleton
                  variant="rounded"
                  height={18}
                  width={150}
                  sx={{
                    backgroundColor: "#0000001f",
                    borderRadius: "32px",
                  }}
                />
              </th>
              <th>
                {" "}
                <Skeleton
                  variant="rounded"
                  height={18}
                  width={100}
                  sx={{
                    backgroundColor: "#0000001f",
                    borderRadius: "32px",
                  }}
                />
              </th>
              <th>
                <Skeleton
                  variant="rounded"
                  height={18}
                  width={120}
                  sx={{
                    backgroundColor: "#0000001f",
                    borderRadius: "32px",
                  }}
                />
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <Skeleton
                  variant="rounded"
                  height={18}
                  width={150}
                  sx={{
                    backgroundColor: "#0000001f",
                    borderRadius: "32px",
                  }}
                />
              </td>
              <td>
                <Skeleton
                  variant="rounded"
                  height={18}
                  width={100}
                  sx={{
                    backgroundColor: "#0000001f",
                    borderRadius: "32px",
                  }}
                />
              </td>
              <td>
                <Skeleton
                  variant="rounded"
                  height={18}
                  width={120}
                  sx={{
                    backgroundColor: "#0000001f",
                    borderRadius: "32px",
                  }}
                />
              </td>
            </tr>
          </tbody>
        </TransactionHistoryWrapper>
      )}

      {!isLoading && (
        <TransactionHistoryWrapper>
          <thead>
            <tr>
              <th>Date</th>
              <th>Text</th>
              <th>Amount</th>
            </tr>
          </thead>

          {history.length > 0 && (
            <tbody>
              {history.map((history, index) => (
                <tr key={index}>
                  <td>{getTime(new Date() - history.date.toDate())}</td>
                  <td>{history.text}</td>
                  <td>
                    <p>${history.amount}</p>
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </TransactionHistoryWrapper>
      )}

      {!isLoading && !history.length > 0 && (
        <p
          style={{
            textAlign: "center",
            margin: "0 auto",
            padding: "16px",
            padddingTop: "0",
            width: "100%",
          }}
        >
          No transactions found
        </p>
      )}
    </TableWrapper>
  );
};

const Search = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 0px;
  position: relative;
  width: 100%;
  height: 32px;
  border-bottom: 1px solid #d9d9d9;
  border-radius: 4px 4px 0px 0px;
  border-color: #686c77;

  .search {
    display: flex;
    align-items: center;
    align-self: stretch;
    gap: 12px;
    flex-grow: 1;
    max-width: 100%;
  }

  .search_wrapper {
    flex-grow: 1;
    overflow: hidden;
  }

  .search_container {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    gap: 12px;
    width: 100%;
    position: relative;
  }

  input {
    margin: 0px;
    padding: 0px;
    font-family: inherit;
    font-weight: inherit;
    line-height: inherit;
    color: inherit;
    flex-grow: 1;
    background: transparent;
    overflow: hidden;
    white-space: nowrap;
    min-width: 1%;
    width: 100%;
    text-overflow: ellipsis;
    max-width: 100%;
    font-size: 14px;
    outline: none;
    border: none;
  }

  .select_buttons {
    display: flex;
    gap: 24px;
  }
`;

const TableWrapper = styled.div`
  max-width: 90vw;
  overflow-y: auto;
  white-space: nowrap;
  display: grid;
  gap: 24px;

  p.enabled {
    color: green;
  }

  p.disabled {
    color: red;
  }
`;

const TransactionHistoryWrapper = styled.table`
  padding-bottom: 24px;

  thead tr th {
    background: #ecf0f5;
    padding: 0px 16px;
    height: 52px;
    font-weight: 500;
    color: #070707;
    vertical-align: middle;
    text-align: start;
  }

  thead tr th:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  thead tr th:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  tbody tr td {
    padding: 0px 16px;
    height: 52px;
    font-weight: 400;
    color: #070707;
    vertical-align: middle;
    text-align: start;
  }

  tbody tr {
    cursor: pointer;
  }

  tbody tr:hover {
    background: #ecf0f567;
  }
`;

const UserProfile = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;

  .icon {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    position: relative;
    cursor: pointer;
    border: 1px solid #b3261e;
    display: grid;
    place-content: center;
    text-transform: uppercase;
    font-weight: 700;
    color: #1968e5;
    box-sizing: border-box;
  }
`;

export default TransactionHistory;
