export function getTime(milliSeconds) {
  const seconds = milliSeconds / 1000;
  const minutes = seconds / 60;
  const hours = minutes / 60;
  const days = hours / 24;
  const weeks = days / 7;

  if (seconds > 60 && minutes < 60) {
    return (
      Math.floor(minutes) +
      (Math.floor(minutes) > 1 ? " minutes ago" : " minute ago")
    );
  }

  if (seconds > 60 && minutes > 60 && hours < 24) {
    return (
      Math.floor(hours) + (Math.floor(hours) > 1 ? " hours ago" : " hour ago")
    );
  }

  if (seconds > 60 && minutes > 60 && hours > 24 && days < 7) {
    return Math.floor(days) + (Math.floor(days) > 1 ? " days ago" : " day ago");
  }

  if (seconds > 60 && minutes > 60 && hours > 24 && days > 7) {
    return (
      Math.floor(weeks) + (Math.floor(weeks) > 1 ? " weeks ago" : " week ago")
    );
  }

  return (
    Math.floor(seconds) +
    (Math.floor(seconds) > 1 ? " seconds ago" : " seconds ago")
  );
}

export function makeid(length) {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}
