import styled from "styled-components";

const BackgroundAmountDropDownWithSmallLabel = ({
  title,
  label,
  account_balance,
  decimals,
}) => {
  return (
    <Wrapper>
      <h3 className="title">{title}</h3>
      <div className="input_wrapper">
        <div className="input_block">
          <p className="label">{label}</p>
          <p className="amount">
            ${account_balance}
            <span>{decimals}</span>
          </p>

          {/* <input className="amount" placeholder="1000" /> */}
        </div>

        <img src="./assets/icons/misc/dropdown.svg" />
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 20px;
  max-width: 720px;

  h3.title {
    margin: 0px;
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
  }

  .input_wrapper {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    background: transparent;
    padding: 10px 12px;
    border-radius: 8px;
    border-width: 1px;
    border-style: solid;
    border-color: #f3f3f3;
    background-color: #f3f3f3;
    cursor: pointer;
    box-sizing: border-box;
  }

  .input_block {
    box-sizing: border-box;
    flex-grow: 1;
    overflow: hidden;
    max-width: 100%;
  }

  p.label {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
    transition: transform 0.1s ease 0s;
    margin-top: 4px;
    line-height: 1;
    color: #686c77;
    font-size: 14px;
  }

  p.amount {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
    transition: transform 0.1s ease 0s;
    margin-top: 4px;
    line-height: 1;
    font-size: 15px;
  }

  p.amount span {
    color: #686c77;
  }

  .caption {
    display: flex;
    gap: 4px;
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
  }

  .caption span {
    color: rgba(21, 128, 61, 1);
  }
`;

export default BackgroundAmountDropDownWithSmallLabel;
