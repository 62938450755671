import React from "react";
import styled from "styled-components";
import AmountBoxWithSmallLabel from "../../../components/styled/AmountBoxWithSmallLabel";
import BackgroundAmountDropDownWithSmallLabel from "../../../components/styled/BackgroundAmountDropDownWithSmallLabel";
import BackgroundAmountDropdown from "../../../components/styled/BackgroundAmountDropdown";
import AmountBoxWithMaxButton from "../../../components/styled/AmountBoxWithMaxButton";
import DepositAddressBox from "../../../components/styled/DepositAddressBox";
import PrimaryButton from "../../../components/styled/PrimaryButton";
import TextBox from "../../../components/styled/TextBox";

const SendToUser = () => {
  return (
    <SendToUserWrapper>
      {/* title /top */}
      <div className="title_wrapper">
        <h3>Send money to another user</h3>
        <p>Enter user's email and amount required for transfer</p>
      </div>

      <div className="section">
        <TextBox title="User Email" label="Email" />

        <AmountBoxWithMaxButton title="Transfer amount" />
      </div>

      <PrimaryButton disabled={true} text="Transfer $0.00" />
    </SendToUserWrapper>
  );
};

const SendToUserWrapper = styled.div`
  .section {
    margin-bottom: 40px;
    display: grid;
    gap: 24px;
  }

  h2.section_title {
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
    border-width: 0px;
    border-style: solid;
    border-color: rgb(230, 230, 230);
    box-sizing: border-box;
    margin: 0px;
    font-size: 24px;
    line-height: 28px;
    font-weight: 500;
    margin-bottom: 20px;
  }

  .title_wrapper {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    gap: 8px;
    color: #333742;
    margin-bottom: 32px;
  }

  .title_wrapper h3 {
    margin: 0px;
    font-size: 20px;
    line-height: 26px;
    font-weight: 700;
    color: #111111;
  }

  .title_wrapper p {
    margin: 0px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
  }

  .title_wrapper p span {
    box-sizing: border-box;
    font-weight: 500;
  }
`;

export default SendToUser;
