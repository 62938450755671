import styled from "styled-components";
import Sidebar from "../components/general/Sidebar";
import Header from "../components/general/Header";
import { useState } from "react";
import General from "./inners/settings/General";
import SafetyAndSecurity from "./inners/settings/SafetyAndSecurity";
import Verification from "./inners/settings/Verification";

const SettingsPage = () => {
  const [activeTab, setActiveTab] = useState("Settings");
  const [sidebarHide, setSidebarHide] = useState(true);
  const [sidebarCollapse, setSidebarCollapse] = useState(false);

  const pageOptions = ["General"];

  const [activePage, setActivePage] = useState("General");

  return (
    <PageContainer>
      <Header
        collapse={{ sidebarCollapse, setSidebarCollapse }}
        hide={{ sidebarHide, setSidebarHide }}
      />
      <PageWrapper>
        <Sidebar
          tab={{ activeTab, setActiveTab }}
          hide={{ sidebarHide, setSidebarHide }}
          collapse={{ sidebarCollapse, setSidebarCollapse }}
        />
        <Main>
          <div className="page_content">
            {/* header */}
            <div className="page_header">
              <p className="page_title">{activePage}</p>

              <PageSwitcher>
                {pageOptions.map((option, index) => (
                  <span
                    key={index}
                    onClick={() => setActivePage(option)}
                    className={
                      option === activePage ? "switch active" : "switch"
                    }
                  >
                    {option}
                  </span>
                ))}
              </PageSwitcher>
              <div className="ruler" />
            </div>

            {activePage === "General" && <General />}
            {activePage === "Safety and security" && <SafetyAndSecurity />}
            {activePage === "Verification" && <Verification />}
          </div>
        </Main>
      </PageWrapper>
    </PageContainer>
  );
};

const PageContainer = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
`;

const PageWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: grid;
  grid-template-columns: max-content auto;

  @media screen and (max-width: 768px) {
    & {
      grid-template-columns: auto auto;
    }
  }
`;

const Main = styled.div`
  background-color: white;
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
  padding: 0px 30px;
  box-sizing: border-box;

  .page_content {
    width: 100%;
    padding: 24px 0;
    margin: 0 auto;
    max-width: 1074px;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .page_content .page_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    position: relative;
    /* padding: 0px !important; */
  }

  .ruler {
    width: 100%;
    height: 1px;
    background-color: rgb(230, 230, 230);
    position: absolute;
    box-sizing: border-box;
    margin-top: 48.7px;
  }

  .page_content .page_title {
    font-size: 32px;
    font-weight: 800;
    line-height: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    white-space: nowrap;
    font-family: Roboto;
    margin-bottom: 30px;
  }

  .page_content .info_cards {
    display: flex;
    gap: 24px;
  }

  .page_content .statistics_cards {
    display: flex;
    gap: 24px;
  }

  @media screen and (max-width: 768px) {
    & {
      width: 100% !important;
    }

    .page_content {
      max-width: unset;
      width: 100%;
      box-sizing: border-box;
    }
  }

  @media screen and (max-width: 1200px) {
    .page_content .page_title {
      display: none;
    }
  }
`;

const PageSwitcher = styled.div`
  display: flex;
  gap: 32px;
  max-width: 90vw;
  overflow-x: auto;
  /* padding: 0px !important; */
  /* padding: 0px; */

  .switch {
    position: relative;
    color: #828282;
    --animationDuration: 0.5s;
    /* min-height: 48px; */
    font-weight: 400;
    white-space: nowrap;
    cursor: pointer;
  }

  .switch.active {
    color: #333742;
    border-bottom: 1px solid red;
    padding-bottom: 32px;
    z-index: 10;
  }

  .switch.active::after {
    content: "";
    position: absolute;
    height: 2px;
    bottom: 0px;
    width: 100%;
    left: 0px;
    transition: background-color 0.5s ease, transform, 0.5s ease;
    transform-origin: 50% 50%;
    transform: scaleX(0) translateY(50%);
    border-radius: 2px;
    transform: scaleX(1) translateY(50%);
    background-color: #1968e5;
  }
`;

export default SettingsPage;
