import styled from "styled-components";
import PrimaryButton from "./styled/PrimaryButton";
import LightButton from "./styled/LightButton";
import { useContext, useEffect, useRef, useState } from "react";
import {
  doc,
  increment,
  onSnapshot,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../firebase/firebase";
import { context } from "../context/context";
import Toast from "../hooks/Toast";
import CircularLoader from "./styled/loaders/CircularLoader";

const IssueCard = ({ issue }) => {
  // toast
  const [openToast, setOpenToast] = useState(false);
  const [toastMessage, setToastMessage] = useState(" ");
  const [toastType, setToastType] = useState(undefined);

  const { issueCard, setIssueCard } = issue;
  const [purchaseCard, setPurchaseCard] = useState(false);

  const { userData } = useContext(context);

  const { id } = userData;

  const [details, setDetails] = useState({});

  const tabs = ["Universal", "Advertising"];

  const [activeTab, setActiveTab] = useState("Universal");

  const [isLoading, setIsLoading] = useState(true);

  const [cards, setCards] = useState([]);

  const [universalCards, setUniversalCards] = useState([]);
  const [advertisingCards, setAdvertisingCards] = useState([]);

  useEffect(() => {
    async function getProducts() {
      let universalList = [];
      let advertisingList = [];
      onSnapshot(doc(db, "admin", "products"), (doc) => {
        const data = doc.data();
        setCards(Object.values(data));

        Object.values(data).forEach((data) => {
          if (data.label === "Universal") {
            universalList.push(data);
          }

          if (data.label === "Advertising") {
            advertisingList.push(data);
          }
        });

        setUniversalCards(universalList);
        setAdvertisingCards(advertisingList);
      });

      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    }

    getProducts();
  }, []);

  function handlePurchase(card) {
    setDetails(card);
    setPurchaseCard(true);
  }

  const [amount, setAmount] = useState(details.minimum || 100);
  const [amountError, setAmountError] = useState(false);

  // name
  const [name, setName] = useState("");
  function handleName(e) {
    const { value } = e.target;

    if (value) {
      setName(value);
    } else {
      setName("");
    }
  }

  const amountRef = useRef();

  function handleMaxButton() {
    if (amountRef) {
      amountRef.current.value = userData.totalBalance;
    }

    setAmountError(false);
    setAmount(userData.totalBalance);
  }

  function handleAmount(e) {
    const { value } = e.target;

    if (Number(value)) {
      if (Number(value) > Number(userData.totalBalance)) {
        setAmountError(true);
        setAmount(Number(value));
      } else {
        setAmountError(false);
        setAmount(Number(value));
      }
    } else {
      setAmount("");
    }
  }

  const [isBuying, setIsBuying] = useState(false);

  function handlePurchaseCard() {
    setIsBuying(true);
    decrementUser();
  }

  //   decrement
  async function decrementUser() {
    const profile = doc(db, "users", id);
    await updateDoc(profile, {
      totalBalance: increment(-Number(total)),
    })
      .then(() => {
        createRecord();
      })
      .catch((error) => {
        setIsBuying(false);
        setToastType("error");
        setToastMessage("Failed to purchase. Please try again later");
        setOpenToast(true);
      });
  }

  async function createRecord() {
    const randomOne = Math.floor(Math.random(100, 999) * 1000 + 1);
    const randomTwo = Math.floor(Math.random(100, 999) * 100 + 1);

    const str =
      id.substring(0, 4) + randomOne.toString() + randomTwo.toString();

    const document = doc(db, "cards", str);
    await setDoc(document, {
      ref: str,
      user: id,
      balance: Number(amount),
      date: serverTimestamp(),
      status: "approved",
      label: details.label,
      number: details.cardNumber,
      cvc: details.cvc,
      expiry: details.expiry,
      type: details.type,
      name: name || null,
      userRef: {
        photo: userData.photoURL ? userData.photoURL : null,
        email: userData.email,
      },
    })
      .then(() => {
        createHistory(str);
      })
      .catch((error) => {
        // console.log(error)
        setIsBuying(false);
        setToastType("error");
        setToastMessage("Failed to approve. Please try again later");
        setOpenToast(true);
      });
  }

  async function createHistory(ref) {
    await setDoc(doc(db, "history", ref), {
      ref,
      user: id,
      amount: Number(amount),
      text: "Purchased and topped up an " + details.label + " card",
      date: serverTimestamp(),
      status: "approved",
      userRef: {
        photo: userData.photoURL ? userData.photoURL : null,
        email: userData.email,
      },
    })
      .then(() => {
        setIsBuying(false);
        setToastType("success");
        setToastMessage("Purchased successfully");
        setOpenToast(true);
      })
      .catch((error) => {
        console.log(error);
        setIsBuying(false);
        setToastType("error");
        setToastMessage("Failed to deposit. Please try again later.");
        setOpenToast(true);
      });
  }

  const [total, setTotal] = useState(0);
  const [totsError, setTotsError] = useState(false);

  useEffect(() => {
    if (
      !isNaN(Number(details.price)) &&
      !isNaN(Number(amount)) &&
      !isNaN(Number(details.commission))
    ) {
      let tots =
        Number(details.price) +
        Number(amount) +
        Number((details.commission / 100) * Number(amount));

      if (Number(tots) > Number(userData.totalBalance)) {
        setTotsError(true);
      } else {
        setTotsError(false);
      }
      setTotal(tots);
    } else {
      setTotal(0);
    }
  }, [details, amount]);

  return (
    <>
      {openToast && (
        <Toast
          open={{ openToast, setOpenToast }}
          message={toastMessage}
          type={toastType}
        />
      )}

      {!purchaseCard && (
        <IssueCardWrapper>
          <div className="back_button_wrapper">
            <button onClick={() => setIssueCard(!issueCard)}>
              <img src="./assets/icons/misc/back-arrow.svg" />
            </button>
            <p>Cards</p>
          </div>
          <p className="page_title">Card issue</p>

          <div className="section mt-60">
            <ReferralCard>
              <div className="left">
                <div className="card_title">Become the owner of a card</div>
                <div className="card_text">
                  A wide selection of cards to suit everyone's needs
                </div>
                <div className="card_stats">
                  <span className="stat">
                    <p>$0</p>
                    <p className="link">Card issue</p>
                  </span>

                  <span className="stat">
                    <p>from $10/month</p>
                    <p>Starting next month</p>
                  </span>

                  <span className="stat">
                    <p>from 5%</p>
                    <p>Commission for replenishment</p>
                  </span>
                </div>
              </div>

              <img className="itemImg" src="./assets/images/card.png" />
            </ReferralCard>

            {/* page switcher */}
            <PageSwitcher>
              {tabs.map((tab, index) => (
                <button
                  className={tab === activeTab ? "switch active" : "switch"}
                  key={index}
                  onClick={() => setActiveTab(tab)}
                >
                  {tab}
                </button>
              ))}
              {/* <button className="switch">Advertising</button> */}
            </PageSwitcher>

            {/* card_roller */}
            <CardRoller>
              {activeTab === "Universal" && (
                <div className="title">
                  Universals{" "}
                  <span className="badge">BINs: {universalCards?.length}</span>
                </div>
              )}

              {activeTab === "Advertising" && (
                <div className="title">
                  Advertising{" "}
                  <span className="badge">
                    BINs: {advertisingCards?.length}
                  </span>
                </div>
              )}

              {activeTab === "Universal" && (
                <div className="description">
                  Universal cards can be used to pay for any purchases on the
                  Internet (there are exceptions), but we do not recommend using
                  them to pay for advertising due to the low binding rate
                </div>
              )}

              {activeTab === "Advertising" && (
                <div className="description">
                  Ad-cards are designed and optimized to pay for ads. We
                  recommend choosing ad-BINs to work with, they will perform
                  better
                </div>
              )}

              {/* cards */}
              {cards.length > 0 && activeTab == "Universal" && (
                <div className="cards">
                  {universalCards.map((card, index) => (
                    <Card key={index}>
                      <div className="card_wrapper">
                        <div className="card_content">
                          <div className="title">{card.type}</div>
                          <div className="bin">
                            {" "}
                            {String(card.cardNumber).slice(0, 4)}{" "}
                            {String(card.cardNumber).slice(4)}
                          </div>
                          <button
                            className="dark_button"
                            onClick={() => handlePurchase(card)}
                          >
                            Buy
                          </button>
                        </div>
                        <div className="card_container">
                          <div className="universal_card">
                            <div className="universal_card_wrapper">
                              <div className="universal_container">
                                <div className="Universal_container_top">
                                  {String(card.cardNumber).slice(0, 4)}{" "}
                                  {String(card.cardNumber).slice(4)} **** ****
                                </div>
                                <div
                                  className="universal_container_bottom"
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <span>{card.label}</span>

                                  <img
                                    src="./assets/icons/misc/mastercard.svg"
                                    style={{ width: "24px" }}
                                  />
                                </div>
                              </div>
                              {/* <div className="locker_wrapper">
                        <div className="locker">
                          <svg
                            className="img"
                            width="65"
                            fill="none"
                            viewBox="0 0 65 64"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{
                              scrollbarWidth: "thin",
                              scrollbarColor: "transparent transparent",
                              borderWidth: "0px",
                              borderStyle: "solid",
                              borderColor: "rgb(230, 230, 230)",
                              boxSizing: "border-box",
                              display: "block",
                              verticalAlign: "middle",
                              width: "65px",
                              height: "auto",
                            }}
                          >
                            <path
                              d="M24.4999 22.6667H40.4999V17.3334C40.4999 15.1111 39.7222 13.2223 38.1666 11.6667C36.611 10.1111 34.7222 9.33336 32.4999 9.33336C30.2777 9.33336 28.3888 10.1111 26.8333 11.6667C25.2777 13.2223 24.4999 15.1111 24.4999 17.3334V22.6667ZM33.7307 57.3332H17.3205C15.9735 57.3332 14.8333 56.8666 13.9 55.9332C12.9667 54.9999 12.5 53.8597 12.5 52.5127V27.4872C12.5 26.1402 12.9667 25 13.9 24.0667C14.8333 23.1334 15.9735 22.6667 17.3205 22.6667H20.5V17.3334C20.5 14.0035 21.6675 11.1711 24.0025 8.83603C26.3376 6.50101 29.1701 5.3335 32.4999 5.3335C35.8298 5.3335 38.6623 6.50101 40.9973 8.83603C43.3324 11.1711 44.4999 14.0035 44.4999 17.3334V22.6667H47.6793C49.0264 22.6667 50.1665 23.1334 51.0999 24.0667C52.0332 25 52.4999 26.1402 52.4999 27.4872V30.8C51.9221 30.6017 51.3024 30.453 50.6409 30.3539C49.9794 30.2547 49.2657 30.2052 48.4999 30.2052V27.4872C48.4999 27.2479 48.423 27.0513 48.2691 26.8974C48.1153 26.7436 47.9187 26.6666 47.6793 26.6666H17.3205C17.0812 26.6666 16.8846 26.7436 16.7307 26.8974C16.5769 27.0513 16.4999 27.2479 16.4999 27.4872V52.5127C16.4999 52.752 16.5769 52.9486 16.7307 53.1025C16.8846 53.2564 17.0812 53.3333 17.3205 53.3333H31.7615C32.0145 54.1265 32.2846 54.8222 32.5718 55.4204C32.859 56.0187 33.2453 56.6563 33.7307 57.3332ZM47.9871 59.487C44.6572 59.487 41.8248 58.3195 39.4897 55.9845C37.1547 53.6495 35.9872 50.817 35.9872 47.4871C35.9872 44.1572 37.1547 41.3248 39.4897 38.9898C41.8248 36.6547 44.6572 35.4872 47.9871 35.4872C51.317 35.4872 54.1494 36.6547 56.4845 38.9898C58.8195 41.3248 59.987 44.1572 59.987 47.4871C59.987 50.817 58.8195 53.6495 56.4845 55.9845C54.1494 58.3195 51.317 59.487 47.9871 59.487ZM52.3871 53.5486L54.0486 51.8871L49.1665 47.005V39.641H46.8076V47.9692L52.3871 53.5486ZM16.4999 34.3595V53.3333V26.6666V34.3595Z"
                              fill="currentColor"
                              style={{
                                scrollbarWidth: "thin",
                                scrollbarColor: "transparent transparent",
                                borderWidth: "0px",
                                borderStyle: "solid",
                                borderColor: "rgb(230, 230, 230)",
                                boxSizing: "border-box",
                              }}
                            />
                          </svg>
                          Available at «Gold» level
                        </div>
                      </div> */}

                              {/* card bg */}
                              <img
                                className="bg"
                                src="./assets/cards/universal.svg"
                                style={{
                                  borderColor: "rgb(230, 230, 230)",
                                  scrollbarWidth: "thin",
                                  scrollbarColor: "transparent transparent",
                                  borderWidth: "0px",
                                  borderStyle: "solid",
                                  boxSizing: "border-box",
                                  display: "block",
                                  verticalAlign: "middle",
                                  maxWidth: "100%",
                                  inset: "0px",
                                  borderRadius: "inherit",
                                  position: "absolute",
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "cover",
                                  zIndex: 1,
                                  pointerEvents: "none",
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Card>
                  ))}
                </div>
              )}

              {cards.length > 0 && activeTab == "Advertising" && (
                <div className="cards">
                  {advertisingCards.map((card, index) => (
                    <Card key={index}>
                      <div className="card_wrapper">
                        <div className="card_content">
                          <div className="title">{card.type}</div>
                          <div className="bin">
                            {String(card.cardNumber).slice(0, 4)}{" "}
                            {String(card.cardNumber).slice(4)}
                          </div>
                          <button
                            className="dark_button"
                            onClick={() => handlePurchase(card)}
                          >
                            Buy
                          </button>
                        </div>
                      </div>
                    </Card>
                  ))}
                </div>
              )}
            </CardRoller>
          </div>
        </IssueCardWrapper>
      )}

      {purchaseCard && (
        <PurchaseCardWrapper>
          <div className="back_button_wrapper">
            <button onClick={() => setIssueCard(!issueCard)}>
              <img src="./assets/icons/misc/back-arrow.svg" />
            </button>
            <p>Cards</p>
          </div>
          {details.label === "Universal" && (
            <p className="page_title">Issuing Universal Card </p>
          )}
          {details.label === "Advertising" && (
            <p className="page_title">Issuing Ad Card </p>
          )}

          <div className="purchase_wrapper">
            <Box>
              <div className="section form">
                <AmountBox>
                  <p className="title">Account</p>
                  <div className="input_wrapper">
                    <div className="input_block">
                      <p>Personal account</p>
                    </div>
                    <div className="right">
                      <p>${userData.totalBalance}</p>
                    </div>
                  </div>
                </AmountBox>

                <AmountBox>
                  <p className="title">Name of the card</p>
                  <div className="input_wrapper">
                    <div className="input_block">
                      <input
                        defaultValue="Name of your card"
                        placeholder="Name on card"
                        onChange={handleName}
                      />
                    </div>
                  </div>
                </AmountBox>

                {/* deposit amount */}
                <AmountBox>
                  <p className="title">Deposit amount</p>
                  <div className="input_wrapper">
                    <div className="input_block">
                      <input
                        defaultValue={amount}
                        placeholder={amount}
                        onChange={handleAmount}
                        ref={amountRef}
                      />
                    </div>
                    <div className="right">
                      {/* <p>$0.00</p> */}
                      <button className="max_button" onClick={handleMaxButton}>
                        MAX
                      </button>
                    </div>
                  </div>
                  {amountError && (
                    <p className="caption error_caption">
                      There are not enough funds on the account
                    </p>
                  )}

                  {totsError && (
                    <p className="caption error_caption">
                      Your current balance is less than the total amount
                    </p>
                  )}
                </AmountBox>

                <div className="btns">
                  <button
                    className="red_button"
                    onClick={() => setIssueCard(!issueCard)}
                  >
                    Cancel
                  </button>
                  <button
                    className={
                      isBuying || !amount || amountError || totsError
                        ? "dark_button disabled"
                        : "dark_button"
                    }
                    style={{ whiteSpace: "nowrap" }}
                    disabled={isBuying || !amount || amountError || totsError}
                    onClick={handlePurchaseCard}
                  >
                    {isBuying ? (
                      <CircularLoader bg="#cccccc" size="20" color="#ffffff" />
                    ) : (
                      "Issue a new card"
                    )}
                  </button>
                </div>
              </div>
            </Box>

            <Box>
              <div className="section form">
                {details.label === "Advertising" && (
                  <p className="section_title_mini">
                    This card can only be used to pay for digital advertising.
                  </p>
                )}
                <div className="section">
                  <p className="section_title">
                    Information about your operation
                  </p>
                  <div className="ruled_section">
                    <p className="title">Card price</p>

                    <p className="section_bal normal">
                      $
                      {String(Number(details?.price).toFixed(2)).slice(
                        0,
                        String(Number(details?.price).toFixed(2)).indexOf(".")
                      )}
                      <span>
                        {String(Number(details?.price).toFixed(2)).slice(
                          String(Number(details?.price).toFixed(2)).indexOf(".")
                        )}
                      </span>
                    </p>
                  </div>
                  <div className="ruled_section">
                    <p className="title">Deposit amount</p>

                    <p className="section_bal normal">
                      $
                      {String(Number(amount).toFixed(2)).slice(
                        0,
                        String(Number(amount).toFixed(2)).indexOf(".")
                      )}
                      <span>
                        {String(Number(amount).toFixed(2)).slice(
                          String(Number(amount).toFixed(2)).indexOf(".")
                        )}
                      </span>
                    </p>
                  </div>
                  <div className="ruled_section">
                    <p className="title">
                      Top-up commission ({details.commission}%)
                    </p>

                    <p className="section_bal normal">
                      $
                      {String(
                        Number((details?.commission / 100) * amount).toFixed(2)
                      ).slice(
                        0,
                        String(
                          Number((details?.commission / 100) * amount).toFixed(
                            2
                          )
                        ).indexOf(".")
                      )}
                      <span>
                        {String(
                          Number((details?.commission / 100) * amount).toFixed(
                            2
                          )
                        ).slice(
                          String(
                            Number(
                              (details?.commission / 100) * amount
                            ).toFixed(2)
                          ).indexOf(".")
                        )}
                      </span>
                    </p>
                  </div>
                  <div className="ruled_section bold">
                    <p className="title">Total amount</p>

                    <p className="section_bal normal">
                      ${total}
                      <span>.00</span>
                    </p>
                  </div>
                </div>
              </div>
            </Box>
          </div>
        </PurchaseCardWrapper>
      )}
    </>
  );
};

const AmountBox = styled.div`
  box-sizing: content-box;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  /* gap: 24px; */
  margin-bottom: 20px;
  width: 100% !important;

  .right p {
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.5rem;
    color: #070707;
  }

  p.title {
    margin-bottom: 12px;
    color: #333742;
    font-size: 16px;
    line-height: 1;
    font-weight: 500;
  }

  h3.title {
    margin: 0px;
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
  }

  .input_wrapper {
    display: flex;
    /* justify-content: space-between; */
    box-sizing: border-box;
    align-items: center;
    position: relative;
    width: 100%;
    background: transparent;
    padding: 10px 12px;
    border-radius: 8px;
    border-width: 1px;
    border-style: solid;
    border-color: #f3f3f3;
    height: 56px;
  }

  .input_block {
    box-sizing: border-box;
    flex-grow: 1;
    overflow: hidden;
    max-width: 100%;
    position: relative;
  }

  label {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
    transition: transform 0.1s ease 0s;
    margin-top: 4px;
    line-height: 1;
    color: #686c77;
    font-size: 14px;
    margin-bottom: 2px;
  }

  .max_button {
    font-size: 12px;
    font-weight: 600;
    background: none;
    outline: none;
    border: none;
    cursor: pointer;
  }

  input {
    box-sizing: border-box;
    margin: 0px;
    padding: 0px;
    font-family: inherit;
    font-size: 100%;
    font-weight: inherit;
    line-height: inherit;
    color: inherit;
    flex-grow: 1;
    background: transparent;
    overflow: hidden;
    white-space: nowrap;
    min-width: 1%;
    width: 100%;
    text-overflow: ellipsis;
    max-width: 100%;
    outline: none;
    border: none;
    transition: 0.3s ease-in-out;
  }

  .caption {
    display: flex;
    gap: 4px;
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
  }

  .caption.error_caption {
    font-size: 12px;
    line-height: 1;
    color: #df1b1b;
    padding: 8px 0 0;
  }

  .caption span {
    color: rgba(21, 128, 61, 1);
  }
`;

const Box = styled.div`
  padding: 24px;
  border: 1px solid #e1e8f1;
  border-radius: 20px;
  margin-bottom: 10px;
  /* height: 155px; */
  /* box-sizing: border-box; */

  .btns {
    display: flex;
    gap: 12px;
  }

  .ruled_section {
    padding-bottom: 12px;
    border-bottom: 1px solid rgb(230, 230, 230);
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 8px;
  }

  p.section_title_mini {
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    color: #686c77;
    margin-top: 16px;
  }

  p.section_title {
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    margin-bottom: 16px;
  }

  .ruled_section .title {
    font-size: 14px;
    color: #111111;
    white-space: nowrap;
    line-height: 1;
  }

  .section_bal.bold {
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
    margin-top: 4px;
  }

  .section_bal.bold span {
    color: #94a3b8;
    font-size: 12px;
  }

  .section_bal.normal {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    margin-top: 4px;
  }

  .section_bal.normal span {
    color: #94a3b8;
    font-size: 12px;
  }

  .with_error {
    color: #df1b1b;
  }

  .section_bal.with_error {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    margin-top: 4px;
    color: #df1b1b;
  }

  .section_bal.with_error span {
    color: #df1b1b;
    font-size: 12px;
  }

  .ruled_section.bold {
    border: none;
    font-weight: 700;
  }

  .ruled_section.bold .section_bal {
    border: none;
    font-weight: 700;
  }

  input[type="checkbox"] {
    cursor: pointer;
    accent-color: #1968e5;
    border: 1px solid #1968e5;
    border-radius: 4px;
  }

  h1.box_title {
    font-size: 20px;
    font-weight: 700;
    line-height: 1;
    color: #111111;
  }

  .check_section {
  }

  .check_section p {
    font-size: 14px;
    line-height: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    white-space: nowrap;
  }

  .user_wrapper {
    display: flex;
    gap: 16px;
    align-items: center;
    margin-top: 24px;
  }

  .user_info {
    display: grid;
    gap: 4px;
  }

  .user_info .name {
    color: #111111;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    white-space: nowrap;
  }

  .user_info .register_info {
    color: #686c77;
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    white-space: nowrap;
  }

  .flex_center {
    display: flex;
    align-items: center;
  }

  .flex_divide {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .form {
    display: flex;
    flex-direction: column;
  }

  .flex-8 {
    gap: 8px;
  }

  .flex-12 {
    gap: 12px;
  }

  .flex-16 {
    gap: 16px;
  }

  .flex-24 {
    gap: 24px;
  }

  .flex-32 {
    gap: 32px;
  }

  .section {
    margin-top: 24px;
  }

  .date_items {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 12px;
  }

  .date_item p {
    font-size: 14px;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    white-space: nowrap;
  }

  .light_select {
    box-sizing: border-box;
    display: flex;
    outline: none;
    gap: 8px;
    background-color: rgba(17, 17, 17, 0.05);
    border-radius: 8px;
    padding: 8px 12px;
    font-size: 14px;
    border: none;
    align-items: center;
    line-height: 1;
    padding-right: 10px;
    font-weight: 500;
    height: 36px;
  }

  @media screen and (max-width: 768px) {
    & {
      width: 100% !important;
      box-sizing: border-box;
    }

    .form {
      width: 100%;
      display: flex;
      flex-direction: column;
      max-width: unset;
      box-sizing: border-box;
    }
  }
`;

const PurchaseCardWrapper = styled.div`
  box-sizing: border-box;

  .purchase_wrapper {
    display: flex;
    gap: 24px;
    margin-top: 24px;
  }

  @media screen and (max-width: 768px) {
    .purchase_wrapper {
      flex-direction: column;
    }
  }

  .mt-32 {
    margin-top: 32px;
  }

  .mt-48 {
    margin-top: 48px;
  }

  .mt-60 {
    margin-top: 60px;
  }

  .btns {
    display: flex;
    gap: 12px;
    margin-top: 16px;
  }

  .back_button_wrapper {
    display: flex;
    line-height: 1;
    gap: 12px;
    margin-bottom: 24px;
    align-items: center;
  }

  .back_button_wrapper button {
    width: 24px;
    height: 24px;
    border-radius: 100%;
    background: rgba(17, 17, 17, 0.05);
    border: none;
    outline: none;
    cursor: pointer;
  }

  .back_button_wrapper p {
    font-weight: 700;
    font-size: 16px;
    line-height: 1;
  }

  .page_title {
    font-size: 24px !important;
    /* width: max-content; */
  }
`;

const IssueCardWrapper = styled.div`
  box-sizing: border-box;

  .mt-32 {
    margin-top: 32px;
  }

  .mt-48 {
    margin-top: 48px;
  }

  .mt-60 {
    margin-top: 60px;
  }

  .btns {
    display: flex;
    gap: 12px;
    margin-top: 16px;
  }

  .back_button_wrapper {
    display: flex;
    line-height: 1;
    gap: 12px;
    margin-bottom: 24px;
    align-items: center;
  }

  .back_button_wrapper button {
    width: 24px;
    height: 24px;
    border-radius: 100%;
    background: rgba(17, 17, 17, 0.05);
    border: none;
    outline: none;
    cursor: pointer;
  }

  .back_button_wrapper p {
    font-weight: 700;
    font-size: 16px;
    line-height: 1;
  }

  .page_title {
    font-size: 24px !important;
    /* width: max-content; */
  }
`;

const ReferralCard = styled.div`
  border-radius: 16px;
  border: none;
  background: #f7f9fb;
  box-sizing: border-box;
  padding: 24px;
  padding-bottom: 0px;
  display: flex;
  justify-content: space-between;
  /* display: none; */

  .left {
    padding: 24px;
  }

  .left .card_title {
    font-size: 40px;
    font-weight: 500;
    line-height: 1;
    color: #111111;
    margin-bottom: 16px;
    line-height: 125%;
    font-weight: 700;
    max-width: 380px;
    text-wrap: balance;
  }

  .left .card_text {
    font-size: 16px;
    line-height: 120%;
    color: #686c77;
    margin-bottom: 16px;
    line-height: 142%;
    margin-top: 12px;
  }

  .card_stats {
    display: flex;
    gap: 32px;
    flex-direction: row;
    align-items: center;
    margin-top: 24px;
  }

  img {
    max-width: 325px;
  }

  .stat p:nth-child(1) {
    font-size: 20px;
    line-height: 112%;
    font-weight: 700;
  }

  .stat p:nth-child(2) {
    margin-top: 6px;
    font-size: 14px;
    line-height: 125%;
    font-weight: 500;
    color: #828282;
  }

  .stat p.link:nth-child(2) {
    text-decoration: underline;
  }

  @media screen and (max-width: 768px) {
    & {
      padding: 12px;
    }

    img {
      display: none;
    }

    .card_stats {
      flex-direction: column;
      align-items: flex-start;
    }

    .left {
      padding: 12px 0px;
    }
  }
`;

const PageSwitcher = styled.div`
  margin-top: 24px;
  background: #f7f9fb;
  padding: 4px;
  border-radius: 12px;
  width: max-content;
  max-width: 100%;
  overflow-x: auto;
  overflow-y: clip;
  display: flex;
  box-sizing: border-box;

  .switch {
    background-color: transparent;
    border-radius: calc(12px / 1.5);
    max-width: 100%;
    white-space: nowrap;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    text-align: center;
    font-size: 16px;
    line-height: 1;
    font-weight: 500;
    padding: 6px 16px;
    min-height: 36px;
    width: 100%;
    border: none;
    outline: none;
    cursor: pointer;
  }

  .switch.active {
    background: rgba(255, 255, 255, 1);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  }
`;

const CardRoller = styled.div`
  border: 1px solid #e1e8f1;
  padding: 12px;
  border-radius: 16px;
  margin-top: 32px;
  box-sizing: border-box;

  .title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
  }

  .badge {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    padding: 0px 6px;
    background: #1968e5;
    height: 20px;
    text-align: center;
    font-size: 10px;
    font-weight: 500;
    line-height: 1;
    color: #fefefe;
  }

  .description {
    margin-top: 16px;
    font-size: 12px;
    line-height: 125%;
    font-weight: 500;
    color: #828282;
    max-width: 70%;
    display: none;
  }

  .cards {
    gap: 24px;
    display: grid;
    margin-top: 32px;
    grid-template-columns: 0.5fr 0.5fr;
    box-sizing: border-box;
  }

  @media screen and (max-width: 768px) {
    .cards {
      display: flex;
      flex-direction: column;
    }
  }
`;

const Card = styled.div`
  padding: 24px;
  border-radius: 16px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  flex-grow: 1;
  border: none;
  background: #f7f9fb;
  box-sizing: border-box;

  .card_wrapper {
    display: flex;
    justify-content: flex-start;
    flex-grow: 1;
    gap: 24px;
    align-items: center;
    flex-direction: row;
    box-sizing: border-box;
  }

  .card_content {
    justify-content: flex-start;
    align-items: stretch;
    flex-direction: column;
    flex-grow: 1;
    align-self: stretch;
    gap: 24px;
    padding: calc(24px / 1.7) 0;
    width: 100%;
    display: flex;
    box-sizing: border-box;
  }

  .card_content .title {
    color: #070707;
    font-size: 20px;
    line-height: 1;
    font-weight: 600;
  }

  .card_content .bin {
    display: flex;
    align-items: baseline;
    gap: 0.5ch;
    white-space: nowrap;
    font-size: 16px;
    line-height: 1;
    font-weight: 500;
    color: #828282;
    margin-top: 8px;
  }

  .card_container {
    flex-shrink: 0;
    order: 3;
    width: 224px;
    transform: rotate(-10deg) translateZ(0px);
    margin: calc(24px / 2) calc(24px / 2) calc(24px / 2) auto;
  }

  .universal_card {
    position: relative;
    flex-shrink: 0;
    margin: 0px auto;
    width: 100%;
    max-width: 224px;
    height: 140px;
  }

  .universal_card_wrapper {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: 10px 10px 12px;
    background-color: #111111;
    width: 100%;
    color: #ffffff;
    position: relative;
    border-radius: 12px;
    height: 100%;
  }

  .locker_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    inset: 0px;
    background: rgba(0, 0, 0, 0.7);
    border-radius: inherit;
    padding: 15px;
    font-size: 14px;
    line-height: 142%;
    position: absolute;
    z-index: 20;
    color: #ffffff;
    text-align: center;
  }

  .locker {
    border-width: 0px;
    border-style: solid;
    font: inherit;
    font-synthesis: inherit;
    place-content: inherit;
    place-items: inherit;
    place-self: inherit;
    animation: inherit;
    background: transparent;
    border-block: inherit;
    border: inherit;
    border-radius: inherit;
    columns: inherit;
    gap: inherit;
    column-rule: inherit;
    contain-intrinsic-size: inherit;
    container: inherit;
    flex: inherit;
    flex-flow: inherit;
    inset-block: inherit;
    inset-inline: inherit;
    list-style: inherit;
    margin-block: inherit;
    margin: inherit;
    margin-inline: inherit;
    marker: inherit;
    mask: inherit;
    offset: inherit;
    outline: inherit;
    overflow: inherit;
    overscroll-behavior: inherit;
    padding-block: inherit;
    padding: inherit;
    padding-inline: inherit;
    scroll-margin-block: inherit;
    scroll-margin: inherit;
    scroll-margin-inline: inherit;
    scroll-padding-block: inherit;
    scroll-padding: inherit;
    scroll-padding-inline: inherit;
    text-decoration: inherit;
    text-emphasis: inherit;
    white-space: inherit;
    transition: inherit;
    view-timeline: inherit;
    border-spacing: inherit;
    -webkit-mask-box-image: inherit;
    -webkit-text-stroke: inherit;
    border-color: rgb(230, 230, 230);
  }

  .bg {
  }

  .universal_container {
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
    border-width: 0px;
    border-style: solid;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    flex-grow: 1;
    position: relative;
    z-index: 10;
  }

  .universal_container .universal_container_top {
    white-space: nowrap;
    display: flex;
    align-items: center;
    font-family: orcas;
    gap: 6.5px;
    font-size: 17px;
    line-height: 28px;
    font-weight: 500;
    margin-top: 7px;
    letter-spacing: normal;
  }

  .universal_container_bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 7px;
  }

  .universal_container_bottom span {
    font-weight: 600;
    font-size: 11px;
    line-height: 9px;
    letter-spacing: 4.8px;
    text-transform: uppercase;
    color: #ffffff;
    opacity: 0.89;
    background: -webkit-linear-gradient(
        360deg,
        rgb(210, 209, 213) 16.99%,
        rgb(0, 0, 0) 22.93%,
        rgb(240, 237, 236) 31.63%,
        rgb(116, 115, 114) 54.96%,
        rgb(255, 255, 255) 67.93%,
        rgb(195, 195, 195) 89.93%,
        rgb(0, 0, 0) 121.64%
      )
      text;
    -webkit-text-fill-color: transparent;
  }

  @media screen and (max-width: 768px) {
    & {
      width: 100% !important;
    }

    .card_wrapper {
      flex-direction: column;
      width: 100%;
    }

    .card_container {
      margin: 0 auto;
      /* transform: translateX(3px); */
    }
  }
`;

export default IssueCard;
