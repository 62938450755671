import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import AmountBoxWithSmallLabel from "../../../components/styled/AmountBoxWithSmallLabel";
import BackgroundAmountDropDownWithSmallLabel from "../../../components/styled/BackgroundAmountDropDownWithSmallLabel";
import BackgroundAmountDropdown from "../../../components/styled/BackgroundAmountDropdown";
import AmountBoxWithMaxButton from "../../../components/styled/AmountBoxWithMaxButton";
import DepositAddressBox from "../../../components/styled/DepositAddressBox";
import PrimaryButton from "../../../components/styled/PrimaryButton";
import { context } from "../../../context/context";
import CircularLoader from "../../../components/styled/loaders/CircularLoader";
import { doc, serverTimestamp, setDoc } from "firebase/firestore";
import Toast from "../../../hooks/Toast";
import { db } from "../../../firebase/firebase";

const TopUp = () => {
  // toast
  const [openToast, setOpenToast] = useState(false);
  const [toastMessage, setToastMessage] = useState(" ");
  const [toastType, setToastType] = useState(undefined);

  const [isLoading, setIsLoading] = useState(true);

  const { depositSettings, userData } = useContext(context);

  const { id } = userData;

  const [addresses, setAddresses] = useState([]);

  const [commission, setCommission] = useState(null);

  const [amount, setAmount] = useState("");

  function handleAmount(e) {
    const { value } = e.target;

    if (Number(value)) {
      setAmount(Number(value));
    } else {
      setAmount("");
    }
  }

  useEffect(() => {
    if (depositSettings) {
      const { addresses, commission } = depositSettings;
      // console.log(addresses);

      if (addresses) {
        setAddresses(Object.values(addresses));
        // console.log(addresses);
      }

      if (commission) {
        setCommission(commission);
      }

      setTimeout(() => {
        setIsLoading(false);
      }, 300);
    }
  }, [depositSettings]);

  const [minimumAmountError, setMinimumAmountError] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);

  function reset() {
    if (amountRef) {
      amountRef.current.value = "";
    }
    setAmount("");
  }

  async function submitDeposit() {
    setIsSubmitting(true);

    const randomOne = Math.floor(Math.random(100, 999) * 1000 + 1);
    const randomTwo = Math.floor(Math.random(100, 999) * 100 + 1);

    const str =
      id.substring(0, 4) + randomOne.toString() + randomTwo.toString();

    await setDoc(doc(db, "deposits", str), {
      ref: str,
      user: id,
      amount: Number(amount),
      date: serverTimestamp(),
      status: "pending",
      commission: (Number(commission) / 100) * Number(amount),
      userRef: {
        photo: userData.photoURL ? userData.photoURL : null,
        email: userData.email,
      },
    })
      .then(() => {
        createHistory(str);
      })
      .catch((error) => {
        console.log(error);
        setIsSubmitting(false);
        setToastType("error");
        setToastMessage("Failed to deposit. Please try again later.");
        setOpenToast(true);
      });
  }

  async function createHistory(ref) {
    await setDoc(doc(db, "history", ref), {
      ref,
      user: id,
      amount: Number(amount),
      text: "Made a deposit of $" + Number(amount),
      date: serverTimestamp(),
      status: "pending",
      userRef: {
        photo: userData.photoURL ? userData.photoURL : null,
        email: userData.email,
      },
    })
      .then(() => {
        reset();
        setIsSubmitting(false);
        setToastType("success");
        setToastMessage("Deposit request pending");
        setOpenToast(true);
      })
      .catch((error) => {
        console.log(error);
        setIsSubmitting(false);
        setToastType("error");
        setToastMessage("Failed to deposit. Please try again later.");
        setOpenToast(true);
      });
  }

  const amountRef = useRef();

  return (
    <TopUpWrapper>
      {openToast && (
        <Toast
          open={{ openToast, setOpenToast }}
          message={toastMessage}
          type={toastType}
        />
      )}
      {/* title /top */}
      <div className="title_wrapper">
        <h3>Replenishment of personal account</h3>
        <p>
          Your personal account number: <span>0200834135859746</span>
        </p>
      </div>

      <div className="section">
        {/* <h2 className="section_title">Deposit amount</h2> */}
        <AmountBoxWithSmallLabel
          caption="Top up commission:"
          caption_span={commission ? `${commission}%` : "No commission"}
          title="Deposit amount"
          label="Amount, $"
          type="number"
          boxRef={amountRef}
          onChange={handleAmount}
        />
      </div>

      {isLoading && <CircularLoader bg="#cccccc" size="32" color="#ffffff" />}

      {!isLoading && (
        <div>
          {addresses.length > 0 &&
            addresses.map((address) => (
              <>
                <DepositAddressBox address={address} amount={amount} />
              </>
            ))}
          <button
            className={
              isSubmitting || !amount ? "dark_button disabled" : "dark_button"
            }
            disabled={isSubmitting || !amount}
            style={{ whiteSpace: "nowrap", width: "100%", maxWidth: "200px" }}
            onClick={submitDeposit}
          >
            {isSubmitting ? (
              <CircularLoader bg="#cccccc" size="20" color="#ffffff" />
            ) : (
              "I have made this payment"
            )}
          </button>
        </div>
      )}

      {!isLoading && (
        <div>{!addresses.length > 0 && <p>No available deposit method</p>}</div>
      )}

      {/**/}
    </TopUpWrapper>
  );
};

const TopUpWrapper = styled.div`
  .section {
    margin-bottom: 40px;
  }

  h2.section_title {
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
    border-width: 0px;
    border-style: solid;
    border-color: rgb(230, 230, 230);
    box-sizing: border-box;
    margin: 0px;
    font-size: 24px;
    line-height: 28px;
    font-weight: 500;
    margin-bottom: 20px;
  }

  .title_wrapper {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    gap: 8px;
    color: #333742;
    margin-bottom: 32px;
  }

  .title_wrapper h3 {
    margin: 0px;
    font-size: 20px;
    line-height: 26px;
    font-weight: 700;
  }

  .title_wrapper p {
    margin: 0px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
  }

  .title_wrapper p span {
    box-sizing: border-box;
    font-weight: 500;
  }
`;

export default TopUp;
