import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import DashboardPage from "./pages/dashboard";
import AccountsPage from "./pages/accounts";
import TeamsPage from "./pages/teams";
import ReferralProgramPage from "./pages/referral-program";
import SupportPage from "./pages/support";
import CardsPage from "./pages/cards";
import LoginPage from "./auth/login";
import RegisterPage from "./auth/register";
import SettingsPage from "./pages/settings";
import AdminPage from "./pages/admin";
import TransactionHistoryPage from "./pages/transaction-history";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<RegisterPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/dashboard" element={<DashboardPage />} />
        <Route path="/accounts" element={<AccountsPage />} />
        <Route path="/teams" element={<TeamsPage />} />
        <Route path="/support" element={<SupportPage />} />
        <Route path="/earn" element={<ReferralProgramPage />} />
        <Route path="/cards" element={<CardsPage />} />
        <Route path="/settings" element={<SettingsPage />} />
        <Route path="/history" element={<TransactionHistoryPage />} />
        <Route path="/admin" element={<AdminPage />} />
      </Routes>
    </Router>
  );
}

export default App;
