import { useState } from "react";
import styled from "styled-components";

const AmountBoxWithMaxButton = ({ title }) => {
  const [inputShown, setInputShown] = useState(false);
  const [value, setValue] = useState("");

  function handleBlur() {
    console.log(value);
    console.log("out!");

    if (!value) {
      setInputShown(false);
    }
  }

  return (
    <Wrapper>
      <h3 className="title">{title}</h3>
      <div className="input_wrapper">
        <div className="input_block">
          <label htmlFor="value">Amount, $</label>
          <input placeholder="" />

          {/* <input
            className="input_label"
            placeholder="Amount, $"
            disabled={inputShown}
            onFocus={() => setInputShown(!inputShown)}
          /> */}
          {/* <p className="input_label" onFocus={() => setInputShown(!inputShown)}>
            Amount, ${" "}
          </p> */}
          {/* {inputShown && (
            <input
              className="input_box"
              placeholder="1000"
              onChange={(e) => setValue(e.target.value)}
              onBlur={handleBlur}
            />
          )} */}
        </div>
        <button className="max_button">MAX</button>
      </div>
      <p className="caption">
        Transfer fee: <span>No commission</span>
      </p>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 20px;
  max-width: 720px;

  h3.title {
    margin: 0px;
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
  }

  .input_wrapper {
    display: flex;
    box-sizing: border-box;
    align-items: center;
    position: relative;
    width: 100%;
    background: transparent;
    padding: 10px 12px;
    border-radius: 8px;
    border-width: 1px;
    border-style: solid;
    border-color: #f3f3f3;
    height: 56px;
  }

  .input_block {
    box-sizing: border-box;
    flex-grow: 1;
    overflow: hidden;
    max-width: 100%;
    position: relative;
  }

  label {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
    transition: transform 0.1s ease 0s;
    margin-top: 4px;
    line-height: 1;
    color: #686c77;
    font-size: 14px;
    margin-bottom: 2px;
  }

  .max_button {
    font-size: 12px;
    font-weight: 600;
    background: none;
    outline: none;
    border: none;
    cursor: pointer;
  }

  input {
    box-sizing: border-box;
    margin: 0px;
    padding: 0px;
    font-family: inherit;
    font-size: 100%;
    font-weight: inherit;
    line-height: inherit;
    color: inherit;
    flex-grow: 1;
    background: transparent;
    overflow: hidden;
    white-space: nowrap;
    min-width: 1%;
    width: 100%;
    text-overflow: ellipsis;
    max-width: 100%;
    outline: none;
    border: none;
    transition: 0.3s ease-in-out;
  }

  /* input:focus ~ label {
    color: red;
    transition-duration: 0.2s;
    transform: translate(0, -1.5em) scale(0.9, 0.9);
  } */

  .caption {
    display: flex;
    gap: 4px;
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
  }

  .caption span {
    color: rgba(21, 128, 61, 1);
  }
`;

export default AmountBoxWithMaxButton;
