import React from "react";
import styled from "styled-components";

const NoCards = ({ action }) => {
  return (
    <NoCardsWrapper>
      <img src="https://e.pn/assets/empty-cards-placeholder.8c8d57a3.png" />
      <h1 className="title">You don't have cards yet</h1>
      <button className="blue_button" onClick={action}>
        Issue a new card
      </button>
    </NoCardsWrapper>
  );
};

const NoCardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    height: auto;
    max-width: 25vw;
  }

  h1.title {
    font-weight: 700;
    line-height: 144%;
    text-align: center;
    font-size: 56px;
    margin-top: 40px;
    margin-bottom: 16px;
  }
`;

export default NoCards;
