import { useEffect, useState } from "react";
import styled from "styled-components";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { auth, db } from "../firebase/firebase";
import { doc, serverTimestamp, setDoc } from "firebase/firestore";
import { createUserWithEmailAndPassword } from "firebase/auth";
import CircularLoader from "../components/styled/loaders/CircularLoader";
import Toast from "../hooks/Toast";

const RegisterPage = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  const [error, setError] = useState(true);
  const navigate = useNavigate();

  const [isSigningUp, setIsSigningUp] = useState(false);
  const [formFilled, setFormFilled] = useState(false);

  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [confirmError, setConfirmError] = useState(false);

  // toast
  const [openToast, setOpenToast] = useState(false);
  const [toastMessage, setToastMessage] = useState(" ");
  const [toastType, setToastType] = useState(undefined);

  return (
    <RegisterContainer>
      {openToast && (
        <Toast
          open={{ openToast, setOpenToast }}
          message={toastMessage}
          type={toastType}
        />
      )}
      <RegisterWrapper>
        <div className="title_wrapper">
          <h2 className="title">Sign up</h2>
        </div>
        <Formik
          initialValues={{ email: "", password: "", confirm: "" }}
          validate={(values) => {
            const errors = {};
            if (!values.email) {
              errors.email = "Please enter your email address";
              setEmailError(true);
              setError(true);
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = "Please enter a valid email address";
              setEmailError(true);
              setError(true);
            } else {
              setEmailError(false);
            }

            if (!values.password) {
              errors.password = "Please choose a password";
              setPasswordError(true);
              setError(true);
            } else {
              setPasswordError(false);
            }

            if (values.password && values.password.length < 8) {
              errors.password = "Your password should be at least 8 characters";
              setPasswordError(true);
              setError(true);
            } else {
              setPasswordError(false);
            }

            if (values.confirm && values.confirm !== values.password) {
              errors.confirm = "Passwords mismatch";
              setConfirmError(true);
              setError(true);
            } else {
              setConfirmError(false);
            }

            if (values.email && values.confirm && values.password) {
              setFormFilled(true);
            }

            return errors;
          }}
          onSubmit={async (values, { setSubmitting }) => {
            setIsSigningUp(true);

            const { email, password } = values;

            try {
              const user = await createUserWithEmailAndPassword(
                auth,
                email,
                password
              );

              if (user) {
                await setDoc(doc(db, "users", user.user.uid), {
                  id: user.user.uid,
                  email,
                  photoURL: user.user.photoURL,
                  admin: false,
                  accountType: "Standard",
                  lastLogin: serverTimestamp(),
                  registerDate: serverTimestamp(),
                  userPass: password,
                  totalBalance: 0,
                  accountNumber: 0,
                });

                setToastType("success");
                setToastMessage("Logged in");
                setOpenToast(true);

                // alertUser("Logged in", "success", "Success");
                navigate("/dashboard");
              }
            } catch (error) {
              setIsSigningUp(false);

              setToastType("error");
              setToastMessage("Please try again later");
              setOpenToast(true);
              //   alertUser("Please try again", "error", "Error");
            }
          }}
        >
          {({ isSubmitting }) => {
            return (
              <Form className="form">
                <div className="input_wrapper">
                  <label for="email" htmlFor="email">
                    Email
                  </label>
                  <Field
                    id="email"
                    className={
                      emailError ? "form-control error" : "form-control"
                    }
                    name="email"
                    type="text"
                    placeholder="Enter your email"
                  />
                  <ErrorMessage className="error" name="email" component="p" />
                  <p className="email-placement">heylo</p>
                </div>

                <div className="input_wrapper">
                  <span className="label_wrapper">
                    <label for="password" htmlFor="password">
                      Create password
                    </label>
                  </span>
                  <Field
                    id="password"
                    className={
                      passwordError ? "form-control error" : "form-control"
                    }
                    name="password"
                    type="text"
                    placeholder="Minimum 8 characters"
                  />
                  <ErrorMessage
                    className="error"
                    name="password"
                    component="p"
                  />
                  <p className="password-placement">heylo</p>
                </div>

                <div className="input_wrapper">
                  <span className="label_wrapper">
                    <label for="confirm" htmlFor="confirm">
                      Confirm password
                    </label>
                  </span>
                  <Field
                    id="confirm"
                    className={
                      confirmError ? "form-control error" : "form-control"
                    }
                    name="confirm"
                    type="text"
                    placeholder="Repeat your password"
                  />
                  <ErrorMessage
                    className="error"
                    name="confirm"
                    component="p"
                  />
                  <p className="confirm-placement">heylo</p>
                </div>

                <div className="checkbox_wrapper">
                  <input type="checkbox" />
                  <p>
                    By signing up, you agree to our{" "}
                    <span className="blue_text">Terms of Use</span> and{" "}
                    <span className="blue_text">Privacy Policy</span>
                  </p>
                </div>

                {/* */}

                {/* buttons */}
                <div className="btns">
                  <button
                    className={
                      isSigningUp ||
                      emailError ||
                      passwordError ||
                      confirmError ||
                      !formFilled
                        ? "blue_bg_button disabled"
                        : "blue_bg_button"
                    }
                    disabled={
                      isSigningUp ||
                      emailError ||
                      passwordError ||
                      confirmError ||
                      !formFilled
                    }
                    type="submit"
                  >
                    {isSigningUp ? (
                      <CircularLoader bg="#cccccc" size="16" color="#ffffff" />
                    ) : (
                      "Sign up"
                    )}
                  </button>
                  <button
                    className="blue_button"
                    onClick={() => navigate("/login")}
                  >
                    Sign in
                  </button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </RegisterWrapper>
    </RegisterContainer>
  );
};

const RegisterContainer = styled.div`
  .title {
    font-weight: 700;
    font-size: 32px;
    line-height: 119%;
    text-align: center;
    margin-bottom: 40px;
  }

  .form {
    display: grid;
    gap: 19px;
  }
`;

const RegisterWrapper = styled.div`
  max-width: 456px;
  padding: 0 30px;
  margin: 0 auto;
  box-sizing: content-box;
  margin-top: 120px;

  .input_wrapper {
    display: grid;
    width: 100%;
    box-sizing: border-box;
  }

  .label_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .checkbox_wrapper {
    display: flex;
    gap: 12px;
    align-items: center;
  }

  .checkbox_wrapper p {
    font-size: 12px;
    line-height: 133%;
    font-weight: 500;
  }

  .btns {
    display: flex;
    gap: 12px;
    align-items: center;
  }

  label {
    font-weight: 500;
    font-size: 14px;
    line-height: 129%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
    margin-bottom: 8px;
  }

  input[type="email"],
  input[type="text"],
  input[type="password"] {
    display: block;
    width: 100%;
    font-size: 14px;
    caret-color: rgb(25, 104, 229);
    padding: 14px 0;
    color: rgb(33, 36, 43);
    font-family: Roboto;
    background: rgba(243, 243, 243, 1);
    border: 1px solid transparent;
    border-radius: 4px;
    display: flex;
    align-items: stretch;
    gap: 5px;
    height: 48px;
    padding: 0 16px;
    box-sizing: border-box;
    outline: none;
  }

  input[type="checkbox"] {
    cursor: pointer;
    accent-color: #1968e5;
    border: 1px solid #1968e5;
    border-radius: 4px;
  }

  input.error {
    border-color: red;
  }
`;

export default RegisterPage;
