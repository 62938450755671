import styled from "styled-components";

const LightButtonWithIcon = ({ src, text }) => {
  return (
    <LightButtonWithIconWrapper>
      <img src={src} />
      <p>{text}</p>
    </LightButtonWithIconWrapper>
  );
};

const LightButtonWithIconWrapper = styled.button`
  box-sizing: border-box;
  display: flex;
  outline: none;
  gap: 8px;
  background-color: rgba(17, 17, 17, 0.05);
  border-radius: 8px;
  padding: 8px 12px;
  font-size: 14px;
  border: none;
  align-items: center;
  line-height: 1;
  text-align: center;
  font-weight: 500;
  height: 36px;
`;

export default LightButtonWithIcon;
