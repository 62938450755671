import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import AddAddressModal from "../modals/AddAddressModal";
import { context } from "../context/context";
import EditAddressModal from "../modals/EditAddressModal";
import { doc, setDoc, updateDoc } from "firebase/firestore";
import { db } from "../firebase/firebase";
import Toast from "../hooks/Toast";

const SettingsAdmin = () => {
  // toast
  const [openToast, setOpenToast] = useState(false);
  const [toastMessage, setToastMessage] = useState(" ");
  const [toastType, setToastType] = useState(undefined);

  const [addAddress, setAddAddress] = useState(false);

  const { depositSettings } = useContext(context);

  const [addresses, setAddresses] = useState([]);

  useEffect(() => {
    if (depositSettings) {
      const { addresses } = depositSettings;

      if (addresses) {
        setAddresses(addresses);
        console.log(addresses);
      }

      // setTimeout(() => {
      //   setIsLoading(false);
      // }, 300);
    }
  }, [depositSettings]);

  const [editAddress, setEditAddress] = useState(false);

  const [selectedEditAddress, setSelectedEditAddress] = useState(undefined);
  const [selectedEditOption, setSelectedEditOption] = useState(undefined);

  // edit
  function handleEditAddress(address) {
    setSelectedEditAddress(address);
    setEditAddress(true);
  }

  // commission
  const [commission, setCommission] = useState(null);
  const [isCommission, setIsCommission] = useState(false);
  function handleCommission(e) {
    const { value } = e.target;

    if (Number(value)) {
      setCommission(Number(value));
    } else {
      setCommission(null);
    }
  }

  async function editCommission() {
    const q = doc(db, "admin", "deposit");
    try {
      await updateDoc(q, {
        commission: commission,
      });

      setIsCommission(false);
      setToastType("success");
      setToastMessage("Successfully saved commission");
      setOpenToast(true);
    } catch (error) {
      console.log(error);
      setIsCommission(false);
      setToastType("error");
      setToastMessage("Failed to save. Please try again later");
      setOpenToast(true);
    }
  }

  return (
    <div>
      {openToast && (
        <Toast
          open={{ openToast, setOpenToast }}
          message={toastMessage}
          type={toastType}
        />
      )}

      {addAddress && <AddAddressModal open={{ addAddress, setAddAddress }} />}

      {editAddress && (
        <EditAddressModal
          open={{ editAddress, setEditAddress }}
          selected={selectedEditAddress}
        />
      )}

      <Box>
        <h1 className="box_title">Deposit settings</h1>

        <div className="section form flex-16">
          <h1 className="title">Addresses</h1>

          {Object.values(addresses)?.length > 0 &&
            Object.values(
              Object.values(addresses)?.map((address, index) => (
                <TextBox key={index}>
                  <div className="input_wrapper">
                    <div className="input_block">
                      <label htmlFor="value">{address.symbol}</label>
                      <div style={{ display: "flex", marginTop: "4px" }}>
                        <img
                          src={`./asseticons/${address.symbol}.svg`}
                          style={{
                            width: "24px",
                            padding: "8px",
                            paddingLeft: "0px",
                          }}
                        />
                        <input value={address?.address} disabled />
                      </div>
                    </div>
                    <button
                      className="blue_button"
                      onClick={() => handleEditAddress(address)}
                    >
                      Edit
                    </button>
                  </div>
                </TextBox>

                // <div className="address">
                //   <div className="content">
                //     <OutlinedIconBoxWithButton className="variant">
                //       <div className="left">
                //         <img
                //           src={`./asseticons/${address.symbol}.svg`}
                //           style={{ marginLeft: "12px" }}
                //           // ./asseticons/${selectedMethod}.svg

                //           alt=""
                //         />
                //         <input
                //           type="text"
                //           placeholder={address?.address}
                //           disabled
                //         />
                //       </div>
                //       <button
                //         onClick={() =>
                //           handleEditAddress(address, "Regular")
                //         }
                //       >
                //         Edit address
                //       </button>
                //     </OutlinedIconBoxWithButton>
                //   </div>
                // </div>
              ))
            )}

          <button
            className="dark_button"
            onClick={() => setAddAddress(!addAddress)}
          >
            Add address
          </button>
        </div>

        <div className="section form flex-16" style={{ marginTop: "40px" }}>
          <h1 className="title">Deposit commission</h1>

          <TextBox>
            <div className="input_wrapper">
              <div className="input_block">
                <label htmlFor="value">Commission %</label>
                <div style={{ display: "flex", marginTop: "4px" }}>
                  <input placeholder="10" onChange={handleCommission} />
                </div>
              </div>
            </div>
          </TextBox>

          <button
            className={
              !commission || isCommission
                ? "dark_button disabled"
                : "dark_button"
            }
            disabled={!commission || isCommission}
            onClick={editCommission}
          >
            Save
          </button>
        </div>
      </Box>
    </div>
  );
};

export default SettingsAdmin;

const Box = styled.div`
  padding: 24px;
  width: 70%;
  border: 1px solid #e1e8f1;
  border-radius: 20px;
  margin-bottom: 10px;
  /* height: 155px; */
  /* box-sizing: border-box; */

  input[type="checkbox"] {
    cursor: pointer;
    accent-color: #1968e5;
    border: 1px solid #1968e5;
    border-radius: 4px;
  }

  h1.box_title {
    font-size: 20px;
    font-weight: 700;
    line-height: 1;
    color: #111111;
  }

  h1.title {
    font-size: 16px;
    line-height: 1;
    font-weight: 500;
    margin-bottom: 8px;
  }

  .check_section {
  }

  .check_section p {
    font-size: 14px;
    line-height: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    white-space: nowrap;
  }

  .user_wrapper {
    display: flex;
    gap: 16px;
    align-items: center;
    margin-top: 24px;
  }

  .user_info {
    display: grid;
    gap: 4px;
  }

  .user_info .name {
    color: #111111;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    white-space: nowrap;
  }

  .user_info .register_info {
    color: #686c77;
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    white-space: nowrap;
  }

  .flex_center {
    display: flex;
    align-items: center;
  }

  .flex_divide {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .form {
    display: flex;
    flex-direction: column;
    max-width: 70%;
  }

  .flex-8 {
    gap: 8px;
  }

  .flex-12 {
    gap: 12px;
  }

  .flex-16 {
    gap: 16px;
  }

  .flex-24 {
    gap: 24px;
  }

  .flex-32 {
    gap: 32px;
  }

  .section {
    margin-top: 24px;
  }

  .date_items {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 12px;
  }

  .date_item p {
    font-size: 14px;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    white-space: nowrap;
  }

  .light_select {
    box-sizing: border-box;
    display: flex;
    outline: none;
    gap: 8px;
    background-color: rgba(17, 17, 17, 0.05);
    border-radius: 8px;
    padding: 8px 12px;
    font-size: 14px;
    border: none;
    align-items: center;
    line-height: 1;
    padding-right: 10px;
    font-weight: 500;
    height: 36px;
  }

  @media screen and (max-width: 768px) {
    & {
      width: 100% !important;
      box-sizing: border-box;
    }

    .form {
      width: 100%;
      display: flex;
      flex-direction: column;
      max-width: unset;
      box-sizing: border-box;
    }
  }
`;

const TextBox = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  /* gap: 24px; */
  max-width: 720px;

  h1.title {
    font-size: 16px;
    line-height: 1;
    font-weight: 500;
    margin-bottom: 8px;
  }

  h3.title {
    margin: 0px;
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
  }

  .input_wrapper {
    display: flex;
    box-sizing: border-box;
    align-items: center;
    position: relative;
    width: 100%;
    background: transparent;
    padding: 10px 12px;
    border-radius: 8px;
    border-width: 1px;
    border-style: solid;
    border-color: #f3f3f3;
    /* height: 56px; */
    width: 100% !important;
  }

  .input_block {
    box-sizing: border-box;
    flex-grow: 1;
    overflow: hidden;
    max-width: 100%;
    position: relative;
  }

  label {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
    transition: transform 0.1s ease 0s;
    margin-top: 4px;
    line-height: 1;
    color: #686c77;
    font-size: 14px;
    margin-bottom: 2px;
  }

  .max_button {
    font-size: 12px;
    font-weight: 600;
    background: none;
    outline: none;
    border: none;
    cursor: pointer;
  }

  input {
    box-sizing: border-box;
    margin: 0px;
    padding: 0px;
    font-family: inherit;
    font-size: 100%;
    font-weight: inherit;
    line-height: inherit;
    color: inherit;
    flex-grow: 1;
    background: transparent;
    overflow: hidden;
    white-space: nowrap;
    min-width: 1%;
    width: 100%;
    text-overflow: ellipsis;
    max-width: 100%;
    outline: none;
    border: none;
    transition: 0.3s ease-in-out;
  }

  /* input:focus ~ label {
    color: red;
    transition-duration: 0.2s;
    transform: translate(0, -1.5em) scale(0.9, 0.9);
  } */

  .caption {
    display: flex;
    gap: 4px;
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
  }

  .caption span {
    color: rgba(21, 128, 61, 1);
  }

  /* @media screen and (max-width: 768px) {
    & {
      width: 100% !important;
      max-width: unset;
    }
  } */
`;
