import React from "react";
import styled from "styled-components";
import AmountBoxWithSmallLabel from "../../../components/styled/AmountBoxWithSmallLabel";
import BackgroundAmountDropDownWithSmallLabel from "../../../components/styled/BackgroundAmountDropDownWithSmallLabel";
import BackgroundAmountDropdown from "../../../components/styled/BackgroundAmountDropdown";
import AmountBoxWithMaxButton from "../../../components/styled/AmountBoxWithMaxButton";
import DepositAddressBox from "../../../components/styled/DepositAddressBox";
import PrimaryButton from "../../../components/styled/PrimaryButton";

const BetweenAccounts = () => {
  return (
    <BetweenAccountsWrapper>
      {/* title /top */}
      <div className="title_wrapper">
        <h3>Between your accounts and cards</h3>
      </div>

      <div className="section">
        <BackgroundAmountDropDownWithSmallLabel
          title="From"
          label="Main account"
          account_balance="0"
          decimals=".00"
        />

        <BackgroundAmountDropdown />
      </div>

      <AmountBoxWithMaxButton title="Deposit amount" />

      <PrimaryButton disabled={true} text="Transfer $0.00" />

      {/* <DepositAddressBox /> */}

      {/* deposit_wrapper */}
      {/* <AmountBoxWithSmallLabel /> */}
      {/* <BackgroundAmountDropDownWithSmallLabel />
      <BackgroundAmountDropdown />
    
     */}

      {/* deposit/address boxes */}
      {/* */}
    </BetweenAccountsWrapper>
  );
};

const BetweenAccountsWrapper = styled.div`
  .section {
    margin-bottom: 40px;
  }

  h2.section_title {
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
    border-width: 0px;
    border-style: solid;
    border-color: rgb(230, 230, 230);
    box-sizing: border-box;
    margin: 0px;
    font-size: 24px;
    line-height: 28px;
    font-weight: 500;
    margin-bottom: 20px;
  }

  .title_wrapper {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    gap: 8px;
    color: #333742;
    margin-bottom: 32px;
  }

  .title_wrapper h3 {
    margin: 0px;
    font-size: 20px;
    line-height: 26px;
    font-weight: 700;
    color: #111111;
  }

  .title_wrapper p {
    margin: 0px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
  }

  .title_wrapper p span {
    box-sizing: border-box;
    font-weight: 500;
  }
`;

export default BetweenAccounts;
