import { useEffect, useRef, useState } from "react";
import Modal from "@mui/material/Modal";
import { styled } from "styled-components";
import Toast from "../hooks/Toast";
import CircularLoader from "../components/styled/loaders/CircularLoader";
import { doc, setDoc, updateDoc } from "firebase/firestore";
import { makeid } from "../utils/utils";
import { db, storage } from "../firebase/firebase";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";

const AddAddressModal = ({ open }) => {
  const { addAddress, setAddAddress } = open;

  // toast
  const [openToast, setOpenToast] = useState(false);
  const [toastMessage, setToastMessage] = useState(" ");
  const [toastType, setToastType] = useState(undefined);

  const [name, setName] = useState(undefined);
  const [symbol, setSymbol] = useState(undefined);
  const [address, setAddress] = useState(undefined);
  const [qr, setQR] = useState(undefined);
  const [imageName, setImageName] = useState("");
  const [imageLink, setImageLink] = useState("");
  const [tempUrl, setTempUrl] = useState("");
  //   const { dispatch } = useContext(context);

  const cryptoAssets = [
    {
      asset: "BTC",
      name: "Bitcoin",
    },
    {
      asset: "ETH",
      name: "Ethereum",
    },
    {
      asset: "QNT",
      name: "Quant",
    },
    {
      asset: "SOL",
      name: "Solana",
    },
    {
      asset: "BCH",
      name: "Bitcoin Cash",
    },
    {
      asset: "LTC",
      name: "Litecoin",
    },
    {
      asset: "DOGE",
      name: "Dogecoin",
    },
    {
      asset: "USDT",

      name: "Tether",
    },
    {
      asset: "MATIC",

      name: "Polygon",
    },
    {
      asset: "AVAX",

      name: "Avalanche",
    },
    {
      asset: "USDC",

      name: "USD Coin",
    },
    {
      asset: "AAVE",

      name: "AAVE",
    },
    {
      asset: "ALGO",

      name: "Algorand",
    },
    {
      asset: "ANC",

      name: "Anchor Protocol",
    },
    {
      asset: "APE",

      name: "ApeCoin",
    },
    {
      asset: "AURORA",

      name: "Aurora",
    },
    {
      asset: "AXS",

      name: "Axie Infinity",
    },
    {
      asset: "BTG",

      name: "Bitcoin Gold",
    },
    {
      asset: "BORING",

      name: "Boring DAO",
    },
    {
      asset: "ADA",

      name: "Cardano",
    },
    {
      asset: "XCN",

      name: "Onyxcoin",
    },
    {
      asset: "LINK",

      name: "ChainLink",
    },

    {
      asset: "CRO",

      name: "Cronos",
    },
    {
      asset: "DAI",

      name: "Dai",
    },
    {
      asset: "DASH",

      name: "Dash",
    },
    {
      asset: "MANA",

      name: "Decentraland",
    },

    {
      asset: "ETC",

      name: "Ethereum Classic",
    },
    {
      asset: "EVMOS",

      name: "Evmos",
    },
    {
      asset: "GT",

      name: "Gate Token",
    },

    {
      asset: "LN",

      name: "Link",
    },
    {
      asset: "XMR",

      name: "Monero",
    },
    {
      asset: "NEXO",

      name: "Nexo",
    },
    {
      asset: "OKB",

      name: "OKB",
    },
    {
      asset: "OP",

      name: "Optimism",
    },
    {
      asset: "OGN",

      name: "Origin Protocol",
    },
    {
      asset: "ORN",

      name: "Orion Protocol",
    },
    {
      asset: "DOT",

      name: "Polkadot",
    },
    {
      asset: "XPR",

      name: "Proton",
    },

    {
      asset: "RARI",

      name: "Rarible",
    },
    {
      asset: "SFP",

      name: "Safepal",
    },
    {
      asset: "SHIB",

      name: "Shiba Inu",
    },
    {
      asset: "XLM",

      name: "Stellar",
    },

    {
      asset: "GMT",

      name: "Stepn",
    },
    {
      asset: "SUSHI",
      name: "Sushi",
    },
    {
      asset: "TLOS",

      name: "Telos",
    },
    {
      asset: "XTZ",

      name: "Tezos",
    },
    {
      asset: "GRT",

      name: "The Graph",
    },
    {
      asset: "TRX",

      name: "Tron",
    },
    {
      asset: "UNI",
      name: "Uniswap",
    },
    {
      asset: "VET",

      name: "Vechain",
    },
    {
      asset: "WING",

      name: "Wing Finance",
      type: "Crypto",
    },

    {
      asset: "ZEC",

      name: "Zcash",
    },

    {
      asset: "XRP",

      name: "Ripple",
    },
  ].sort();

  const [selectedAsset, setSelectedAsset] = useState({
    asset: "BTC",
    name: "Bitcoin",
  });

  function handleAssetChange(e) {
    const { value } = e.target;

    cryptoAssets.forEach((asset) => {
      if (asset.asset === value) {
        setSelectedAsset(asset);
      }
    });
  }

  const imageRef = useRef();

  function handleImageChange() {
    imageRef.current.click();
  }

  const [isSubmittingQR, setIsSubmittingQR] = useState(false);

  function handleImageURL(e) {
    const file = e.target.files[0];

    if (file) {
      setImageLink(file);
      setImageName(file.name);
      const url = URL.createObjectURL(file);
      setTempUrl(url);
    }
  }

  function reset() {
    URL.revokeObjectURL(imageLink);
  }

  const [isSaving, setIsSaving] = useState(false);

  async function handleSave() {
    setIsSaving(true);

    if (imageLink) {
      submitQR();
    } else {
      addDepositAddress();
    }
  }

  async function submitQR() {
    // setIsUploadingVerification(true);
    if (imageLink) {
      const storageRef = ref(storage, imageName + new Date());
      const uploadTask = uploadBytesResumable(storageRef, imageLink);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // const progress =
          //   (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

          // console.log("Upload is " + progress + "% done");
          switch (snapshot.state) {
            case "paused":
              // console.log("Upload is paused");
              break;
            case "running":
              // console.log("Upload is running");
              break;
            default:
              break;
          }
        },
        (error) => {
          console.log(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            // console.log("File available at", downloadURL);
            // submitVerification(downloadURL);
            addDepositAddressQR(downloadURL);
          });
        }
      );
    }
  }

  async function addDepositAddressQR(url) {
    const namekey = `addresses.${selectedAsset.asset}`;

    const q = doc(db, "admin", "deposit");
    try {
      await updateDoc(q, {
        [namekey]: {
          QR: url,
          address,
          name: selectedAsset.name,
          symbol: selectedAsset.asset,
        },
      });

      setIsSaving(false);
      setToastType("success");
      setToastMessage("Successfully added address");
      setOpenToast(true);
    } catch (error) {
      console.log(error);
      setToastType("error");
      setToastMessage("Could not save. Please try again later");
      setOpenToast(true);
      // toast.error("Could not update. Please try again later");
      setIsSaving(false);
    }
  }

  async function addDepositAddress() {
    const namekey = `addresses.${selectedAsset.asset}`;

    const q = doc(db, "admin", "deposit");
    try {
      await updateDoc(q, {
        [namekey]: {
          QR: null,
          address,
          name: selectedAsset.name,
          symbol: selectedAsset.asset,
        },
      });

      setIsSaving(false);
      setToastType("success");
      setToastMessage("Successfully added address");
      setOpenToast(true);
    } catch (error) {
      console.log(error);
      setIsSaving(false);
      setToastType("error");
      setToastMessage("Could not add. Please try again later");
      setOpenToast(true);
    }
  }

  function handleAddress(e) {
    const { value } = e.target;

    if (value) {
      setAddress(value);
    } else {
      setAddress("");
    }
  }

  return (
    <>
      {openToast && (
        <Toast
          open={{ openToast, setOpenToast }}
          message={toastMessage}
          type={toastType}
        />
      )}

      <Modal
        open={addAddress}
        onClose={() => setAddAddress(false)}
        style={{
          display: "flex",
          placeContent: "center",
          zIndex: "10001",
          padding: "12px",
        }}
      >
        <ModalStandard className="scrollbar-hide">
          <div className="modal_top">
            <div className="modal_top_left">
              {/* <img
                data-v-04385784=""
                class="logoImage"
                src="https://epn.net/storage/2024/02/02/6654840424c66d6d740b54e36cbe5410845e4647.png"
                loading="lazy"
                alt=""
              /> */}

              <p>Add Address</p>
            </div>
            {/* <p>Add signal balance</p> */}

            <svg
              width="15"
              height="14"
              viewBox="0 0 15 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={() => setAddAddress(!addAddress)}
              style={{ cursor: "pointer" }}
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M11.8647 0.366365C12.3532 -0.122122 13.1451 -0.122122 13.6336 0.366365C14.1221 0.854853 14.1221 1.64685 13.6336 2.13533L8.88929 6.87968L13.8743 11.8647C14.3628 12.3532 14.3628 13.1451 13.8743 13.6336C13.3858 14.1221 12.5938 14.1221 12.1053 13.6336L7.12032 8.64864L2.13533 13.6336C1.64685 14.1221 0.854853 14.1221 0.366366 13.6336C-0.122122 13.1451 -0.122122 12.3532 0.366366 11.8647L5.35136 6.87968L0.607014 2.13533C0.118527 1.64685 0.118527 0.854853 0.607014 0.366365C1.0955 -0.122122 1.8875 -0.122122 2.37598 0.366365L7.12032 5.11071L11.8647 0.366365Z"
                fill="#858DAD"
              />
            </svg>
          </div>

          <div className="modal_content">
            <div className="top">
              <BgDropdown>
                <div className="input_wrapper">
                  <div className="input_block">
                    <p className="label">Type:</p>

                    <select name="assets" onChange={handleAssetChange}>
                      {cryptoAssets.sort().map((asset) => (
                        <option value={asset.asset} key={asset.name}>
                          <p className="amount">{asset.name}</p>
                        </option>
                      ))}
                    </select>

                    {/* <input className="amount" placeholder="1000" /> */}
                  </div>

                  <img src="./assets/icons/misc/dropdown.svg" />
                </div>
              </BgDropdown>

              <TextBox>
                <div className="input_wrapper">
                  <div className="input_block">
                    <label htmlFor="value">Address:</label>
                    <input
                      placeholder="01eindeUD83938g3bufb3"
                      onChange={handleAddress}
                    />
                  </div>
                </div>
              </TextBox>

              {/* qr */}
              <div
                className="qr-code"
                style={{
                  margin: "0 auto",
                  height: "200px",
                  border: "1px solid #f3f3f3",
                  borderRadius: "12px",
                  display: "grid",
                  cursor: "pointer",
                  placeContent: "center",
                  marginTop: "40px",
                  //   margin: "40px 0px",
                  position: "relative",
                }}
                onClick={handleImageChange}
              >
                {tempUrl && (
                  <div
                    style={{
                      maxHeight: "200px",
                      overflow: "hidden",
                      padding: "8px",
                      height: "100%",
                    }}
                  >
                    <img
                      style={{ margin: "0px" }}
                      src={tempUrl}
                      alt=""
                      className="qr-code"
                    />
                  </div>
                )}
                {!tempUrl && (
                  <p
                    style={{
                      padding: "8px",
                      color: "white",
                      lineHeight: "20px",
                      fontWeight: "500",
                      fontSize: "14px",
                      color: "#111111",
                    }}
                  >
                    Tap here to add or change QR code. Leave empty to save
                    address without QR code.
                  </p>
                )}

                <input
                  type="file"
                  style={{
                    opacity: "0",
                    position: "absolute",
                    pointerEvents: "none",
                    top: "0",
                    left: "0",
                    height: "100%",
                    maxWidth: "100%",
                  }}
                  ref={imageRef}
                  onChange={handleImageURL}
                  accept="image/png, image/gif, image/jpeg, image/svg"
                />
              </div>
            </div>

            <div className="bottom">
              <div className="btns">
                <button
                  onClick={handleSave}
                  disabled={isSaving || !selectedAsset || !address}
                  className={
                    isSaving || !selectedAsset || !address
                      ? "dark_button full disabled"
                      : "dark_button full "
                  }
                >
                  {isSaving ? (
                    <CircularLoader bg="#cccccc" size="16" color="#ffffff" />
                  ) : (
                    "Add"
                  )}
                </button>
              </div>
            </div>
          </div>
        </ModalStandard>
      </Modal>
    </>
  );
};

const TextArea = styled.textarea`
  font-family: inherit;
  color: inherit;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #d9d9d9;
  width: 100%;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  font-family: Roboto;
  height: 180px;
  box-sizing: border-box;
  margin-top: 12px;
`;

const BgDropdown = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 20px;
  max-width: 720px;

  select {
    width: 100%;
    border: none;
    outline: none;
    background-color: transparent;
    appearance: none;
    padding: 0;
    margin: 0;
    font-weight: 500;
  }

  h3.title {
    margin: 0px;
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
  }

  .input_wrapper {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    background: transparent;
    padding: 10px 12px;
    border-radius: 8px;
    border-width: 1px;
    border-style: solid;
    border-color: #f3f3f3;
    background-color: #f3f3f3;
    cursor: pointer;
    box-sizing: border-box;
  }

  .input_block {
    box-sizing: border-box;
    flex-grow: 1;
    overflow: hidden;
    max-width: 100%;
  }

  p.label {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
    transition: transform 0.1s ease 0s;
    margin-top: 4px;
    line-height: 1;
    color: #686c77;
    font-size: 14px;
  }

  p.amount {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
    transition: transform 0.1s ease 0s;
    margin-top: 4px;
    line-height: 1;
    font-size: 15px;
  }

  p.amount span {
    color: #686c77;
  }

  .caption {
    display: flex;
    gap: 4px;
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
  }

  .caption span {
    color: rgba(21, 128, 61, 1);
  }
`;

const TextBox = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 24px;
  max-width: 720px;
  margin-top: 16px;

  h3.title {
    margin: 0px;
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
  }

  .input_wrapper {
    display: flex;
    box-sizing: border-box;
    align-items: center;
    position: relative;
    width: 100%;
    background: transparent;
    padding: 10px 12px;
    border-radius: 8px;
    border-width: 1px;
    border-style: solid;
    border-color: #f3f3f3;
    height: 56px;
    width: 100% !important;
  }

  .input_block {
    box-sizing: border-box;
    flex-grow: 1;
    overflow: hidden;
    max-width: 100%;
    position: relative;
  }

  label {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
    transition: transform 0.1s ease 0s;
    margin-top: 4px;
    line-height: 1;
    color: #686c77;
    font-size: 14px;
    margin-bottom: 2px;
  }

  .max_button {
    font-size: 12px;
    font-weight: 600;
    background: none;
    outline: none;
    border: none;
    cursor: pointer;
  }

  input {
    box-sizing: border-box;
    margin: 0px;
    padding: 0px;
    font-family: inherit;
    font-size: 100%;
    font-weight: inherit;
    line-height: inherit;
    color: inherit;
    flex-grow: 1;
    background: transparent;
    overflow: hidden;
    white-space: nowrap;
    min-width: 1%;
    width: 100%;
    text-overflow: ellipsis;
    max-width: 100%;
    outline: none;
    border: none;
    transition: 0.3s ease-in-out;
  }

  /* input:focus ~ label {
    color: red;
    transition-duration: 0.2s;
    transform: translate(0, -1.5em) scale(0.9, 0.9);
  } */

  .caption {
    display: flex;
    gap: 4px;
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
  }

  .caption span {
    color: rgba(21, 128, 61, 1);
  }

  /* @media screen and (max-width: 768px) {
    & {
      width: 100% !important;
      max-width: unset;
    }
  } */
`;

const ModalStandard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  /* justify-content: space-between; */
  align-items: flex-start;
  border-radius: 16px;
  width: 660px;
  min-height: 480px;
  height: 70%;
  background-color: #fff;
  opacity: 1;
  padding: 24px;
  box-sizing: border-box;
  overflow: hidden;
  outline: none;
  place-self: center;
  position: relative;

  .qr-code {
    max-width: 200px;
    width: 100%;
    height: auto;
    place-self: center;
    margin-top: 24px;
    margin: 0 auto;
  }

  .bottom {
    margin-top: auto;
    position: absolute;
    bottom: 30px;
  }

  .btns {
    display: flex;

    gap: 12px;
  }

  .modal_top {
    color: white;
    font-size: 16px;
    font-weight: 600;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .modal_top_left {
    display: flex;
    align-items: center;
  }

  .modal_top_left img {
    height: 30px;
    width: auto;
    margin-right: 10px;
  }

  .modal_top_left p {
    color: #333742;
    font-size: 16px;
    font-family: Roboto;
  }

  .modal_content {
    width: 100%;
    box-sizing: border-box;
  }

  .modal_content .top {
    width: 100%;
    box-sizing: border-box;
  }

  .modal_content .top .text {
    color: #333742;
    font-family: Roboto;
    line-height: 22px;
    font-size: 18px;
  }

  .checkbox_wrapper {
    display: flex;
    gap: 8px;
    align-items: center;
    margin-top: 16px;
  }

  .checkbox_wrapper p {
    font-size: 16px;
    line-height: 133%;
    font-weight: 500;
  }

  .slide {
  }

  .slider {
  }

  .slide img {
  }
`;

const TransparentDropdown = styled.div`
  box-sizing: border-box;
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: flex-start; */
  flex-direction: column;
  gap: 24px;
  margin-bottom: 20px;
  max-width: 720px;

  h3.title {
    font-size: 16px;
    line-height: 1;
    font-weight: 500;
    margin-bottom: 12px;
  }

  .input_wrapper {
    display: flex;
    box-sizing: border-box;
    align-items: center;
    position: relative;
    width: 100%;
    background: transparent;
    padding: 10px 12px;
    border-radius: 8px;
    border-width: 1px;
    border-style: solid;
    border-color: #f3f3f3;
    height: 56px;
    font-size: 16px;
    font-weight: 500 !important;
  }

  .input_block {
    box-sizing: border-box;
    flex-grow: 1;
    overflow: hidden;
    max-width: 100%;
    position: relative;
  }

  label {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
    transition: transform 0.1s ease 0s;
    margin-top: 4px;
    line-height: 1;
    color: #686c77;
    font-size: 14px;
    margin-bottom: 2px;
  }

  .max_button {
    font-size: 12px;
    font-weight: 600;
    background: none;
    outline: none;
    border: none;
    cursor: pointer;
  }

  input {
    box-sizing: border-box;
    margin: 0px;
    padding: 0px;
    font-family: inherit;
    font-size: 100%;
    font-weight: inherit;
    line-height: inherit;
    color: inherit;
    flex-grow: 1;
    background: transparent;
    overflow: hidden;
    white-space: nowrap;
    min-width: 1%;
    width: 100%;
    text-overflow: ellipsis;
    max-width: 100%;
    outline: none;
    border: none;
    transition: 0.3s ease-in-out;
  }

  /* input:focus ~ label {
    color: red;
    transition-duration: 0.2s;
    transform: translate(0, -1.5em) scale(0.9, 0.9);
  } */

  .caption {
    display: flex;
    gap: 4px;
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
  }

  .caption span {
    color: rgba(21, 128, 61, 1);
  }

  p.option {
    color: #333742;
    font-size: 16px;
    font-weight: 600 !important;
    font-family: Roboto;
  }
`;

export default AddAddressModal;
