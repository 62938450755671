import styled from "styled-components";
import LightButton from "../components/styled/LightButton";
import { useEffect, useState } from "react";
import Toast from "../hooks/Toast";
import {
  collection,
  deleteDoc,
  deleteField,
  doc,
  getDocs,
  increment,
  onSnapshot,
  orderBy,
  query,
  updateDoc,
} from "firebase/firestore";
import { db } from "../firebase/firebase";
import CircularLoader from "../components/styled/loaders/CircularLoader";
import { ClickAwayListener, Grow, Skeleton } from "@mui/material";
import { getTime } from "../utils/utils";

const DepositsTable = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [deposits, setDeposits] = useState([]);
  const [depositsList, setDepositsList] = useState([]);

  useEffect(() => {
    const depositList = [];

    async function getDeposits() {
      const q = query(collection(db, "deposits"), orderBy("date", "desc"));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        depositList.push(doc.data());
      });
      setDeposits(depositList);
      setDepositsList(depositList);
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    }

    getDeposits();
  }, []);

  //   function copyValue(value, type) {
  //     navigator.clipboard.writeText(value);

  //     // setType("success");
  //     // setMessage("Copied " + type);
  //     // setIsOpenCon(true);
  //   }

  function handleSearchDeposits(e) {
    const { value } = e.target;
    let filteredDeposits;

    if (value) {
      filteredDeposits = depositsList.filter(
        (deposits) =>
          deposits.userRef?.email.toLowerCase().includes(value.toLowerCase()) ||
          deposits.ref.toLowerCase().includes(value.toLowerCase())
      );
      setDeposits(filteredDeposits);
    } else {
      setDeposits(depositsList);
    }
  }

  // toast
  const [openToast, setOpenToast] = useState(false);
  const [toastMessage, setToastMessage] = useState(" ");
  const [toastType, setToastType] = useState(undefined);

  const [addProduct, setAddProduct] = useState(false);

  const [currentAction, setCurrentAction] = useState(undefined);

  const [selectedRef, setSelectedRef] = useState("");

  function handleSelectedRef(deposit) {
    const { ref } = deposit;
    setSelectedRef(selectedRef === ref ? " " : ref);
  }

  // edit
  //   const [editProduct, setEditProduct] = useState(false);
  //   const [details, setDetails] = useState({});

  function handleAction(action, deposit) {
    const { ref, amount, commission, user } = deposit;
    setSelectedRef(undefined);

    if (action === "delete") {
      deleteDeposit(ref);
    }

    if (action === "approve") {
      topUpUser(ref, amount, commission, user);
    }

    if (action === "decline") {
      declineRecord(ref);
    }
  }

  //   approve
  async function topUpUser(ref, amount, commission, user) {
    setCurrentAction(ref);

    const profile = doc(db, "users", user);
    await updateDoc(profile, {
      totalBalance: increment(Number(amount) - Number(commission)),
    })
      .then(() => {
        updateRecord(ref);
      })
      .catch((error) => {
        setCurrentAction(undefined);
        setToastType("error");
        setToastMessage("Failed to update. Please try again later");
        setOpenToast(true);
      });
  }

  async function updateRecord(ref) {
    setCurrentAction(ref);
    const document = doc(db, "deposits", ref);
    await updateDoc(document, {
      status: "approved",
    })
      .then(() => {
        updateHistory(ref);
      })
      .catch((error) => {
        // console.log(error)
        setCurrentAction(undefined);
        setToastType("error");
        setToastMessage("Failed to approve. Please try again later");
        setOpenToast(true);
      });
  }

  async function updateHistory(ref) {
    setCurrentAction(ref);
    const document = doc(db, "history", ref);
    await updateDoc(document, {
      status: "approved",
    })
      .then(() => {
        setToastType("success");
        setToastMessage("Approved successfully");
        setOpenToast(true);
      })
      .catch((error) => {
        setCurrentAction(undefined);
        // console.log(error)
        setCurrentAction(undefined);
        setToastType("error");
        setToastMessage("Failed to approve. Please try again later");
        setOpenToast(true);
      });
  }

  //   decline
  async function declineRecord(ref) {
    setCurrentAction(ref);
    const document = doc(db, "deposits", ref);
    await updateDoc(document, {
      status: "declined",
    })
      .then(() => {
        declineHistory(ref);
      })
      .catch((error) => {
        // console.log(error)
        setCurrentAction(undefined);
        setToastType("error");
        setToastMessage("Failed to approve. Please try again later");
        setOpenToast(true);
      });
  }

  async function declineHistory(ref) {
    setCurrentAction(ref);
    const document = doc(db, "history", ref);
    await updateDoc(document, {
      status: "declined",
    })
      .then(() => {
        setCurrentAction(undefined);
        setToastType("success");
        setToastMessage("Declined successfully");
        setOpenToast(true);
      })
      .catch((error) => {
        // console.log(error)
        setCurrentAction(undefined);
        setToastType("error");
        setToastMessage("Failed to approve. Please try again later");
        setOpenToast(true);
      });
  }

  // delete
  async function deleteDeposit(ref) {
    setCurrentAction(ref);
    const document = doc(db, "deposits", ref);
    await deleteDoc(document)
      .then(() => {
        deleteHistory(ref);
      })
      .catch((error) => {
        setCurrentAction(undefined);
        setToastType("error");
        setToastMessage("Failed to delete. Please try again later");
        setOpenToast(true);
      });
  }

  async function deleteHistory(ref) {
    setCurrentAction(ref);
    const document = doc(db, "history", ref);
    await deleteDoc(document)
      .then(() => {
        setCurrentAction(undefined);
        setToastType("success");
        setToastMessage("Deleted successfully");
        setOpenToast(true);
      })
      .catch((error) => {
        setCurrentAction(undefined);
        setToastType("error");
        setToastMessage("Failed to delete. Please try again later");
        setOpenToast(true);
      });
  }

  return (
    <TableWrapper className="scrollbar-hide">
      {openToast && (
        <Toast
          open={{ openToast, setOpenToast }}
          message={toastMessage}
          type={toastType}
        />
      )}

      <div className="table_top">
        <div className="page_title">Deposits</div>
      </div>

      <Search>
        <div className="search">
          <img src="./assets/icons/misc/search.svg" />
          <div className="search_wrapper">
            <div className="search_container">
              <input
                maxLength="40"
                placeholder="Search for deposits"
                onChange={handleSearchDeposits}
              />

              {/* <img src="./assets/icons/misc/close.svg" /> */}
            </div>
          </div>
        </div>
      </Search>

      {isLoading && (
        <DepositsTableWrapper>
          <thead>
            <tr>
              <th>
                {" "}
                <Skeleton
                  variant="rounded"
                  height={18}
                  width={150}
                  sx={{
                    backgroundColor: "#0000001f",
                    borderRadius: "32px",
                  }}
                />
              </th>
              <th>
                {" "}
                <Skeleton
                  variant="rounded"
                  height={18}
                  width={100}
                  sx={{
                    backgroundColor: "#0000001f",
                    borderRadius: "32px",
                  }}
                />
              </th>
              <th>
                <Skeleton
                  variant="rounded"
                  height={18}
                  width={120}
                  sx={{
                    backgroundColor: "#0000001f",
                    borderRadius: "32px",
                  }}
                />
              </th>
              <th>
                <Skeleton
                  variant="rounded"
                  height={18}
                  width={120}
                  sx={{
                    backgroundColor: "#0000001f",
                    borderRadius: "32px",
                  }}
                />
              </th>
              <th>
                <Skeleton
                  variant="rounded"
                  height={18}
                  width={80}
                  sx={{
                    backgroundColor: "#0000001f",
                    borderRadius: "32px",
                  }}
                />
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <Skeleton
                  variant="rounded"
                  height={18}
                  width={150}
                  sx={{
                    backgroundColor: "#0000001f",
                    borderRadius: "32px",
                  }}
                />
              </td>
              <td>
                <Skeleton
                  variant="rounded"
                  height={18}
                  width={100}
                  sx={{
                    backgroundColor: "#0000001f",
                    borderRadius: "32px",
                  }}
                />
              </td>
              <td>
                <Skeleton
                  variant="rounded"
                  height={18}
                  width={120}
                  sx={{
                    backgroundColor: "#0000001f",
                    borderRadius: "32px",
                  }}
                />
              </td>
              <td>
                <Skeleton
                  variant="rounded"
                  height={18}
                  width={120}
                  sx={{
                    backgroundColor: "#0000001f",
                    borderRadius: "32px",
                  }}
                />
              </td>
              <td>
                <Skeleton
                  variant="rounded"
                  height={18}
                  width={120}
                  sx={{
                    backgroundColor: "#0000001f",
                    borderRadius: "32px",
                  }}
                />
              </td>
              <td>
                <Skeleton
                  variant="rounded"
                  height={18}
                  width={100}
                  sx={{
                    backgroundColor: "#0000001f",
                    borderRadius: "32px",
                  }}
                />
              </td>
            </tr>
          </tbody>
        </DepositsTableWrapper>
      )}

      {!isLoading && (
        <DepositsTableWrapper>
          <thead>
            <tr>
              <th>User</th>
              <th>Ref</th>
              <th>Amount</th>
              <th>Commission</th>
              <th>Date</th>
              <th>Status</th>
              <th></th>
            </tr>
          </thead>

          {deposits?.length > 0 && (
            <tbody>
              {deposits.map(
                (deposit, index) =>
                  deposit.status == "pending" && (
                    <tr key={index}>
                      <td>
                        <UserProfile>
                          <div className="icon">
                            <p>{String(deposit.userRef?.email).slice(0, 2)}</p>
                          </div>
                          <p>{deposit.userRef?.email}</p>
                        </UserProfile>
                      </td>
                      <td>
                        {" "}
                        <p>{deposit.ref}</p>
                      </td>
                      <td>
                        {" "}
                        <p>${deposit.amount}</p>
                      </td>
                      <td>
                        {" "}
                        <p>${deposit.commission}</p>
                      </td>
                      <td>
                        {" "}
                        <p>{getTime(new Date() - deposit.date.toDate())}</p>
                      </td>
                      <td>
                        <p className="pending">{deposit.status}</p>
                      </td>
                      <td>
                        <div style={{ position: "relative" }}>
                          {currentAction === deposit.ref ? (
                            <CircularLoader
                              bg="#cccccc"
                              size="24"
                              color="#ffffff"
                            />
                          ) : (
                            <LightButton
                              text="Manage"
                              action={() => handleSelectedRef(deposit)}
                            />
                          )}

                          {selectedRef === deposit.ref && (
                            <ClickAwayListener
                              onClickAway={() => setSelectedRef("")}
                            >
                              <Grow
                                in={selectedRef === deposit.ref}
                                style={{ transformOrigin: "0 0 0 0" }}
                                {...(selectedRef === deposit.ref
                                  ? { timeout: 300 }
                                  : {})}
                              >
                                <Dropdown>
                                  <div className="drop_section">
                                    <span
                                      className="drop_item"
                                      onClick={() =>
                                        handleAction("approve", deposit)
                                      }
                                    >
                                      {/* <img src={item.icon} alt={item.title} /> */}
                                      <p>Approve</p>
                                    </span>
                                    <span
                                      className="drop_item"
                                      onClick={() =>
                                        handleAction("decline", deposit)
                                      }
                                    >
                                      {/* <img src={item.icon} alt={item.title} /> */}
                                      <p>Decline</p>
                                    </span>
                                    <span
                                      className="drop_item"
                                      onClick={() =>
                                        handleAction("delete", deposit)
                                      }
                                    >
                                      {/* <img src={item.icon} alt={item.title} /> */}
                                      <p className="disabled">Delete</p>
                                    </span>
                                  </div>
                                </Dropdown>
                              </Grow>
                            </ClickAwayListener>
                          )}
                        </div>
                      </td>
                    </tr>
                  )
              )}

              {deposits.map(
                (deposit, index) =>
                  deposit.status == "approved" && (
                    <tr key={index}>
                      <td>
                        <UserProfile>
                          <div className="icon">
                            <p>{String(deposit.userRef?.email).slice(0, 2)}</p>
                          </div>
                          <p>{deposit.userRef?.email}</p>
                        </UserProfile>
                      </td>
                      <td>
                        {" "}
                        <p>{deposit.ref}</p>
                      </td>
                      <td>
                        {" "}
                        <p>${deposit.amount}</p>
                      </td>
                      <td>
                        {" "}
                        <p>${deposit.commission}</p>
                      </td>
                      <td>
                        {" "}
                        <p>{getTime(new Date() - deposit.date.toDate())}</p>
                      </td>
                      <td>
                        <p className="enabled">{deposit.status}</p>
                      </td>
                      <td>
                        <div style={{ position: "relative" }}>
                          {currentAction === deposit.ref ? (
                            <CircularLoader
                              bg="#cccccc"
                              size="24"
                              color="#ffffff"
                            />
                          ) : (
                            <LightButton
                              text="Manage"
                              action={() => handleSelectedRef(deposit)}
                            />
                          )}

                          {selectedRef === deposit.ref && (
                            <ClickAwayListener
                              onClickAway={() => setSelectedRef("")}
                            >
                              <Grow
                                in={selectedRef === deposit.ref}
                                style={{ transformOrigin: "0 0 0 0" }}
                                {...(selectedRef === deposit.ref
                                  ? { timeout: 300 }
                                  : {})}
                              >
                                <Dropdown>
                                  <div className="drop_section">
                                    <span
                                      className="drop_item"
                                      onClick={() =>
                                        handleAction("decline", deposit)
                                      }
                                    >
                                      <p>Decline</p>
                                    </span>
                                    <span
                                      className="drop_item"
                                      onClick={() =>
                                        handleAction("delete", deposit)
                                      }
                                    >
                                      <p className="disabled">Delete</p>
                                    </span>
                                  </div>
                                </Dropdown>
                              </Grow>
                            </ClickAwayListener>
                          )}
                        </div>
                      </td>
                    </tr>
                  )
              )}

              {deposits.map(
                (deposit, index) =>
                  deposit.status == "declined" && (
                    <tr key={index}>
                      <td>
                        <UserProfile>
                          <div className="icon">
                            <p>{String(deposit.userRef?.email).slice(0, 2)}</p>
                          </div>
                          <p>{deposit.userRef?.email}</p>
                        </UserProfile>
                      </td>
                      <td>
                        {" "}
                        <p>{deposit.ref}</p>
                      </td>
                      <td>
                        {" "}
                        <p>${deposit.amount}</p>
                      </td>
                      <td>
                        {" "}
                        <p>${deposit.commission}</p>
                      </td>
                      <td>
                        {" "}
                        <p>{getTime(new Date() - deposit.date.toDate())}</p>
                      </td>
                      <td>
                        <p className="disabled">{deposit.status}</p>
                      </td>
                      <td>
                        <div style={{ position: "relative" }}>
                          {currentAction === deposit.ref ? (
                            <CircularLoader
                              bg="#cccccc"
                              size="24"
                              color="#ffffff"
                            />
                          ) : (
                            <LightButton
                              text="Manage"
                              action={() => handleSelectedRef(deposit)}
                            />
                          )}

                          {selectedRef === deposit.ref && (
                            <ClickAwayListener
                              onClickAway={() => setSelectedRef("")}
                            >
                              <Grow
                                in={selectedRef === deposit.ref}
                                style={{ transformOrigin: "0 0 0 0" }}
                                {...(selectedRef === deposit.ref
                                  ? { timeout: 300 }
                                  : {})}
                              >
                                <Dropdown>
                                  <div className="drop_section">
                                    <span
                                      className="drop_item"
                                      onClick={() =>
                                        handleAction("approve", deposit)
                                      }
                                    >
                                      <p>Approve</p>
                                    </span>
                                    <span
                                      className="drop_item"
                                      onClick={() =>
                                        handleAction("delete", deposit)
                                      }
                                    >
                                      <p className="disabled">Delete</p>
                                    </span>
                                  </div>
                                </Dropdown>
                              </Grow>
                            </ClickAwayListener>
                          )}
                        </div>
                      </td>
                    </tr>
                  )
              )}
            </tbody>
          )}
        </DepositsTableWrapper>
      )}

      {!isLoading && !deposits?.length > 0 && (
        <p
          style={{
            textAlign: "center",
            margin: "0 auto",
            padding: "16px",
            padddingTop: "0",
            width: "100%",
          }}
        >
          No deposits yet
        </p>
      )}
    </TableWrapper>
  );
};

const Dropdown = styled.div`
  position: absolute;
  /* min-width: 114px; */
  position: absolute;
  z-index: 40;
  border-radius: 4px;
  background: #fff;
  max-height: 384px;
  box-shadow: 0 3px 18px rgba(0, 0, 0, 0.075), 3px 15px 25px rgba(0, 0, 0, 0.05);
  max-width: unset;
  width: 100%;
  left: 0;

  .drop_section {
    position: relative;
    /* padding: 8px 16px; */
  }

  .drop_divider {
    background: #e5e5e5;
    height: 1px;
    width: 100%;
    margin-bottom: 6px;
    margin-top: 6px;
  }

  .drop_item {
    position: relative;
    padding: 8px 16px;
    display: grid;
    grid-template-columns: 24px auto;
    align-items: center;
    gap: 14px;
    font-weight: 400;
    font-size: 14px;
    height: 40px;
    cursor: pointer;
    box-sizing: border-box;
  }

  .drop_item:hover {
    background: #f3f3f3;
  }

  .user_wrapper {
    display: flex;
    gap: 14px;
    align-items: center;
    display: flex;
    align-items: flex-start;
    gap: 16px;
    width: 100%;
    padding: 16px 16px 8px 8px;
  }

  .user_wrapper .user_email {
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 230px;
    white-space: nowrap;
  }

  .user_wrapper .user_level {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
    color: #1968e5;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    white-space: nowrap;
    font-size: 14px;
    margin-top: 8px;
  }
`;

const Search = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 0px;
  position: relative;
  width: 100%;
  height: 32px;
  border-bottom: 1px solid #d9d9d9;
  border-radius: 4px 4px 0px 0px;
  border-color: #686c77;

  .search {
    display: flex;
    align-items: center;
    align-self: stretch;
    gap: 12px;
    flex-grow: 1;
    max-width: 100%;
  }

  .search_wrapper {
    flex-grow: 1;
    overflow: hidden;
  }

  .search_container {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    gap: 12px;
    width: 100%;
    position: relative;
  }

  input {
    margin: 0px;
    padding: 0px;
    font-family: inherit;
    font-weight: inherit;
    line-height: inherit;
    color: inherit;
    flex-grow: 1;
    background: transparent;
    overflow: hidden;
    white-space: nowrap;
    min-width: 1%;
    width: 100%;
    text-overflow: ellipsis;
    max-width: 100%;
    font-size: 14px;
    outline: none;
    border: none;
  }

  .select_buttons {
    display: flex;
    gap: 24px;
  }
`;

const TableWrapper = styled.div`
  max-width: 90vw;
  overflow-y: auto;
  white-space: nowrap;
  display: grid;
  gap: 24px;
  padding-bottom: 140px;

  p.enabled {
    color: #0ac18e;
    text-transform: capitalize;
  }

  p.disabled {
    color: #e84142;
    text-transform: capitalize;
  }

  p.pending {
    text-transform: capitalize;
    color: #f7931a;
  }
`;

const DepositsTableWrapper = styled.table`
  padding-bottom: 24px;

  thead tr th {
    background: #ecf0f5;
    padding: 0px 16px;
    height: 52px;
    font-weight: 500;
    color: #070707;
    vertical-align: middle;
    text-align: start;
  }

  thead tr th:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  thead tr th:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  tbody tr td {
    padding: 0px 16px;
    height: 52px;
    font-weight: 400;
    color: #070707;
    vertical-align: middle;
    text-align: start;
  }

  tbody tr {
    cursor: pointer;
  }

  tbody tr:hover {
    background: #ecf0f567;
  }
`;

const UserProfile = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;

  .icon {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    position: relative;
    cursor: pointer;
    border: 1px solid #b3261e;
    display: grid;
    place-content: center;
    text-transform: uppercase;
    font-weight: 700;
    color: #1968e5;
    box-sizing: border-box;
  }
`;

export default DepositsTable;
